import * as types from "../types";

// Return an object to store data inside `state`, separeated folder or both
const processData = (state, newData, { separateTo, storeBoth }) => ({
  ...state,
  ...(!separateTo
    ? newData
    : {
        ...(storeBoth ? newData : {}),
        [separateTo]: { ...state[separateTo], ...newData },
      }),
});

/**
 * `products` store initial state
 * @type {Object}
 * @prop {Array[Object]} products - array containing products descriptions (for groups of products)
 * @prop {Object} targetProduct - product description separated from ones in `products` field
 * (for main content product, such as at `Product` or `Checkout page`)
 * @prop {Number} count - amount of products selected by query in `FETCH_PRODUCT` saga (including not already fetched)
 * @prop {Number} next - number of next group of products (page) to fetch with query used in previous request
 * @prop {Number} pages - full amount of product groups could be fetched by query in `FETCH_PRODUCT` saga
 */
export const init = {
  products: [],
  similarProducts: [],
  upSaleProducts: [],
  prefetched: {},
  mostRecommendedProducts: [],
  selectedProduct: null,
  count: null,
  next: 1,
  pages: null,
  queryData: null,
};

export default function products(state = {}, action) {
  switch (action.type) {
    case types.FETCH_PRODUCTS:
    case types.FETCH_SELECTED_PRODUCT:
      return processData(state, { loading: true }, action);

    case types.FETCH_PRODUCTS_FAILURE:
    case types.FETCH_SELECTED_PRODUCT_FAILURE:
      return processData(state, { error: action.error, loading: false }, action);

    case types.FETCH_PRODUCTS_SUCCESS: {
      const hasProducts = Array.isArray(action.data.results) && action.data.results.length > 0;
      const newData = {
        products: hasProducts ? [...action.data.results] : [],
        pages: action.data.pages,
        count: action.data.count,
        next: action.data.next,
        queryData: action.data,
        loading: false,
      };

      return processData({ ...state, prefetched: newData }, newData, action);
    }

    case types.UPDATE_BY_PREFETCHED: {
      return {
        ...state,
        ...state.prefetched,
      };
    }

    case types.FETCH_MOST_RECOMMENDED_SUCCESS: {
      const hasMostRecommended = Array.isArray(action.data) && action.data.length > 0;
      return {
        ...state,
        mostRecommendedProducts: hasMostRecommended ? [...action.data] : [],
        loading: false,
      };
    }

    case types.FETCH_MOST_RECOMMENDED_FAILURE: {
      return {
        ...state,
        mostRecommendedProducts: [],
        error: action.error,
        loading: false,
      };
    }

    case types.SET_SELECTED_PRODUCT:
      return {
        ...state,
        selectedProduct: action.product,
        loading: true,
      };

    case types.FETCH_SELECTED_PRODUCT_SUCCESS:
      return {
        ...state,
        selectedProduct: action.product,
        loading: false,
      };
    case types.FETCH_SIMILAR_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_SIMILAR_PRODUCTS_SUCCESS:
      return {
        ...state,
        similarProducts: action.payload,
        loading: false,
      };
    case types.GET_SIMILAR_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.FETCH_UPSALE_PRODUCTS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.GET_UPSALE_PRODUCTS_SUCCESS:
      return {
        ...state,
        upSaleProducts: action.payload,
        loading: false,
      };
    case types.GET_UPSALE_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
}
