import { chevron } from "./chevron";

/**
 * @var variations - consists from variation data structure:
 * {
 *    shapeName: base shape name form Icon/shapes/ folder
 *    change: [{
 *      rotate: transformation degree
 *    }]
 * }
 */

export const variations = {
  ...chevron,
};
