import { DEFAULT_LANG } from "../../constants";
import { availableLangs } from "../urlLanguage";

/**
 * Checks if passed language code is usable
 * @param {String} langUnsafe - language code to check
 * @returns {String} - language code available for current usage
 */
export default function getCurrentLanguage(langUnsafe) {
  return langUnsafe && availableLangs.some(l => l.code === langUnsafe) ? langUnsafe : DEFAULT_LANG;
}
