import { takeEvery, put, call } from "redux-saga/effects";
import * as types from "../types";
import { Api } from "../../functions/fetchFromApi";

// eslint-disable-next-line no-unused-vars
function* fetchCurrencies(action) {
  try {
    const { data } = yield call(Api.get, `/api/currencies/`);
    yield put({ type: types.FETCH_CURRENCIES_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.FETCH_CURRENCIES_FAILURE, error });
  }
}

function* fetchDefaultCurrency(action) {
  try {
    const defaultCurrency = yield call(fetchDefaultCurrency, action.request);
    yield put({ type: types.FETCH_DEFAULT_CURRENCY_SUCCESS, defaultCurrency });
  } catch (error) {
    yield put({ type: types.FETCH_DEFAULT_CURRENCY_FAILURE, error });
  }
}

export default function* watch() {
  yield takeEvery(types.FETCH_CURRENCIES, fetchCurrencies);
  yield takeEvery(types.FETCH_DEFAULT_CURRENCY, fetchDefaultCurrency);
}
