import React from "react";
import { t } from "@lingui/macro";
import { sendEventTrack } from "../../../functions/analytics";
import Cookies from "../../../functions/cookies";
import { availableCurs } from "../../../functions/currency";
import Button from "../../Button";
import Radio from "../../Radio";
import { Icon } from "../../Icon";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";

/**
 * Saves currency to cookie
 * Reloads page to refetch all products with valid currency
 * @param {String} currency - currency code to set
 */
const saveCurrency = code => {
  Cookies.set("currency", code, { path: "/" });
  sendEventTrack("Currency Selected", { label: code });
  window.location.reload();
};
/**
 * Menu showing after click on select currency button
 * @param {Object} $
 */
const CurrencyMenu = ({ isMobile, currency, i18n }) => {
  const Tag = isMobile ? Radio : Button;

  return (
    <PopupOrDropdownAdapter
      isMobile={isMobile}
      title={isMobile && i18n._(t`Select currency`)}
      triggerElement={currency}
      bodyClassName="HeaderMenu"
      noButtonWrap={false}
      disableOutside={false}
      align="right"
      ContentComponent={() =>
        availableCurs.map(({ code, icon }) => {
          const inputProps =
            Tag === Radio
              ? {
                  name: "currency",
                  value: code,
                  defaultChecked: code === currency,
                }
              : { size: "S" };
          return (
            <Tag
              key={code}
              {...inputProps}
              className="HeaderMenu-item HeaderMenu-item--bold"
              onClick={() => saveCurrency(code)}
            >
              {code} <Icon name={icon} height="20" width="19" />
            </Tag>
          );
        })
      }
    />
  );
};

export default CurrencyMenu;
