import { Trans } from "@lingui/macro";
import fetch from "isomorphic-fetch";
import Cookies from "./cookies";

const API_HOST = process.env.HOST || "localhost";
const API_PORT = process.env.PORT || 3000;
const API_URL_FOR_JS_SERVER = process.env.API_URL_FOR_JS_SERVER || `http://${API_HOST}:${API_PORT}`;
const apiUrl = process.env.BUILD_TARGET === "server" ? API_URL_FOR_JS_SERVER : "";
const errorText = (
  <Trans>
    Looks like there are not enough tickets available for the selected timeslot. Please change the
    dates or number of participants, or email us at support@wegotrip.com and we'll help you complete
    your order.
  </Trans>
);
/**
 * Extended `Error` class for description status errors in fetch
 * @prop {Number} status - status code
 */
class FetchError extends Error {
  constructor(message, code = null) {
    if (typeof message === "object") {
      message = message.props.id || JSON.stringify(message);
    }
    super(message);
    this.name = "FetchError";
    this.status = code;
  }
}

/**
 * Static class for operating server's API
 */
export const Api = {
  /**
   * Gets request to API (universal for all request types)
   * @param {String} path - api method url
   * @param {Object} $
   * @param {Object} $.headers - additional headers
   * @param {String} $.cookies - string with cookies to get auth token, check `Cookies::get` for what happen if not passed
   * @param {Object} $.options - additional props passed in `fetch`'s second param
   * @param {Boolean} $.provideHeaders - make attempt to access headers from API responce (effective for `Set-Cookie` in SSR)
   */
  // eslint-disable-next-line consistent-return
  async fetchFromApi(path, { headers, cookies, lang, provideHeaders, textBody, ...options }) {
    const defaultHeaders = {};
    const token = Cookies.get("token", cookies);
    const csrftoken = Cookies.get("csrftoken", cookies);

    if (token) defaultHeaders.Authorization = `Token ${token}`;
    if (csrftoken) defaultHeaders["X-CSRFToken"] = csrftoken;
    if (lang) defaultHeaders["Accept-Language"] = lang;

    try {
      const req = await fetch(`${apiUrl}${path}`, {
        credentials: "same-origin",
        ...options,
        headers: { ...headers, ...defaultHeaders },
      });
      if (!req.ok && req.status === 419) {
        throw new FetchError(errorText);
      }

      if (!req.ok && req.status !== 400 && req.status !== 419) {
        throw new FetchError(`Request status: ${req.status}`, req.status);
      }
      const data = textBody ? await req.text() : await req.json();
      if (req.status === 400 && data.errors && data.errors.length) {
        throw new FetchError(data.errors[0].message, req.status);
      }
      if (req.status === 400 && data.error && data.error) {
        throw new FetchError(data.error.message, req.status);
      }
      if (req.status === 400 && data.non_field_errors) {
        throw new FetchError(data.non_field_errors, req.status);
      }
      return provideHeaders ? { data, headers: req.headers } : data;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(`Error on request ${options.method.toUpperCase()} ${apiUrl}${path}`, e);
      throw e;
    }
  },

  async get(url, options = {}) {
    const data = await Api.fetchFromApi(url, { method: "get", ...options });
    return data;
  },

  async post(url, { headers, payload, ...options } = {}) {
    const defaultHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const data = await Api.fetchFromApi(url, {
      method: "post",
      headers: { ...headers, ...defaultHeaders },
      body: payload ? JSON.stringify(payload) : null,
      ...options,
    });
    return data;
  },

  async put(url, { headers, payload, ...options } = {}) {
    const defaultHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const data = await Api.fetchFromApi(url, {
      method: "put",
      headers: { ...headers, ...defaultHeaders },
      body: payload ? JSON.stringify(payload) : null,
      ...options,
    });
    return data;
  },

  async patch(url, { headers, payload, ...options } = {}) {
    const defaultHeaders = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
    const data = await Api.fetchFromApi(url, {
      method: "PATCH",
      headers: { ...headers, ...defaultHeaders },
      body: payload ? JSON.stringify(payload) : null,
      ...options,
    });
    return data;
  },
};
