import { t } from "@lingui/macro";
import formatDuration from "date-fns/formatDuration";
import { ru, enUS } from "date-fns/locale";
import { translateDate } from "./translateDate";

const locales = {
  ru,
  en: enUS,
};
export function getFnsLocale(lang) {
  // it's the built-in language and doesn't require any setup
  // eslint-disable-next-line no-prototype-builtins
  if (locales.hasOwnProperty(lang)) {
    return locales[lang];
  }
  return enUS;
}

function pad(n, width, z) {
  const internalZ = z || "0";
  const internalN = String(n);
  return internalN.length >= width
    ? n
    : new Array(width - internalN.length + 1).join(internalZ) + internalN;
}

function shortenTimestring(string) {
  return string
    .replace(/\sday\w*/g, "d")
    .replace(/\sminute\w*/g, "min")
    .replace(/\shour\w*/g, "h")
    .replace(/\sд\p{L}*/gu, "д")
    .replace(/\sмин\p{L}*/gu, "мин")
    .replace(/\sчас\p{L}*/gu, "ч");
}

export const dateToString = d => {
  if (d instanceof Date)
    return `${d.getFullYear()}-${pad(d.getMonth() + 1, 2)}-${pad(d.getDate(), 2)}`;
  return `${d.year}-${pad(d.month, 2)}-${pad(d.day, 2)}`;
};

export const timeToString = time => `${time.hour}:${pad(time.minute, 2)}`;

export const stringDateToDate = date => {
  if (typeof date !== "string") return null;

  const [year, month, day] = date.split("-");

  return new Date(year, month - 1, day, 0, 0, 0, 0);
};

/**
 * Description of the time unit
 * @typedef {Object} TimeDescription
 * @prop {Number} period - how much times current unit lasted
 * @prop {Number} scale - scale to the first more precise time unit
 * @prop {String} type - time unit name (e.g. `"day"`, `"hour"`, `"minute"`)
 * @prop {Lingui::MessageDescriptor} word - translation from lingui for current unit
 */

function parseTimeToDict(min, precision) {
  let time = [
    { scale: 24, type: "days" },
    { scale: 60, type: "hours" },
    {
      scale: 60,
      type: "minutes",
      period: min,
    },
  ];
  let highestPeriodIndex = time.length - 1;

  for (let i = time.length - 2; i >= 0; i -= 1) {
    time[i].period = Math.floor(time[i + 1].period / time[i].scale);
    time[i + 1].period = time[i + 1].period % time[i].scale;

    if (time[i].period) {
      highestPeriodIndex = i;
    }
  }

  time = time.slice(highestPeriodIndex, precision ? highestPeriodIndex + precision : time.length);

  return time.reduce((res, item) => {
    if (!item.period) return res;
    res[item.type] = item.period;
    return res;
  }, {});
}

/**
 * Converts time interval into string of type `${min} - ${max} ${timeUnitName}`
 * @param {Number} min - start of the interval (in minutes)
 * @param {Number} max - end of the interval (in minutes)
 * @param {String} locale - locale code
 */
export function humanizeDistance(min, max, i18n, locale, isShort) {
  if ((!min && !max) || min > max) {
    return null;
  }

  const [timeFrom, timeTo] = [min, max].map(time => parseTimeToDict(time));

  const fromString = formatDuration(timeFrom, { locale: getFnsLocale(locale) });

  const toString = formatDuration(timeTo, { locale: getFnsLocale(locale) });

  if (min === max) {
    return isShort ? shortenTimestring(toString) : i18n._(t`About ${toString}`);
  }
  return isShort
    ? shortenTimestring(`${fromString} - ${toString}`)
    : i18n._(t`${fromString} - ${toString}`);
}

export function formatDateRange(dateRange, lang, dateFormat = "d MMM") {
  const { from: start, to: end } = dateRange;

  if (!start) return "";

  const startDateFormatted = translateDate(start, lang, dateFormat);
  const isSameDate = end && dateToString(end) === dateToString(start);

  if (end && !isSameDate) {
    const endDateFormatted = translateDate(end, lang, dateFormat);
    const isSameMonth = start.getMonth() === end.getMonth();

    return `${isSameMonth ? start.getDate() : startDateFormatted} - ${endDateFormatted}`;
  }

  return translateDate(start, lang, dateFormat);
}
