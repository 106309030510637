import React from "react";
import { Trans } from "@lingui/macro";

// TODO: refactor to one function
export const getStatusObjectByTimeslotStatus = status => {
  switch (status) {
    case "waiting_for_info":
      return {
        status: <Trans>Waiting</Trans>,
        text: <Trans>We are waiting for an addition on availability</Trans>,
        disabled: true,
      };
    case "available":
      return {};
    case "day_off":
      return {
        status: <Trans>Closed</Trans>,
        text: <Trans>The museum has a day off</Trans>,
        disabled: true,
      };
    case "sold_out":
      return {
        status: <Trans>Sold out</Trans>,
        text: <Trans>Tickets left nowhere</Trans>,
        disabled: true,
      };
    default:
      return {};
  }
};

export const getStatusObjectForCalendar = status => {
  switch (status) {
    case "waiting_for_info":
      return {
        status: <Trans>waiting</Trans>,
        text: <Trans>We are waiting for an addition on availability</Trans>,
        disabled: true,
      };
    case "available":
      return {};
    case "day_off":
      return {
        status: (
          <span style={{ color: "#FF3B2F" }}>
            <Trans>weekend</Trans>
          </span>
        ),
        text: <Trans>The museum has a day off</Trans>,
        disabled: true,
      };
    case "sold_out":
      return {
        status: <Trans>sold out</Trans>,
        text: <Trans>Tickets left nowhere</Trans>,
        disabled: true,
      };
    default:
      return {};
  }
};

export const getAvailableTimeslots = (data, categories) => {
  const totalParticipantsCapacity = categories.reduce((sum, { capacity }) => sum + capacity, 0);
  return data.filter(({ capacity }) => capacity >= totalParticipantsCapacity);
};
