import React, { useState, Children, useEffect } from "react";
import { useMediaQuery } from "beautiful-react-hooks";
import { isSSR } from "../NoSSR";
import { Icon } from "../Icon";
import classNames from "../../functions/classNames";
import "./Accordion.css";

/**
 * Accordion
 * @param {Object} $
 * @param {String} $.className - additional CSS class
 * @param {String} $.theme - component's theme
 * @param {React::Component} $.title - accordion header
 * @param {React::Component} $.children - accordion contents
 * @param {Boolean} $.prohibitEmpty - do not render if `null` or `undefined` passed to children (no content presented)
 * @param {Function} $.onToggled - user toggled accordion content visibility
 */
export default ({
  theme,
  title,
  children,
  className,
  prohibitEmpty,
  onToggled = () => {},
  hideIconCol = false,
  initiallyOpened = false,
}) => {
  const isMobile = !isSSR && useMediaQuery("(max-width: 767px)");
  const shouldAddColClass = isMobile && hideIconCol;

  const [opened, setOpened] = useState(initiallyOpened);

  useEffect(() => {
    onToggled(opened);
  }, [opened]);

  return !prohibitEmpty || Children.count(children) ? (
    <div
      className={classNames(
        "Accordion",
        opened && `Accordion--opened`,
        theme && `Accordion--${theme}`,
        className,
      )}
    >
      <button
        className="Accordion__button row"
        onClick={e => {
          e.preventDefault();
          setOpened(!opened);
        }}
      >
        <div className={classNames("Accordion__title", shouldAddColClass && "col-xs")}>{title}</div>
        <div className={classNames("Accordion__icon grow-0", shouldAddColClass && "col-xs")}>
          <Icon name={`chevron/${opened ? "up" : "down"}`} width="24" height="24" />
        </div>
      </button>
      <div className={classNames("Accordion__content", opened && "Accordion__content--opened")}>
        {children}
      </div>
    </div>
  ) : null;
};
