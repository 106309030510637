/**
 * Describes icon transformations, forming current variation
 * @typedef {Object} IconTransform
 * @prop {Number} rotate - icon rotation angle (in degrees)
 */

/**
 * Calculate replacement/transformation for the icon
 * @param {Array[IconTransform]} change - array with object, describing icon transformations
 * @returns {IconTransform} - resulting transformations collected from array
 */
export const getReplacement = change =>
  change.reduce(
    (acc, special) => {
      return {
        rotate: special.rotate || acc.rotate,
      };
    },
    {
      rotate: 0,
    },
  );
