import accent from "./accent";
import green from "./green";
import white from "./white";
import black from "./black";
import circledAccent from "./circledAccent";

export const check = {
  "check/accent": accent,
  "check/green": green,
  "check/white": white,
  "check/black": black,
  "check/circled-accent": circledAccent,
};
