/* eslint-disable no-underscore-dangle */
import { isSSR } from "./components/NoSSR";

export const runtimeConfig = !isSSR
  ? {
      // client
      ENV: window._ENV_.ENV || window._ENV_.NODE_ENV,
      DOMAIN_REGION: window._ENV_.DOMAIN_REGION,
      DOMAIN_URL: window._ENV_.DOMAIN_URL,
      STRIPE_PUBLISHABLE_KEY: window._ENV_.STRIPE_PUBLISHABLE_KEY,
      GOOGLE_MAPS_API_KEY: window._ENV_.GOOGLE_MAPS_API_KEY,
      RECAPTCHA_SITE_KEY: window._ENV_.RECAPTCHA_SITE_KEY,
      YOOKASSA_SHOP_ID: window._ENV_.YOOKASSA_SHOP_ID,
      RAZZLE_SENTRY_DSN: window._ENV_.RAZZLE_SENTRY_DSN,
      API_URL_PROXY: window._ENV_.API_URL_PROXY,
    }
  : {
      // server
      ENV: process.env.ENV || process.env.NODE_ENV,
      DOMAIN_REGION: process.env.DOMAIN_REGION,
      DOMAIN_URL: process.env.DOMAIN_URL,
      STRIPE_PUBLISHABLE_KEY: process.env.STRIPE_PUBLISHABLE_KEY,
      GOOGLE_MAPS_API_KEY: process.env.GOOGLE_MAPS_API_KEY,
      RECAPTCHA_SITE_KEY: process.env.RECAPTCHA_SITE_KEY,
      RAZZLE_SENTRY_DSN: process.env.RAZZLE_SENTRY_DSN,
      YOOKASSA_SHOP_ID: process.env.YOOKASSA_SHOP_ID,
      API_URL_PROXY: process.env.API_URL_PROXY,
    };
