import React from "react";
import { ErrorMessage } from "formik";
import classNames from "../../functions/classNames";
import "./ErrorMessageLine.css";

const extractErrorMessages = error => {
  if (typeof error === "string") {
    return error;
  } else if (typeof error === "object") {
    return Object.values(error).map(extractErrorMessages).join(" ");
  }
  return "";
};

export default function ErrorMessageLine({ className, ...props }) {
  return (
    <ErrorMessage {...props}>
      {error => {
        const errorMessage = extractErrorMessages(error);
        return <div className={classNames("ErrorMessageLine", className)}>{errorMessage}</div>;
      }}
    </ErrorMessage>
  );
}
