import React from "react";
import { NumberFormat } from "@lingui/macro";

Number.isInteger =
  Number.isInteger ||
  function isInteger(value) {
    return typeof value === "number" && Number.isFinite(value) && Math.floor(value) === value;
  };

export default ({ value, currencyCode }) =>
  (currencyCode && value) || value === 0 ? (
    <NumberFormat
      value={value}
      format={{
        style: "currency",
        currency: currencyCode,
        minimumFractionDigits: Number.isInteger(value) ? 0 : 2,
        maximumFractionDigits: 2,
      }}
    />
  ) : null;
