import { takeEvery, put, call, select, delay } from "redux-saga/effects";
import get from "lodash/get";
import isEqual from "lodash/isEqual";
import { t } from "@lingui/macro";
import { DISCOUNT_NO_DATA, DISCOUNT_WRONG } from "../../constants/errorTypes";
import * as types from "../types";
import { Api } from "../../functions/fetchFromApi";
import { dateToString } from "../../functions/dateTimeUtils";
import sendEvent, {
  ANALYTICS,
  convertProduct,
  sendPinterestEvent,
} from "../../functions/analytics";
import { isPaymentComplete } from "../../functions/payments";
import { getAvailableTimeslots } from "../../functions/timeslotsVariants";

const PAYMENT_STATUS_INTERVAL = 10000;
const PAYMENT_STATUS_MAX_TRIES = 6;
const YOOKASSA_STATUS_MAX_TRIES = 6;

// TODO: create helper functions

function* handleDaysFetchFailure(action) {
  const { productId, start, end, days, categories, isAppend } = action;

  let endDate;
  if (!end) {
    const till = new Date();
    till.setDate(till.getDate() + days);
    endDate = dateToString(till);
  } else {
    endDate = end;
  }
  const currency = yield select(state => state.currencies.currentCurrency);
  try {
    const { data } = yield call(Api.post, `/api/v2/products/${productId}/days/`, {
      payload: { start, end: endDate, currency, ...(categories?.length ? { categories } : {}) },
    });

    if (!data) {
      const lazyMessage = t`There are no dates available. Change the number of attendees to attend or select another product.`;
      yield put({ type: types.FETCH_DAYS_FAILURE, error: lazyMessage });
      yield put({ type: types.SHOW_ALERT, data: { lazyMessage } });
    }
    yield put({ type: types.FETCH_DAYS_SUCCESS, data, isAppend });
  } catch (error) {
    const lazyMessage = t`Failed to get available dates. Please reload the page or choose another product.`;
    yield put({ type: types.FETCH_DAYS_FAILURE, error: lazyMessage });
    yield put({ type: types.SHOW_ALERT, data: { lazyMessage, actions: [] } });
  }
}

/**
 * Fetch available dates for tickets and tour with tickets
 * @param {Object} action
 * @param {string} action.productId - id of product
 * @param {string} action.end - Exact date till fetch in YYYY-MM-DD format
 * @param {string} action.days - For how many days in future fetch availability
 */
function* fetchDays(action) {
  const { productId, start, end, days, categories, isAppend } = action;

  let endDate;
  if (!end) {
    const till = new Date();
    till.setDate(till.getDate() + days);
    endDate = dateToString(till);
  } else {
    endDate = end;
  }

  const currency = yield select(state => state.currencies.currentCurrency);

  try {
    const { data } = yield call(Api.post, `/api/v2/products/${productId}/days/`, {
      payload: { start, end: endDate, currency, ...(categories?.length ? { categories } : {}) },
    });

    if (!data) {
      const lazyMessage = t`There are no dates available. Change the number of attendees to attend or select another product.`;
      yield put({ type: types.FETCH_DAYS_FAILURE, error: lazyMessage });
      yield put({ type: types.SHOW_ALERT, data: { lazyMessage } });
      return;
    }

    yield put({ type: types.FETCH_DAYS_SUCCESS, data, isAppend });
  } catch (error) {
    yield call(handleDaysFetchFailure, action);
  }
}

function* handleTimeslotsFetchFailure({ productId, filterAvailable, categories, ...props }) {
  try {
    const { data } = yield call(Api.post, `/api/v2/products/${productId}/timeslots/`, {
      payload: { categories, ...props },
    });

    const timeslots = filterAvailable ? getAvailableTimeslots(data, categories) : data;
    yield put({ type: types.FETCH_TIMESLOTS_SUCCESS, data: timeslots });
  } catch (error) {
    const lazyMessage = t`Error while getting timeslots. Please refresh the page or select a different date`;
    yield put({ type: types.FETCH_TIMESLOTS_FAILURE, error: lazyMessage });
    yield put({ type: types.SHOW_ALERT, data: { lazyMessage, actions: [] } });
  }
}

function* fetchTimeslots(action) {
  const { productId, date, categories, filterAvailable = true } = action;
  const currency = yield select(state => state.currencies.currentCurrency);
  try {
    const { data } = yield call(Api.post, `/api/v2/products/${productId}/timeslots/`, {
      payload: { date, categories, currency },
    });

    const timeslots = filterAvailable ? getAvailableTimeslots(data, categories) : data;

    yield put({ type: types.FETCH_TIMESLOTS_SUCCESS, data: timeslots });
  } catch (error) {
    yield call(handleTimeslotsFetchFailure, {
      productId,
      filterAvailable,
      date,
      categories,
      currency,
    });
  }
}

function* fetchOptions(action) {
  const { productId, date, onSuccess = () => {} } = action;

  try {
    const data = yield call(
      Api.get,
      `/api/v2/products/${productId}/day_availability/?date=${date}`,
    );
    yield put({ type: types.FETCH_OPTIONS_SUCCESS, data });
    yield call(onSuccess, [...data]);
  } catch (error) {
    const lazyMessage = t`Could not fetch available options. Please, refresh this page or select another tour.`;
    yield put({ type: types.FETCH_OPTIONS_FAILURE, error: lazyMessage });
    yield put({ type: types.SHOW_ALERT, data: { lazyMessage, actions: [] } });
  }
}

function* createPayment(action) {
  const {
    yooKassa,
    productId,
    paymentMethod,
    promo,
    date,
    time,
    passengers,
    customer,
    onCompleted = () => {},
    params = {},
    recaptcha,
  } = action;

  // yield put({ type: types.DISABLE_PAYMENT_FORM });

  const currency = yield select(state => state.currencies.currentCurrency);
  try {
    const {
      data: { confirmation_token: confirmationToken, ...data },
    } = yield call(Api.post, `/api/v2/payments/${yooKassa ? "yookassa/" : ""}`, {
      payload: {
        currency,
        promo,
        type: paymentMethod,
        product: productId,
        date,
        time,
        reservations: [
          {
            passengers: passengers.map(({ pricingCategoryId, ...pass }) => ({
              ...pass,
              pricingCategoryId: pricingCategoryId.replace("id-", ""),
            })),
          },
        ],
        customer: {
          fullName: [customer.firstName, customer.lastName].join(""),
          ...customer,
        },
        recaptcha,
        ...params,
      },
    });
    yield put({
      type: types.CREATE_PAYMENT_SUCCESS,
      data: { ...data, confirmationToken },
      paymentMethod,
    });
    yield put({ type: types.ENABLE_PAYMENT_FORM });
    onCompleted(true, data);
  } catch (error) {
    const lazyMessage = error.message
      ? error.message
      : t`Looks like something is broken, please email us at support@wegotrip.com and we'll help you get your order.`;
    yield put({ type: types.SHOW_ALERT, data: { actions: [], lazyMessage } });
    yield put({ type: types.CREATE_PAYMENT_FAILURE, error: lazyMessage });
    yield put({ type: types.ENABLE_PAYMENT_FORM });
    yield put({
      type: types.DISABLE_PAYMENT_SUBMITTING,
    });
    onCompleted(false, error);
  }
}

/**
 * Requests server for changing payment method
 * @param {Object} action
 * @param {Number} action.id - payment id
 * @param {String} action.method - selected payment method (`"AP"`, `"GP"` or `"CP"`)
 */
function* selectPaymentMethod({ id, method }) {
  const { confirmationCode } = yield select(state => state.availability.payment);

  try {
    yield call(Api.put, `/api/v2/payments/${id}/`, {
      textBody: true,
      payload: { confirmationCode, paymentType: method },
    });
    yield put({ type: types.SELECT_PAYMENT_METHOD_SUCCESS, method });
  } catch (error) {
    yield put({ type: types.SELECT_PAYMENT_METHOD_FAILURE, error });
  }
}

const STRIPE_ERROR_MAP = {
  generic_decline: t`The card was declined. Please, try another card or contact your bank.`,
  insufficient_funds: t`Your card has insufficient funds. Please, try another card or contact your bank.`,
  lost_card: t`The card was declined for an unknown reason. Please, contact your bank.`,
  stolen_card: t`The card was declined for an unknown reason. Please, contact your bank.`,
  fraudulent: t`The card was declined for an unknown reason. Please, contact your bank.`,
  incorrect_number: t`The card number is incorrect. Please try again.`,
  incorrect_cvc: t`The entered CVC is incorrect. Please try again.`,
  expired_card: t`This card has expired. Please try another payment method.`,
  invalid_expiry_month: t`The entered expiry month is incorrect. Please try again.`,
  invalid_expiry_year: t`The entered expiry year is incorrect. Please try again.`,
  test_mode_live_card: t`Your card was declined. Your request was in test mode, but used a non-test card. For a list of valid test cards, visit: https://stripe.com/docs/testing.`,
  invalid_number: t`Your card number is incorrect. Please try again.`,
  processing_error: t`A bank processing error occurred. Please try again.`,
  payment_intent_authentication_failure: t`Authentication failed. Please try again. An unexpected error has occurred.`,
  authentication_required: t`Authentication was required. Please try again.`,
  approve_with_id: t`Authentication failed. Please try again.`,
  call_issuer: t`The card was declined for an unknown reason. Please try another card or contact your bank.`,
  card_not_supported: t`The card does not support this type of purchase. Please try another card or contact your bank.`,
  card_velocity_exceeded: t`The balance credit limit has been exceeded available on the card. Please try another card or contact your bank.`,
  currency_not_supported: t`The card does not support the payment currency. Please try another card or contact your bank.`,
  do_not_honor: t`An unexpected error has occurred. Please try another card or contact your bank.`,
  do_not_try_again: t`An unexpected error has occurred. Please try another card or contact your bank.`,
  duplicate_transaction: t`A transaction with identical amount and credit card information was submitted very recently. Please check the previous order or try again later.`,
  incorrect_pin: t`The PIN entered is incorrect. Please try again.`,
  incorrect_zip: t`The postal code is incorrect. Please try again.`,
  invalid_account: t`The card, or account the card is connected to, is invalid. Please try another card or contact your bank.`,
  invalid_amount: t`The payment amount exceeds the amount that’s allowed. Please try again or contact your bank.`,
  invalid_cvc: t`The CVC number is incorrect. Please try again.`,
  invalid_pin: t`The PIN entered is incorrect. Please try again.`,
  issuer_not_available: t`Authentication failed. Please try again or contact your bank.`,
  merchant_blacklist: t`The card was declined for an unknown reason. Please contact your bank.`,
  new_account_information_available: t`The card, or account the card is connected to, is invalid. Please try another card or contact your bank.`,
  no_action_taken: t`The card was declined for an unknown reason. Please try another card or contact your bank.`,
  not_permitted: t`The payment isn’t permitted. Please try another card or contact your bank.`,
  offline_pin_required: t`The card was declined because it requires a PIN. Please try again.`,
  online_or_offline_pin_required: t`The card was declined because it requires a PIN. Please try again.`,
  pickup_card: t`The card was declined. Please contact your bank or try another card.`,
  pin_try_exceeded: t`The allowable number of PIN tries was exceeded. Please try another card.`,
  reenter_transaction: t`The payment couldn’t be processed. Please try again or contact your bank.`,
  restricted_card: t`The card was declined. Please contact your bank or try another card.`,
  revocation_of_all_authorizations: t`The card was declined. Please contact your bank or try another card.`,
  revocation_of_authorization: t`The card was declined. Please contact your bank or try another card.`,
  security_violation: t`The card was declined. Please contact your bank or try another card.`,
  service_not_allowed: t`The card was declined. Please contact your bank or try another card.`,
  stop_payment_order: t`The card was declined. Please contact your bank or try another card.`,
  testmode_decline: t`A Stripe test card number was used.`,
  transaction_not_allowed: t`The card was declined. Please contact your bank or try another card.`,
  try_again_later: t`The card was declined. Please try again later or contact your bank.`,
  withdrawal_count_limit_exceeded: t`The balance credit limit has been exceeded available on the card. Please try another card or contact your bank.`,
};

function* submitStripePaymentFailure(action) {
  const { error } = action;

  let errorMessage = STRIPE_ERROR_MAP[error.code];

  if (error.decline_code !== undefined) {
    errorMessage = STRIPE_ERROR_MAP[error.decline_code];
  }

  if (!errorMessage) {
    errorMessage = t`An unexpected error has occurred.`;
  }

  yield put({ type: types.SHOW_ALERT, data: { lazyMessage: errorMessage, actions: [] } });
  yield put({ type: types.CREATE_PAYMENT_FAILURE, error: errorMessage });
}

/**
 * Requests yooKassa about payment status
 */
function* bombYookassa({ id, tryIndex = 0, cookies, lang }) {
  const { data: yooKassaPayment } = yield call(Api.post, "/api/v2/payments/yookassa/status/", {
    payload: { yooKassa_id: id },
  });

  const confirmationUrl = get(yooKassaPayment, "confirmation.confirmation_url");
  if (confirmationUrl) {
    window.location.href = confirmationUrl;
  } else if (["succeeded", "canceled"].includes(yooKassaPayment.status)) {
    yield put({ type: types.GET_PAYMENT_STATUS, cookies, lang });
  } else if (tryIndex < YOOKASSA_STATUS_MAX_TRIES) {
    yield delay(PAYMENT_STATUS_INTERVAL);
    yield put({ type: types.BOMB_YOOKASSA, tryIndex: tryIndex + 1, cookies, lang });
  } else if (tryIndex === YOOKASSA_STATUS_MAX_TRIES) {
    const { userId, productId } = yield select(({ user, products }) => ({
      userId: user.user.id,
      productId: products.selectedProduct.id,
    }));

    sendEvent("track", "checkout_payment_server_failure", {
      user_id: userId,
      product_id: productId,
    });
  }
}

/**
 * Checks payment status
 *  - if status of the payment from WeGoTrip server is "pending" requests payment provider in loop
 *  - after payment completion confirmation from provider runs `GET_PAYMENT_STATUS`
 * !IMPORTANT: expects `store::availability::paymentStatus` to be loaded already
 */
function* getExternalPaymentStatus({ cookies, lang, history }) {
  const paymentStatus = yield select(state => state.availability.paymentStatus);

  if (paymentStatus.status !== "done") {
    if (paymentStatus.yooKassa_id) {
      yield put({ type: types.BOMB_YOOKASSA, id: paymentStatus.yooKassa_id, history });
    } else {
      yield put({ type: types.GET_PAYMENT_STATUS, cookies, lang });
    }
  } else {
    yield put({ type: types.GET_PAYMENT_STATUS, cookies, lang });
  }
}

/**
 * Triggers payment status fetching if stack of calls not exceeded
 * @param {Object} error - error description from previous call, also if set triggers error dispatching
 * @param {Object} params - parameters for net call of `GET_PAYMENT_STATUS`
 */
function* runPaymentStatus(error, params = {}) {
  const { statusTried } = yield select(state => state.availability.paymentStatus);

  if (error) {
    yield put({ type: types.GET_PAYMENT_STATUS_FAILURE, error });
    yield delay(PAYMENT_STATUS_INTERVAL);
  }
  if (statusTried < PAYMENT_STATUS_MAX_TRIES) {
    yield put({ type: types.GET_PAYMENT_STATUS, ...params });
  }
}

/**
 * Gets payment status in loop while payment is not passed at backend
 * Payment is considered passed if responce status code is `200` and `link` or `access` field exists in the responce
 * @param {Object} action
 */
function* getPaymentStatus(action) {
  const { confirmationCode, id } = yield select(state => state.availability.payment);
  const { statusTried } = yield select(state => state.availability.paymentStatus);
  const userId = yield select(({ user }) => user.user.id);
  const { selectedProduct: product } = yield select(state => state.products);

  if (statusTried >= PAYMENT_STATUS_MAX_TRIES) {
    yield put({ type: types.PAYMENT_STATUS_TIMEOUT });
    sendEvent("track", "checkout_payment_server_failure", {
      user_id: userId,
      product_id: product.id,
    });
    return;
  }

  try {
    const {
      data,
      data: { price, currency: { code: currencyCode } = {} },
    } = yield call(Api.post, `/api/v2/payments/${id}/`, {
      payload: { confirmationCode },
      cookies: action.cookies,
    });

    if (!isPaymentComplete(data, "payment")) {
      throw new Error(
        `No obligatory \`access\` or \`link\` field in responce Object for payment ${id}`,
      );
    }

    yield put({ type: types.GET_PAYMENT_STATUS_SUCCESS, data });

    const {
      codes: [
        {
          discount,
          promotion: { code: coupon },
        },
      ] = [{ promotion: {} }],
    } = yield select(({ availability }) => availability.discount);

    /**
     * Sending analytics
     */
    if (window.localStorage && localStorage.getItem(`${id}_recorded`)) {
      return;
    }

    const quantity = Math.round(price / product.price);
    // TODO: split passengers' categories as separate products or other way to properly calculate
    sendEvent("track", "Order Completed", {
      order_id: id,
      total: price,
      currency: currencyCode,
      discount,
      coupon,
      ...(product ? { products: [convertProduct(product, { lang: action.lang, quantity })] } : {}),
    });

    // send `purchase` event to GA4
    sendEvent(
      "track",
      "purchase",
      {
        transaction_id: id,
        ...(userId ? { user_id: userId } : {}),
        value: price,
        currency: currencyCode,
        coupon,
        ...(product
          ? { items: convertProduct(product, { lang: action.lang, quantity }, true) }
          : {}),
      },
      { include: [ANALYTICS.GA4] },
    );

    sendEvent("track", "Checkout Step Completed", { step: 3 });
    sendPinterestEvent("Checkout", { product, quantity, totalPrice: price, orderId: id });

    localStorage.setItem(`${id}_recorded`, true);
  } catch (error) {
    yield call(runPaymentStatus, error, { cookies: action.cookies, lang: action.lang });
  }
}

/**
 * Checks discount code by requesting server
 * @param {Object} action
 * @param {Object} action.product - description of product to which code is applying
 * @param {String} action.promo - discount code
 * @param {String?} action.date - date of the travel (for tours with date required) in format `"yyyy-mm-dd"`
 * @param {String?} action.time - time of the travel (for tours with tickets) in format `HH:mm`
 * @param {Object} action.categories - categories, initted the request (will be saved in store to control the relativity of the responces if required data changed during the request)
 * @param {Array[Object]} action.reservations - pricing categories for participants (as in `CREATE_PAYMENT`)
 */
function* checkDiscount({ product, promo, date, time, reservations, categories }) {
  yield put({ type: types.CHECK_DISCOUNT_INIT, categories });

  try {
    const { data } = yield call(Api.post, "/api/v2/promo/check/", {
      payload: {
        product: product.id,
        currency: product.currencyCode,
        promo,
        date,
        time,
        reservations,
      },
    });

    const requestedCats = yield select(
      ({ availability }) => availability.discount.requiredCategories,
    );
    if (requestedCats && isEqual(requestedCats, categories)) {
      yield put({ type: types.CHECK_DISCOUNT_SUCCESS, data });
    }
  } catch (error) {
    yield put({ type: types.CHECK_DISCOUNT_FAILURE, error });

    const match = error.message.match(
      /Maximum number of participants for this promo code is (\d+)/,
    );
    const maxParticipants = match ? parseInt(match[1], 20) : null;

    if (maxParticipants !== null) {
      yield put({
        type: types.ERROR_ADD,
        error: { type: `DISCOUNT_MAX_PARTICIPANTS_${maxParticipants}` },
      });
    } else {
      switch (error.status) {
        case 100:
          yield put({ type: types.ERROR_ADD, error: { type: DISCOUNT_NO_DATA } });
          break;
        default:
          yield put({ type: types.ERROR_ADD, error: { type: DISCOUNT_WRONG } });
          break;
      }
    }
  }
}

export default function* watch() {
  yield takeEvery(types.FETCH_DAYS, fetchDays);
  yield takeEvery(types.FETCH_TIMESLOTS, fetchTimeslots);
  yield takeEvery(types.FETCH_OPTIONS, fetchOptions);
  yield takeEvery(types.CREATE_PAYMENT, createPayment);
  yield takeEvery(types.SELECT_PAYMENT_METHOD, selectPaymentMethod);
  yield takeEvery(types.BOMB_YOOKASSA, bombYookassa);
  yield takeEvery(types.GET_EXTERNAL_PAYMENT_STATUS, getExternalPaymentStatus);
  yield takeEvery(types.GET_PAYMENT_STATUS, getPaymentStatus);
  yield takeEvery(types.SUBMIT_STRIPE_PAYMENT_FAILURE, submitStripePaymentFailure);
  yield takeEvery(types.CHECK_DISCOUNT, checkDiscount);
}
