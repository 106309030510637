/**
 * Gets errors from redux `errors.errors` field by type
 * @param {Array[SagaError]} errors - `errors.errors` field from redux state
 * @param {String|Array[String]} errorTypes - type/types of the searching errors
 * @returns {Array[SagaError]}
 */
export default function getSagaErrors(errors = [], errorTypes = []) {
  const processedTypes = errorTypes instanceof Array ? errorTypes : [errorTypes];
  return errors.filter(({ type }) => processedTypes.some(errType => type === errType));
}
