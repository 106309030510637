export const heart = {
  heart: {
    shape:
      '<path fill="#000" fill-opacity=".98" d="m12 19.65-.75-.675c-1.633-1.5-2.983-2.78-4.05-3.838-1.067-1.058-1.912-1.991-2.537-2.8-.625-.808-1.059-1.541-1.301-2.2A5.734 5.734 0 0 1 3 8.15c0-1.267.433-2.333 1.3-3.2s1.933-1.3 3.2-1.3c.883 0 1.708.225 2.475.675.767.45 1.442 1.108 2.025 1.975.583-.867 1.258-1.525 2.025-1.975A4.807 4.807 0 0 1 16.5 3.65c1.267 0 2.333.433 3.2 1.3S21 6.883 21 8.15c0 .667-.12 1.329-.362 1.987-.242.659-.675 1.392-1.3 2.2-.625.809-1.467 1.742-2.526 2.8a120.157 120.157 0 0 1-4.062 3.838l-.75.675Zm0-1.35a113.68 113.68 0 0 0 3.95-3.725c1.033-1.033 1.85-1.93 2.45-2.688.6-.758 1.017-1.429 1.25-2.012.233-.583.35-1.158.35-1.725 0-1-.333-1.833-1-2.5s-1.5-1-2.5-1c-.8 0-1.537.229-2.212.687-.675.459-1.271 1.138-1.788 2.038h-1c-.533-.917-1.133-1.6-1.8-2.05a3.85 3.85 0 0 0-2.2-.675c-.983 0-1.812.333-2.487 1S4 7.15 4 8.15c0 .567.117 1.142.35 1.725.233.583.65 1.254 1.25 2.012.6.759 1.417 1.65 2.45 2.675A141.71 141.71 0 0 0 12 18.3Z"/>',
    viewBox: [0, 0, 24, 24],
  },
  "heart/white": {
    shape:
      '<path fill="#FFFFFF" fill-opacity=".98" d="m12 19.65-.75-.675c-1.633-1.5-2.983-2.78-4.05-3.838-1.067-1.058-1.912-1.991-2.537-2.8-.625-.808-1.059-1.541-1.301-2.2A5.734 5.734 0 0 1 3 8.15c0-1.267.433-2.333 1.3-3.2s1.933-1.3 3.2-1.3c.883 0 1.708.225 2.475.675.767.45 1.442 1.108 2.025 1.975.583-.867 1.258-1.525 2.025-1.975A4.807 4.807 0 0 1 16.5 3.65c1.267 0 2.333.433 3.2 1.3S21 6.883 21 8.15c0 .667-.12 1.329-.362 1.987-.242.659-.675 1.392-1.3 2.2-.625.809-1.467 1.742-2.526 2.8a120.157 120.157 0 0 1-4.062 3.838l-.75.675Z"/>',
    viewBox: [0, 0, 24, 24],
  },
  "heart/red-filled": {
    shape:
      '<path fill="#E44656" fill-opacity=".98" d="m12 19.65-.75-.675c-1.633-1.5-2.983-2.78-4.05-3.838-1.067-1.058-1.912-1.991-2.537-2.8-.625-.808-1.059-1.541-1.301-2.2A5.734 5.734 0 0 1 3 8.15c0-1.267.433-2.333 1.3-3.2s1.933-1.3 3.2-1.3c.883 0 1.708.225 2.475.675.767.45 1.442 1.108 2.025 1.975.583-.867 1.258-1.525 2.025-1.975A4.807 4.807 0 0 1 16.5 3.65c1.267 0 2.333.433 3.2 1.3S21 6.883 21 8.15c0 .667-.12 1.329-.362 1.987-.242.659-.675 1.392-1.3 2.2-.625.809-1.467 1.742-2.526 2.8a120.157 120.157 0 0 1-4.062 3.838l-.75.675Z"/>',
    viewBox: [0, 0, 24, 24],
  },
};
