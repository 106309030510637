import React from "react";
import { Icon } from "../Icon";

const socialLinks = {
  instagram: {
    com: "https://instagram.com/wegotripcom",
    // ru: "https://instagram.com/wegotrip_russia/",
  },
  facebook: {
    com: "https://facebook.com/wegotripcom",
    // ru: "https://facebook.com/wegotriprussia",
  },
  vk: { ru: "https://vk.com/wegotrip" },
  pinterest: { com: "https://pinterest.com/wegotrip" },
  tiktok: { com: "https://tiktok.com/@wegotripcom" },
  twitter: { com: "https://twitter.com/wegotrip" },
  linkedin: { com: "https://linkedin.com/company/wegotrip" },
};

const iconSize = { width: 32, height: 32 };

const SocialIcon = ({ lang, platform, link, iconProps }) => {
  if (!lang || !platform || !link) {
    return null;
  }

  return (
    <a href={link} target="_blank" rel="noreferrer" className="Footer__social">
      <Icon name={platform} {...iconProps} />
    </a>
  );
};

const SocialIcons = ({ lang }) => {
  const platforms = Object.keys(socialLinks);

  return (
    <>
      {platforms.map(platform => {
        const links = socialLinks[platform];

        return (
          <SocialIcon
            key={platform}
            lang={lang}
            platform={platform}
            link={links[lang === "ru" ? lang : "com"] || links.both}
            iconProps={iconSize}
          />
        );
      })}
    </>
  );
};

export default SocialIcons;
