import { runtimeConfig } from "../config";
import { DOMAIN_URL } from ".";

export const IMAGEPROXY_BASE_URL = `${runtimeConfig.API_URL_PROXY || DOMAIN_URL}/imgproxy/insecure`;

export function getImgProxyPath(originalUrl, options = {}) {
  const {
    resizingType = "fill",
    width,
    height,
    gravity,
    enlarge = 1,
    extension = "",
    // IMGPROXY_ENABLE_WEBP_DETECTION: enables WebP support detection. When the file extension is omitted in the imgproxy URL and browser supports WebP, imgproxy will use it as the resulting format;
    quality,
  } = options;
  let origin = "";
  let path = originalUrl;
  const foundOrigin = originalUrl.match(/^\w*:\/\/[^/]*/);
  if (foundOrigin) {
    [origin] = foundOrigin;
    path = originalUrl.slice(foundOrigin.index + foundOrigin[0].length);
  }

  /**
   * On production get static files from imgproxy,
   *   on development build use local links provided by webpack without img-proxy
   *   (if new static image was added in dev it won't be accessible via img-proxy from production)
   */
  if (path.startsWith("/static/media")) {
    origin = DOMAIN_URL;
  } else if (path.startsWith(`/media`)) {
    // Use local files for store media images
    origin = "local:";
    path = path.replace("/media", "");
  } else {
    origin = ["plain", origin].filter(s => !!s).join("/");
  }

  // if (path.startsWith("/")) {
  //   // TODO: properly serve local files if the file on the same server with proxy imgproxy itself
  //   // url = `local:///${path}`;
  //   url = `${runtimeConfig.SHOW_URL}${path}`;
  // }

  //  TODO: enable signing https://app.clickup.com/t/1p0ac09
  //  const encoded_url = urlSafeBase64(url);
  //  return `/rs:${resizingType}:${width}:${height}:${enlarge}/g:${gravity}/?url=${encoded_url}.${extension}`;

  /**
   * We are using ?url= param because of our nginx setup,
   *   please look https://github.com/SurpriseMeru/web_branding for details
   */
  return `${
    resizingType === "none"
      ? ``
      : `/rs:${resizingType}:${Math.floor(width)}:${Math.floor(height)}:${enlarge}`
  }/${gravity ? `g:${gravity}/` : ""}${quality ? `q:${quality}/` : ""}plain/?url=${origin}${path}${
    extension ? `@${extension}` : ""
  }`;
}

export function getImgProxyUrl(url) {
  if (!url) return null;
  return url;
}
