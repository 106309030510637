import React, { forwardRef } from "react";
import { DropdownButton, withDropdownBody } from "../Dropdown";
import Popup from "../Popup";
import classNames from "../../functions/classNames";

import "./PopupOrDropdownAdapter.css";

/**
 * Renders element with `dropdown` or `bottomSheet` after click on it
 * @param {Object} $
 * @param {React::Component|String} $.title - header for Dropdown or bottomSheet
 * @param {React::Component|String} $.theme - `bottomSheet`. If not set dropdown will be rendered on click
 * @param {Boolean} $.triggerElement -  - element that will open/close the `dropdown`/`bottomSheet` on click
 * @param {React::Component} $.ContentComponent - dropdown was selected
 * @param {React::Component} $.props - other properties that will be passed to `dropdown`/`bottomSheet`
 */
export default forwardRef(function PopupOrDropdownAdapter(
  {
    title,
    ContentComponent,
    triggerElement,
    bottomButtonProps,
    altBottomButtonProps,
    isMobile,
    align = "left",
    bodyClassName,
    noButtonWrap = true,
    disableOutside = true,
    icon,
    onClose,
    onStateChange,
    ...props
  },
  ref,
) {
  const DropdownBody = withDropdownBody(ContentComponent);
  const bodyClass = "PopupOrDropdownAdapter";
  const bottomButton = bottomButtonProps && { theme: "black", ...bottomButtonProps };
  const altBottomButton = altBottomButtonProps && { isAlt: true, ...altBottomButtonProps };
  const theme = isMobile ? "bottomSheet" : "dropdown";
  const effectiveTriggerElement = icon && isMobile ? <span>{icon}</span> : triggerElement;

  return theme === "bottomSheet" ? (
    <Popup
      ref={ref}
      theme="bottomSheet"
      triggerElement={effectiveTriggerElement}
      title={title}
      bottomButtonProps={bottomButton}
      altBottomButtonProps={altBottomButton}
      bodyClassName={classNames(bodyClass, bodyClassName)}
      noButtonWrap={noButtonWrap}
      {...props}
    >
      <ContentComponent />
    </Popup>
  ) : (
    <DropdownButton
      ref={ref}
      mergeHandlers
      disableOutside={disableOutside}
      noButtonWrap={noButtonWrap}
      body={
        <DropdownBody
          title={title}
          bodyClassName={classNames(
            `${bodyClass} ${bodyClass}--dropdown`,
            `${bodyClass}--dropdown--${align}`,
            bodyClassName,
          )}
          bottomButtonProps={bottomButton}
          altBottomButtonProps={altBottomButton}
          handleClose={onClose}
        />
      }
      theme="container"
      onClose={onClose}
      onStateChange={onStateChange}
      {...props}
    >
      {effectiveTriggerElement}
    </DropdownButton>
  );
});
