/* eslint-disable react/no-unknown-property */
import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { DOMAIN_URL, DEFAULT_LANG } from "../../constants";
import reverseUrl from "../../functions/reverseUrl";
import { availableLangs } from "../../functions/urlLanguage";

/**
 * Language codes used in production
 */
const languageCodes = availableLangs.map(({ code }) => code);

/**
 * Adds `<link rel="alternate" />` with given URL path relative to current domain
 * @param {Object} $
 * @param {String} $.lang - current page language code. Might be `ru` or `en` for `hrefLang`
 * @param {String} $.path - URL path with trailing slash at start to add into `href` tag
 * @returns
 */
export default function Alternate({ lang = DEFAULT_LANG, path }) {
  return (
    <Helmet>
      <link rel="alternate" hrefLang={lang} href={`${DOMAIN_URL}${path}`} />
      {languageCodes
        .filter(langCode => langCode !== lang)
        .map(code => (
          <link key={code} rel="alternate" hrefLang={code} href={`${DOMAIN_URL}${path}`} />
        ))}
    </Helmet>
  );
}

/**
 * <Alternate /> with link forms from `reverseUrl` with autodetected params
 * @param {Object} $
 * @param {String} $.route - name of the current route from `/src/routes.js`
 * @returns
 */
export function AlternateAuto({ route }) {
  const { lang, ...params } = useParams();
  const path = reverseUrl(route, { lang, ...params });
  return <Alternate path={path} lang={lang} />;
}
