import React, { useEffect, useRef, useState } from "react";
import { getIn, useFormikContext } from "formik";
import PhoneInput from "react-phone-number-input";
import classNames from "../../functions/classNames";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import ErrorMessageLine from "../ErrorMessageLine";
import "react-phone-number-input/style.css";
import "./FormikInput.css";

export default ({
  field, // { name, value, onChange, onBlur }
  form: providedForm, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  withLabel,
  caption,
  placeholder,
  type = "text",
  label,
  ...props
}) => {
  const { touched, errors, setFieldValue, setFieldTouched } = providedForm || useFormikContext();
  const inputRef = useRef(null);
  const lang = useCurrentLanguage();
  const [focus, setFocus] = useState(null);
  const isTouched = getIn(touched, field.name);
  const error = getIn(errors, field.name);
  const isMultiline = type === "textarea";
  const InputTag = isMultiline ? "textarea" : "input";
  const className = classNames(
    "FormikInput",
    error && isTouched && "FormikInput--error",
    isMultiline && "FormikInput--multiline",
    (caption || error) && "m-8",
    focus !== null && focus && "focus",
  );

  useEffect(() => {
    if (inputRef.current) {
      const handleCursorPosition = () => {
        const { length } = inputRef.current.value;
        inputRef.current.setSelectionRange(length, length);
      };

      const inputElement = inputRef.current;

      inputElement.addEventListener("focus", handleCursorPosition);

      return () => {
        inputElement.removeEventListener("focus", handleCursorPosition);
      };
    }
  }, []);

  return (
    <div className={classNames("FormikInput__wrapper")}>
      {withLabel && <div className="t-15 m-8">{label || placeholder}</div>}
      {type === "tel" ? (
        <PhoneInput
          ref={inputRef}
          {...field}
          {...props}
          className={className}
          placeholder={placeholder}
          onChange={value => {
            setFieldValue(field.name, value);
          }}
          onBlur={() => {
            setFieldTouched(field.name);
            setFocus(false);
          }}
          onFocus={() => {
            props.onFocus();
            setFocus(true);
          }}
          {...(lang === "ru"
            ? {
                defaultCountry: "RU",
                useNationalFormatForDefaultCountryValue: true,
              }
            : {})}
        />
      ) : (
        <InputTag className={className} placeholder={placeholder} {...props} {...field} />
      )}
      {isTouched && error && (
        <div className="t-13">
          <ErrorMessageLine name={field.name} />
        </div>
      )}
      {!error && caption && <div className="t-13">{caption}</div>}
    </div>
  );
};
