import React, { useEffect, useState } from "react";

/**
 * Adds "0" if num has one digit
 * @param {Number} number
 * @return {String} - normalized and converted number
 */
function normalizeDigit(number) {
  let normalized = String(number);
  if (normalized.length < 2) {
    normalized = `0${normalized}`;
  }

  return normalized;
}

/**
 * Renders `mm:ss` text timer
 * Could not be reset - if you need to run again - reinstall into DOM
 * @param {Object} $
 * @param {Function} $.release - calls when timer ends up
 */
export default function SMSTimer({ release, timeout }) {
  const [time, setTime] = useState(timeout);

  useEffect(() => {
    let timeoutId = null;

    /**
     * Runs function every second for fixed period of time
     * @param {Function} workload - calls every second
     */
    function tick(workload, secondsLeft) {
      timeoutId = setTimeout(() => {
        workload(secondsLeft - 1);
        if (secondsLeft - 1) tick(workload, secondsLeft - 1);
      }, 1000);
    }

    tick(secondsLeft => {
      if (secondsLeft) setTime(secondsLeft);
      else release();
    }, timeout);

    return () => clearTimeout(timeoutId);
  }, [release, timeout]);

  return (
    <span>
      {normalizeDigit(Math.floor(time / 60))}:{normalizeDigit(time % 60)}
    </span>
  );
}
