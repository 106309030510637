import { startOfDay } from "date-fns";
import { runtimeConfig } from "../config";
import getDefaultLanguage from "../functions/languages/getDefaultLanguage";
import { dateToString } from "../functions/dateTimeUtils";

export const getFeedbackLink = locale => {
  return `https://wegotrip.com/${locale}/feedback/`;
};

export const DOMAIN_URL = runtimeConfig.DOMAIN_URL || "https://wegotrip.com";
export const DOMAIN_REGION = runtimeConfig.DOMAIN_REGION || "com";

export const { GOOGLE_MAPS_API_KEY, RECAPTCHA_SITE_KEY } = runtimeConfig;
export const DEFAULT_LANG = getDefaultLanguage(DOMAIN_REGION);

export const STRIPE_ACCOUNT_COUNTRY = "EE";

export const BOOKING_AVAILABILITY_MAX_LENGHT_MONTHS = 6;

export const TODAY_START = startOfDay(new Date());
export const TODAY_START_STRING = dateToString(TODAY_START);

const endDateCandidate = new Date(TODAY_START);

endDateCandidate.setMonth(endDateCandidate.getMonth() + BOOKING_AVAILABILITY_MAX_LENGHT_MONTHS - 1);
const lastDayOfMonth = new Date(endDateCandidate.getFullYear(), endDateCandidate.getMonth() + 1, 0);

const roundedEndDate = new Date(endDateCandidate);

roundedEndDate.setMonth(roundedEndDate.getMonth() + 1, 1);
roundedEndDate.setDate(roundedEndDate.getDate() - 1);

export const BOOKING_AVAILABILITY_END_DATE = lastDayOfMonth;
export const BOOKING_AVAILABILITY_END_DATE_STRING = dateToString(BOOKING_AVAILABILITY_END_DATE);

export const SEC = 1000;
export const MIN_IN_SEC = 60;
export const MIN = MIN_IN_SEC * SEC;
export const HOUR_IN_SEC = 60 * MIN_IN_SEC;
export const HOUR = 60 * MIN;
export const DAY = 24 * HOUR;
export const DAY_IN_SEC = 24 * HOUR_IN_SEC;
export const WEEK = 7 * DAY;
export const WEEK_IN_SEC = 7 * DAY_IN_SEC;
export const MONTH_IN_SEC = 30 * DAY_IN_SEC;

export const REVIEW_TEXT_WORDS_LIMIT = 55;
export const COOKIE_TEN_YEAR_AGE = 315569520;
export const COOKIE_WEEK_AGE = 604800;
export const COOKIE_MAX_EXPIRES = 400 * DAY_IN_SEC;

export const SCROLL_HEIGH_DISCOUNT_PROMO_TRIGGER = -400;
/**
 * Amount of seconds fonts will be stored in cache
 * From `cache-control: max-age` header at adobe fonts loader
 * @type {Number}
 */
export const FONTS_CACHE_AGE = 31536000;

/**
 * Page names
 */
export const ATTRACTION_PAGE = "attraction";
