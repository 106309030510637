import React, { useEffect } from "react";
import get from "lodash/get";
import { withI18n } from "@lingui/react";
import { Trans, t } from "@lingui/macro";
import { Field, Formik } from "formik";
import { connect, useDispatch } from "react-redux";
import * as types from "../../stores/types";
import classNames from "../../functions/classNames";
import { fetchOverview } from "../../functions/fetchData";
import { fetchDefaultCurrency } from "../../functions/currency";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import withRedirectToKnownLang from "../../functions/languages/withRedirectToKnownLang";
import Root from "../../components/_Root";
import { CanonicalAuto } from "../../components/Canonical";
import Button from "../../components/Button";
import parseQueryParams from "../../functions/parseQueryParams";
import { RadioButtonGroup, RadioButton } from "../../components/Radio";
import FormikInput from "../../components/FormikInput";
import "./Unsubscribe.css";
import Spinner from "../../components/Spinner";
import { AlternateAuto } from "../../components/Alternate";
import CookieFooter from "../../components/CookieFooter";

export const unsubscribeStatus = {
  sending: "Sending",
  sended: "Sended",
  error: "Error",
};

function ButtonText({ sending, isSended, error }) {
  if (sending) {
    return (
      <>
        <Spinner theme="white" /> <Trans>Sending...</Trans>
      </>
    );
  }
  if (error) return <Trans>Error</Trans>;
  return isSended ? <Trans>Successfully sent</Trans> : <Trans>Send</Trans>;
}

const DetailsArea = withI18n()(({ i18n, disabled }) => {
  return (
    <Field
      withLabel
      disabled={disabled}
      name="details"
      placeholder={i18n._(t`More details...`)}
      component={FormikInput}
    />
  );
});

const RadioButtons = withI18n()(({ i18n, values, handleReset, setFieldValue }) => {
  const translations = {
    first: i18n._(t`Emails are lost among inbox`),
    second: i18n._(t`I'm not going to use the WeGoTrip service anymore`),
    third: i18n._(t`Emails get into Spam`),
    fourth: i18n._(t`Too much emails`),
    fifth: i18n._(t`Did not use the WeGoTrip service, it's mistake`),
    sixth: i18n._(t`Other`),
  };

  const fields = Object.keys(translations).map(field => (
    <div key={field} className="t-15 m-16">
      <Field
        customValue={translations[field]}
        name={field}
        id={values[field]}
        component={RadioButton}
        label={translations[field]}
        customOnChange={() => {
          handleReset();
          setFieldValue(field, translations[field]);
        }}
      />
    </div>
  ));

  return <RadioButtonGroup>{fields}</RadioButtonGroup>;
});

const Reasons = withI18n()(({ i18n, dispatch, userId, user }) => {
  return (
    <Formik
      initialValues={{
        first: false,
        second: false,
        third: false,
        fourth: false,
        fifth: false,
        sixth: false,
        details: "",
      }}
    >
      {({ values, setFieldValue, handleReset }) => {
        const handleSubmit = event => {
          event.preventDefault();
          event.persist();
          let text;
          const answers = Object.values(values).filter(value => value);

          if (answers[1]) text = answers[1] ? answers[1] : i18n._(t`Other`);
          else [text] = answers;

          dispatch({
            type: types.SEND_UNSUBSCRIBE_TEXT,
            user: userId,
            text,
          });
        };

        return (
          <form onSubmit={handleSubmit}>
            <RadioButtons values={values} handleReset={handleReset} setFieldValue={setFieldValue} />
            <DetailsArea disabled={!values.sixth} />
            <Button
              className="Unsubscribe__button"
              disabled={!Object.values(values).filter(value => value).length}
              type="submit"
              theme="main"
            >
              <ButtonText
                sending={user.unsubscribeStatus === unsubscribeStatus.sending}
                isSended={user.unsubscribeStatus === unsubscribeStatus.sended}
                error={user.unsubscribeStatus === unsubscribeStatus.error}
              />
            </Button>
          </form>
        );
      }}
    </Formik>
  );
});

const Unsubscribe = ({ cities, location, user }) => {
  const lang = useCurrentLanguage();
  const dispatch = useDispatch();
  // eslint-disable-next-line camelcase
  const { user_id: userId, subject } = parseQueryParams(location.search);

  useEffect(() => {
    dispatch({
      type: types.CREATE_UNSUBSCRIBE,
      user: userId,
      subject,
    });
  }, []);

  return (
    <Root stickyHeader searchInHeader language={lang} destinations={cities}>
      <AlternateAuto route="unsubscribe" />
      <CanonicalAuto route="unsubscribe" />
      <div className={classNames("Wrapper")}>
        <div className="Unsubscribe__title">
          <Trans>We will not send you any more emails!</Trans>
        </div>
        <div className="Unsubscribe__subtitle">
          <Trans>
            We are very sorry that you did not like our emails. Please write the reason, what
            exactly was wrong?
          </Trans>
        </div>
        <Reasons dispatch={dispatch} userId={userId} user={user} />
        <CookieFooter />
      </div>
    </Root>
  );
};

Unsubscribe.getInitialProps = withRedirectToKnownLang(
  // eslint-disable-next-line no-unused-vars
  async ({ req, res, match, history, location, store, ...ctx }) => {
    const lang = getCurrentLanguage(match.params.lang);
    const cookies = get(req, "headers.cookie");
    store.dispatch({ type: types.FETCH_USER, cookies });

    const currency = await fetchDefaultCurrency(req);
    store.dispatch({ type: types.SET_DEFAULT_CURRENCY, defaultCurrency: currency });

    let overview = {};
    overview = await fetchOverview({ lang }, req);

    return { cities: overview.topCities };
  },
);

const mapStateToProps = ({ user }) => ({
  user,
});

export default connect(mapStateToProps)(Unsubscribe);
