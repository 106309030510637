import React, { useRef } from "react";
import classNames from "../../functions/classNames";
import ErrorMessageLine from "../ErrorMessageLine";
import "./Radio.css";
import { Icon } from "../Icon";

export function RadioForFormik({ checked, children, disabled, className, ...props }) {
  const inputRef = useRef();
  return (
    <label
      className={classNames(
        "RadioForFormik",
        disabled && "RadioForFormik--disabled",
        checked && "RadioForFormik--checked",
        className,
      )}
    >
      <div className="RadioForFormik__input-container">
        <div className="RadioForFormik__input-icon">
          {/* className support in Icon is welcome. */}
          <Icon name="check/white" width="12" height="10" />
        </div>
        <input ref={inputRef} className="Radio__input" type="radio" checked={checked} {...props} />{" "}
      </div>
      {children}
    </label>
  );
}
// disabled={available}

// Radio input
export const RadioButton = ({
  field: { name, value, onChange, onBlur },
  id,
  label,
  className,
  customValue,
  // Formik passes its own change handler inside the field object, and, at first glance, this function cannot be modified. Stripe also isolates state within its own component, so we can't access it.
  // As we have to recreate Stripes paymentIntent on paymentMethod change, we have to keep a copy of the state at the PaymentForm component level and pass a custom onChange function to fire side effects.
  customOnChange = () => {},
  ...props
}) => {
  return (
    <RadioForFormik
      className={className}
      name={name}
      value={id} // could be something else for output?
      checked={id === customValue}
      onChange={e => {
        onChange(e);
        customOnChange(e);
      }}
      onBlur={onBlur}
      {...props}
    >
      {label}
    </RadioForFormik>
  );
};

// Radio group
export const RadioButtonGroup = ({ value, error, touched, id, label, className, children }) => {
  const classes = classNames("RadioGroup", className);

  return (
    <div className={classes}>
      <fieldset>
        {/* <legend>{label}</legend> */}
        {children}
        {touched && <ErrorMessageLine className="m-10" name="date" />}
      </fieldset>
    </div>
  );
};

export default function Radio({ children, className, label, theme, ...props }) {
  return (
    <label className={classNames("Radio", theme && `Radio--${theme}`, className)}>
      <input type="radio" className="Radio__input" {...props} />
      <span className="Radio__graphics" />
      {children || label}
    </label>
  );
}
