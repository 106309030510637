import apple from "./apple";
import google from "./google";
import googleBlack from "./googleBlack";
import appleBlack from "./appleBlack";

export const pay = {
  "pay/apple": apple,
  "pay/appleBlack": appleBlack,
  "pay/google": google,
  "pay/googleBlack": googleBlack,
  "pay/card": {
    shape:
      '<path fill="#000" fill-opacity=".51" d="M21 6.63v10.75A1.58 1.58 0 0 1 19.37 19H4.63A1.58 1.58 0 0 1 3 17.37V6.63A1.57 1.57 0 0 1 4.63 5h14.75A1.58 1.58 0 0 1 21 6.63ZM4 8.8h16V6.62a.6.6 0 0 0-.19-.43.6.6 0 0 0-.43-.19H4.63a.6.6 0 0 0-.44.19.6.6 0 0 0-.19.43V8.8Zm0 2.4v6.18c0 .16.06.3.19.43a.6.6 0 0 0 .43.19h14.75a.6.6 0 0 0 .44-.19.6.6 0 0 0 .19-.43V11.2H4Z"/>',
  },
};
