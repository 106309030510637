export const currency = {
  euro: {
    shape: `<path fill="#000" fill-opacity=".51" d="M11.91 16.33c-1.53 0-2.84-.42-3.92-1.27s-1.85-1.93-2.27-3.24H2.95v-.8h2.61a7.45 7.45 0 0 1 0-2.06H2.95v-.78h2.8A6.5 6.5 0 0 1 8 4.94a6.14 6.14 0 0 1 3.91-1.27 6.46 6.46 0 0 1 2.23.38 6.85 6.85 0 0 1 1.93 1.08l-.57.54a5.93 5.93 0 0 0-3.59-1.21c-1.32 0-2.43.35-3.35 1.07s-1.57 1.6-1.97 2.65h5.23v.8H6.37a5.94 5.94 0 0 0 0 2.06h5.45v.78H6.57c.41 1.06 1.07 1.94 1.99 2.65s2.04 1.07 3.35 1.07a5.8 5.8 0 0 0 3.59-1.2l.57.53a6.83 6.83 0 0 1-4.15 1.46z"/>`,
    viewBox: [0, 0, 19, 20],
  },
  dollar: {
    shape: `<path fill="#000" fill-opacity=".51" d="M9.08 16.73v-1.6a3.38 3.38 0 0 1-2.83-2.3l.74-.32c.22.6.53 1.05.94 1.37s.94.47 1.63.47a3.17 3.17 0 0 0 1.64-.44c.5-.3.75-.78.75-1.46 0-.56-.18-1.01-.55-1.35s-1.06-.67-2.06-1c-1.01-.33-1.73-.7-2.16-1.12s-.63-.96-.63-1.63c0-.74.29-1.33.85-1.76s1.13-.68 1.68-.71v-1.6h.8v1.6a2.8 2.8 0 0 1 2.39 1.52l-.7.35a2.39 2.39 0 0 0-.78-.83 2.31 2.31 0 0 0-1.27-.31c-.6 0-1.12.16-1.54.48s-.64.74-.64 1.26c0 .48.18.88.53 1.18s1.04.62 2.07.93c.96.3 1.67.7 2.12 1.18s.69 1.09.69 1.81c0 .8-.28 1.42-.85 1.9s-1.23.72-2.02.77v1.6h-.8z"/>`,
    viewBox: [0, 0, 19, 20],
  },
  pound: {
    shape: `<path fill="#000" fill-opacity=".51" d="M5.18 16.33v-.95a8.35 8.35 0 0 0 .77-.6c.24-.22.46-.46.65-.71s.35-.55.47-.86.17-.65.17-1a4.91 4.91 0 0 0-.03-.55 2.89 2.89 0 0 0-.1-.51H5.17v-.8H6.6A7.4 7.4 0 0 1 5.58 8.9a3.8 3.8 0 0 1 .76-4.47 3.83 3.83 0 0 1 2.8-1.15c.8 0 1.5.2 2.12.6a3.82 3.82 0 0 1 1.4 1.56l-.73.31a3.06 3.06 0 0 0-2.79-1.68c-.87 0-1.61.31-2.23.93s-.93 1.37-.93 2.24a2.89 2.89 0 0 0 .44 1.56 12.09 12.09 0 0 0 1.2 1.57h3.1v.79H7.95a3.47 3.47 0 0 1 .07.51l.03.56c0 .67-.17 1.29-.5 1.86a5.05 5.05 0 0 1-1.29 1.46h4.87c.56 0 1-.12 1.3-.35s.56-.56.72-.97l.67.35c-.18.54-.52.97-1 1.29s-1.05.47-1.69.47H5.2z"/>`,
    viewBox: [0, 0, 19, 20],
  },
};
