import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { withI18n } from "@lingui/react";
import { Icon } from "../Icon";
import * as types from "../../stores/types";
import "./Alert.css";

export const Alert = withI18n()(function AlertPure({ i18n }) {
  const dispatch = useDispatch();
  const alert = useSelector(state => state.alert);
  const transition = { duration: 0.2, ease: "easeInOut" };
  const timeoutRef = React.useRef(null);
  const isMessageLazy = !!alert.content.lazyMessage;

  const hideAlert = () => {
    if (timeoutRef.current !== null) {
      window.clearTimeout(timeoutRef.current);
    }

    dispatch({ type: types.HIDE_ALERT });
  };

  useEffect(() => {
    if (alert.isVisible) {
      if (timeoutRef.current !== null) {
        window.clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = window.setTimeout(() => {
        dispatch({ type: types.HIDE_ALERT });
      }, 5000);
    }
  }, [alert]);

  return (
    <motion.div
      initial={{ y: "105%" }}
      animate={{ y: alert.isVisible ? "0" : "105%" }}
      exit={{ y: "105%" }}
      transition={transition}
      className="Alert__container"
    >
      <div className="Alert">
        <p className="Alert__message">
          {isMessageLazy ? i18n._(alert.content.lazyMessage) : alert.content.message}
        </p>
        <div className="Alert__actions">
          <div className="Alert__close" onClick={hideAlert}>
            {/* TODO: support actions */}
            <Icon name="close/white" height="25" width="25" />
          </div>
        </div>
      </div>
    </motion.div>
  );
});
