export const ticket = {
  ticket: {
    shape:
      '<path fill="#000" fill-opacity=".51" fill-rule="evenodd" d="M4 19a.96.96 0 0 1-.7-.3.96.96 0 0 1-.3-.7v-3.2a3.092 3.092 0 0 0 1.438-1.088A2.814 2.814 0 0 0 5 12c0-.633-.187-1.204-.562-1.713A3.094 3.094 0 0 0 3 9.2V6c0-.267.1-.5.3-.7.2-.2.433-.3.7-.3h16c.267 0 .5.1.7.3.2.2.3.433.3.7v3.212l-.012-.007a3.093 3.093 0 0 0-1.426 1.082A2.818 2.818 0 0 0 19 12c0 .633.187 1.204.562 1.712.375.508.853.87 1.435 1.087l.003-.002V18c0 .267-.1.5-.3.7-.2.2-.433.3-.7.3H4Zm16-3.55a4.105 4.105 0 0 1-1.463-1.462A3.852 3.852 0 0 1 18 12c0-.717.179-1.38.537-1.988A4.105 4.105 0 0 1 20 8.55V6H4v2.55a4.105 4.105 0 0 1 1.463 1.462C5.821 10.621 6 11.283 6 12c0 .717-.179 1.38-.537 1.988A4.105 4.105 0 0 1 4 15.45V18h16v-2.55Z" clip-rule="evenodd"/>',
    viewBox: [0, 0, 24, 24],
  },
  "ticket/gray": {
    shape:
      '<path d="M.5 10.4V1.6C.5 1.04.966.5 1.627.5H2.75c0 1.155.93 2.2 2.25 2.2 1.32 0 2.25-1.045 2.25-2.2h1.124C9.035.5 9.5 1.04 9.5 1.6v8.8c0 .56-.464 1.1-1.125 1.1H7.25c0-1.155-.93-2.2-2.25-2.2a2.214 2.214 0 00-2.25 2.2H1.626C.965 11.5.5 10.96.5 10.4" stroke="#979797" stroke-width="1" stroke-linejoin="round" />',
    viewBox: [0, 0, 10, 12],
  },
};
