import React from "react";
import { Trans } from "@lingui/macro";
import useCurrentLanguage from "../../../functions/languages/useCurrentLanguage";
import { SignInConditions } from "..";
import "./withOffer.css";
import { discountOption } from "../../../constants/singUpOptions";

/**
 * Handler for get current popupOption element by key
 * @returns {React::Element|Null} - element for login popup
 */
const getOption = popupOption => {
  let element;
  switch (popupOption) {
    case "discount5":
      element = <span className="Option__discount-element">{discountOption}</span>;
      return (
        <div>
          <Trans>
            Register to receive a promo code for a {element}. We will send you a promo code at the
            first login after registration.
          </Trans>
        </div>
      );
    default:
      return null;
  }
};

/**
 * HOC for adding text contents with offer to login and terms and conditions
 * @param {React::Element} SignIn - modified SignIn class
 */
export default SignIn => {
  return ({ screen, option, ...props }) => {
    const lang = useCurrentLanguage();

    return (
      <SignIn screen={screen} option={option} {...props}>
        <div className="SignIn__offer">
          {!screen && option !== "discount5" ? (
            <Trans>
              Create an account to save your favorite tours add access your orders from any device
            </Trans>
          ) : null}
          {screen === "log-in" ? (
            <Trans>Log in to save favorite tours add see your orders from any device</Trans>
          ) : null}
          {getOption(option)}
        </div>
        <div>
          <div className="SignIn__terms">
            <Trans>
              You can always proceed without signing in. If you&apos;re signing in for the first
              time, we&apos;ll create an account for you.
            </Trans>
          </div>
          <SignInConditions lang={lang} />
        </div>
      </SignIn>
    );
  };
};
