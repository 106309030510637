import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import classNames from "../../functions/classNames";
import useCurrentLanguage from "../../functions/languages/useCurrentLanguage";
import "react-phone-number-input/style.css";
import "./Input.css";

/**
 * Input
 * @param {String} className - additional CSS class name
 * @param {String} theme - component theme
 * @param {Array[React::Element]} children - first child is added before input field, second - after
 * @param {Boolean} error - show/hide error border
 */
export default ({
  type = "text",
  inputRef,
  className,
  theme,
  children = [],
  error,
  phone,
  pattern,
  disabled,
  success,
  customInput,
  WrapperTag = "div",
  onFocusChange,
  onFocus = () => {},
  onChange = () => {},
  onClick = () => {},
  ...props
}) => {
  const lang = useCurrentLanguage();
  const [focus, setFocus] = useState(false);
  const isMultiline = type === "textarea";
  const InputTag = isMultiline ? "textarea" : "input";

  useEffect(() => {
    if (onFocusChange) {
      onFocusChange(focus);
    }
  }, [focus, onFocusChange]);

  return (
    <>
      <WrapperTag
        className={classNames(
          "Input",
          disabled && "Input--disabled",
          isMultiline && "Input--multiline",
          className,
          theme && `Input--${theme}`,
          focus && "Input--focused",
          success && "Input--success",
          error && "Input--error",
          !phone && "Input--outer",
        )}
        onClick={onClick}
      >
        {children[0]}
        {phone ? (
          <PhoneInput
            {...props}
            className="Input__field"
            onFocus={() => setFocus(true)}
            onChange={onChange}
            onBlur={() => {
              setFocus(false);
            }}
            {...(lang === "ru"
              ? {
                  defaultCountry: "RU",
                  useNationalFormatForDefaultCountryValue: true,
                }
              : {})}
          />
        ) : (
          customInput || (
            <InputTag
              ref={inputRef}
              type={type}
              disabled={disabled}
              className="Input__field"
              onFocus={() => {
                setFocus(true);
                onFocus();
              }}
              onBlur={() => setFocus(false)}
              onChange={event => {
                if (!pattern || (pattern && new RegExp(pattern).test(event.target.value))) {
                  onChange(event);
                }

                return false;
              }}
              {...props}
            />
          )
        )}
        {children[1] || (!Array.isArray(children) && children)}
      </WrapperTag>
      {props.after}
    </>
  );
};
