import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { DOMAIN_URL } from "../../constants";
import reverseUrl from "../../functions/reverseUrl";

/**
 * Adds `<link rel="canonical" />` with given URL relative to current domain
 * @param {Object} $
 * @param {String} $.url - URL with trailing slash at start to add into `href` tag
 * @returns
 */
export default function Canonical({ url }) {
  return (
    <Helmet>
      <link rel="canonical" href={`${DOMAIN_URL}${url}`} />
    </Helmet>
  );
}

/**
 * <Canonical /> with link forms from `reverseUrl` with autodetected params
 * @param {Object} $
 * @param {String} $.route - name of the current route from `/src/routes.js`
 * @returns
 */
export function CanonicalAuto({ route }) {
  const params = useParams();
  return <Canonical url={reverseUrl(route, params)} />;
}
