import { createStore, compose, applyMiddleware } from "redux";
// import { createLogger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import { runtimeConfig } from "../config";
import { rootSaga, makeInit, makeReducers } from "./index";

export function safeSaga(saga) {
  return function* (args) {
    try {
      yield saga(args);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error creating safeSaga", err);
    }
  };
}

const bindMiddleware = middleware => {
  if (process.env.BUILD_TARGET === "client" && runtimeConfig.ENV !== "production") {
    const { composeWithDevTools } = require("redux-devtools-extension");
    return composeWithDevTools(applyMiddleware(...middleware));
  }
  if (process.env.BUILD_TARGET === "client" && runtimeConfig.ENV === "development") {
    // middleware.push(createLogger());
  }
  return compose(applyMiddleware(...middleware));
};

export default function configureStore(initialState = makeInit()) {
  const sagaMiddleware = createSagaMiddleware();
  const store = createStore(makeReducers(), initialState, bindMiddleware([sagaMiddleware]));

  store.sagaTask = sagaMiddleware.run(rootSaga);

  if (process.env.BUILD_TARGET !== "client") {
    if (!store.sagaTask.done && store.sagaTask.toPromise) {
      store.sagaTask.done = store.sagaTask.toPromise();
    }
  }

  return store;
}
