import { languages } from "../urlLanguage";

/**
 * Gets codes of languages available on current domain
 * @param {String} domainRegion - region (e.g. `"ru"` or `"com"`) to find allowed languages
 * @returns {Array[String]} - language codes
 */
export default function getAvailableLangsOnDomain(domainRegion) {
  const allLangCodes = languages.map(l => l.code);

  if (domainRegion === "com") {
    return allLangCodes.filter(c => c !== "ru");
  }
  if (domainRegion === "ru") {
    return ["ru"];
  }

  return allLangCodes;
}
