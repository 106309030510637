import { DEFAULT_LANG } from "../../constants";
import findAfterRoute from "../after/findAfterRoute";
import redirectToSamePage from "../after/redirectToSamePage";
import getCurrentLanguage from "./getCurrentLanguage";

/**
 * Modifies `AfterJS` components `getInitialProps()` method to redirect if requested language URL not matches known ones
 * @param {Function} callback - `getInitialProps()` method to modify
 * @returns {Function}
 */
export default function withRedirectToKnownLang(callback) {
  return async function modifiedGetInitialProps(params, ...args) {
    const requestedLang = params.match.params.lang;
    if (requestedLang && getCurrentLanguage(requestedLang) !== requestedLang) {
      return redirectToSamePage(
        findAfterRoute(params.req).name,
        { req: params.req, match: params.match },
        { lang: DEFAULT_LANG },
      );
    }

    return callback(params, ...args);
  };
}
