export default {
  "step/delayed": {
    shape:
      '<path d="M32 61.5002C48.5685 61.5002 62 48.0688 62 31.5002C62 14.9317 48.5685 1.50024 32 1.50024C15.4315 1.50024 2 14.9317 2 31.5002C2 48.0688 15.4315 61.5002 32 61.5002Z" stroke="#FFFFFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M32 13.5002V31.5002L44 37.5002" stroke="#22CCD8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>',
    viewBox: [0, 0, 64, 64],
  },
  "step/delayed-bold": {
    shape:
      '<g stroke-width="3.976" stroke-linecap="round" stroke-linejoin="round"><path d="M22.5 42.97c11.305 0 20.47-9.165 20.47-20.47S33.805 2.03 22.5 2.03 2.03 11.195 2.03 22.5s9.165 20.47 20.47 20.47z" stroke="#fff"/><path d="M22.5 10.218V22.5l8.188 4.094" stroke="#22ccd8"/></g>',
    viewBox: [0, 0, 45, 45],
  },
};
