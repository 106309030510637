import * as types from "../types";

export const init = {
  days: [],
  timeslots: [],
  calendarIsDisabled: false,
  options: [],
  loading: false,
  payment: {},
  discount: {},
  paymentStatus: {},
  paymentFormIsDisabled: false,
  paymentStatusTimedOut: false,
  paymentsIsSubmitting: false,
  fromOnDemandForm: false,
  selectedDate: null,
  selectedCategories: {},
  selectedTimeslot: {},
  originalPassengerCount: {},
  customerData: {},
  selectedPlan: null,
};

export default function availability(state = {}, action) {
  switch (action.type) {
    case types.FETCH_DAYS:
      return {
        ...state,
        loading: "days",
      };
    case types.FILL_PAYMENT:
      if (action.statusOnly) {
        return {
          ...state,
          paymentStatus: {
            ...state.paymentStatus,
            ...action.payment,
          },
        };
      }

      return {
        ...state,
        options: action.options,
        payment: {
          ...state.payment,
          id: action.paymentId,
          clientSecret: action.clientSecret,
          confirmationCode: action.confirmationCode,
          price: action.payment.price,
          // confirmation token for YooKassa widget
          confirmationToken: action.payment.confirmation_token,
        },
        paymentStatus: {
          ...state.paymentStatus,
          ...action.payment,
        },
        discount: action.discount.discount
          ? {
              ...state.discount,
              codes: [
                {
                  discount: action.discount.discount,
                  promotion: action.discount.promotion,
                },
              ],
            }
          : state.discount,
      };
    case types.FETCH_TIMESLOTS:
      return {
        ...state,
        loading: "timeslots",
      };
    case types.FETCH_OPTIONS:
      return {
        ...state,
        loading: "options",
      };
    case types.CREATE_PAYMENT:
      return {
        ...state,
        loading: "payment",
      };
    case types.UPDATE_PAYMENT:
      return {
        ...state,
        payment: {
          ...state.payment,
          price: action.payload.price,
        },
      };
    case types.FETCH_DAYS_FAILURE:
    case types.FETCH_TIMESLOTS_FAILURE:
    case types.FETCH_OPTIONS_FAILURE:
    case types.CREATE_PAYMENT_FAILURE:
    case types.SELECT_PAYMENT_METHOD_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_DAYS_SUCCESS:
      return {
        ...state,
        days: [...(action.isAppend ? state.days : []), ...action.data],
        loading: false,
      };
    case types.FETCH_TIMESLOTS_SUCCESS:
      return {
        ...state,
        timeslots: action.data,
        isTimeslotsAvailable: !!action.data.length,
        loading: false,
      };

    case types.FETCH_OPTIONS_SUCCESS:
      return {
        ...state,
        options: [...action.data],
        loading: false,
      };
    case types.DISABLE_PAYMENT_FORM:
      return {
        ...state,
        paymentFormIsDisabled: true,
      };
    case types.ENABLE_PAYMENT_FORM:
      return {
        ...state,
        paymentFormIsDisabled: false,
      };
    case types.SET_CLIENT_SECRET:
      return {
        ...state,
        payment: {
          ...state.payment,
          clientSecret: action.data,
        },
      };
    case types.CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        payment: action.data,
        paymentMethod: action.paymentMethod,
      };
    case types.SELECT_PAYMENT_METHOD_SUCCESS:
      return {
        ...state,
        loading: false,
        paymentMethod: action.method,
      };
    case types.SELECT_PAYMENT_METHOD:
      return {
        ...state,
        loading: "method",
      };
    case types.SUBMIT_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        paymentStatus: { ...state.paymentStatus, statusTried: 0 },
      };
    case types.GET_PAYMENT_STATUS:
      return {
        ...state,
        paymentStatus: {
          ...state.paymentStatus,
          statusTried: (state.paymentStatus.statusTried || 0) + 1,
          loading: true,
        },
      };
    case types.PAYMENT_STATUS_TIMEOUT:
      return {
        ...state,
        paymentStatusTimedOut: true,
        loading: false,
      };
    case types.GET_PAYMENT_STATUS_SUCCESS:
      return {
        ...state,
        paymentStatus: {
          ...state.paymentStatus,
          ...action.data,
          loading: false,
        },
      };
    case types.CHECK_DISCOUNT:
      return {
        ...state,
        discount: {
          ...state.discount,
          loading: true,
        },
      };
    case types.CHECK_DISCOUNT_INIT:
      return {
        ...state,
        discount: {
          ...state.discount,
          requiredCategories: action.categories,
        },
      };
    case types.CHECK_DISCOUNT_SUCCESS:
      return {
        ...state,
        discount: {
          ...state.discount,
          codes: [action.data],
          loading: false,
          requiredCategories: null,
        },
      };
    case types.CHECK_DISCOUNT_FAILURE:
      return {
        ...state,
        discount: {
          ...state.discount,
          loading: false,
          requiredCategories: null,
        },
      };
    case types.RESET_DISCOUNT:
      return {
        ...state,
        discount: {
          ...state.discount,
          ...(action.code ? { codes: [] } : {}),
        },
      };
    case types.DISABLE_PAYMENT_SUBMITTING:
      return {
        ...state,
        paymentsIsSubmitting: false,
      };
    case types.ENABLE_PAYMENT_SUBMITTING:
      return {
        ...state,
        paymentsIsSubmitting: true,
      };
    case types.SAVE_DATE_SELECT:
      return {
        ...state,
        selectedDate: action.date,
      };
    case types.SAVE_CATEGORIES_SELECT:
      return {
        ...state,
        selectedCategories: { ...state.selectedCategories, ...action.select },
      };
    case types.SAVE_TIME_SELECT:
      return {
        ...state,
        selectedTimeslot: action.slot,
      };
    case types.SET_BOOKING_TOTAL:
      return {
        ...state,
        bookingTotal: action.total,
      };
    case types.SET_FROM_ONDEMAND_FORM:
      return {
        ...state,
        fromOnDemandForm: true,
      };
    case types.SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case types.SET_CALCULATED_PRICE:
      return {
        ...state,
        calculatedPrice: action.calculatedPrice,
      };
    case types.SET_ORIGINAL_PASSENGER_COUNT:
      return {
        ...state,
        originalPassengerCount: action.payload,
      };

    case types.SAVE_CUSTOMER_DATA:
      return {
        ...state,
        customerData: action.payload,
      };

    case types.RESET_AVAILABILITY:
      return { ...init };

    default:
      return state;
  }
}
