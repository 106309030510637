import React from "react";
import isSSR from "../../constants/env/ssr";

export { isSSR };

export default class NoSSR extends React.Component {
  render() {
    const { children } = this.props;
    return isSSR ? null : children;
  }
}
