import { icons } from "../Icon.constants";
import { getVariation } from "./getVariation";
import { getShape } from "./getShape";
import { createIcon } from "./createIcon";

/**
 * All icon descriptions
 * @type {Array[Object]}
 */
const iconList = icons.reduce(
  (obj, name) => ({
    ...obj,
    [name]: () => {
      /**
       * Icons have different variations.
       * Chevron icon have up, down or right variations.
       * But shape for this icons is the same. Chevron Down is a Chevron Up but rotated at 180 degrees.
       * For icons which have the same shape, base shape stores at the Icons/shapes/
       * and variations of this shape stores at the Icons/variations.
       */
      const variation = getVariation(name);
      const shape = getShape(variation.shapeName);
      const icon = createIcon({
        name,
        ...shape,
        change: variation.change,
      });

      return icon;
    },
  }),
  {},
);

/**
 * Gets icon description and forms Icon component from it
 * @param {String} name - icon id
 */
export const getIcon = name => {
  const icon = iconList[name] || iconList.notFound;
  return icon();
};
