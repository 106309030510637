import intersection from "lodash/intersection";
import { t } from "@lingui/macro";
import { DOMAIN_REGION } from "../constants";
import { TRANSLATED_LANGS } from "../constants/i18n/existingTranslations";
import getAvailableLangsOnDomain from "./languages/getAvailableLangsOnDomain";

/**
 * Descriptions of available language
 * @type {Array[Object]}
 */
export const languages = [
  { code: "en", name: "English", t: t`English` },
  { code: "ru", name: "Russian", t: t`Russian` },
  { code: "de", name: "German", t: t`German` },
  // { code: "it", name: "Italiano" },
  // { code: "fr", name: "Français" },
  // { code: "nl", name: "Nederlands" },
  // { code: "es", name: "Español" },
  // { code: "pl", name: "Polski" },
];

export const tourLanguages = [
  { code: "en", name: "English", t: t`English` },
  { code: "ru", name: "Russian", t: t`Russian` },
  { code: "de", name: "German", t: t`German` },
  { code: "it", name: "Italian", t: t`Italian` },
  { code: "fr", name: "French", t: t`French` },
  { code: "nl", name: "Dutch", t: t`Dutch` },
  { code: "es", name: "Spanish", t: t`Spanish` },
  { code: "pl", name: "Polish", t: t`Polish` },
  { code: "pt-PT", name: "Portuguese", t: t`Portuguese` },
  { code: "ar", name: "Arabic", t: t`Arabic` },
  { code: "bg", name: "Bulgarian", t: t`Bulgarian` },
  { code: "zh", name: "Chinese", t: t`Chinese` },
  { code: "cs", name: "Czech", t: t`Czech` },
  { code: "da", name: "Danish", t: t`Danish` },
  { code: "et", name: "Estonian", t: t`Estonian` },
  { code: "fi", name: "Finnish", t: t`Finnish` },
  { code: "el", name: "Greek", t: t`Greek` },
  { code: "hu", name: "Hungarian", t: t`Hungarian` },
  { code: "hi", name: "Hindi", t: t`Hindi` },
  { code: "id", name: "Indonesian", t: t`Indonesian` },
  { code: "ja", name: "Japanese", t: t`Japanese` },
  { code: "ko", name: "Korean", t: t`Korean` },
  { code: "lv", name: "Latvian", t: t`Latvian` },
  { code: "lt", name: "Lithuanian", t: t`Lithuanian` },
  { code: "no", name: "Norwegian", t: t`Norwegian` },
  { code: "ro", name: "Romanian", t: t`Romanian` },
  { code: "sk", name: "Slovak", t: t`Slovak` },
  { code: "sl", name: "Slovenian", t: t`Slovenian` },
  { code: "sv", name: "Swedish", t: t`Swedish` },
  { code: "tr", name: "Turkish", t: t`Turkish` },
  { code: "uk", name: "Ukrainian", t: t`Ukrainian` },
];

/**
 * Languages (from `languages`), available for selecting by user
 * Formed from:
 * - regional restrictions
 * - existing translations
 * @type {Array[Object]}
 */
export const availableLangs = (function () {
  const domainCodes = getAvailableLangsOnDomain(DOMAIN_REGION);
  return intersection(domainCodes, TRANSLATED_LANGS).map(code =>
    languages.find(lang => lang.code === code),
  );
})();

/**
 * Languages (from `languages`), available for tours on domain
 * @type {Array[String]}
 */
export const availableTourLangs = tourLanguages;

/**
 * Codes of available languages for tours on domain
 * @type {Array[String]}
 */
export const tourLangCodes = availableTourLangs.map(({ code }) => code);

// Preferred browser language
export const preferredLanguage =
  typeof navigator !== "undefined" ? navigator.language || navigator.userLanguage : null;

/**
 * Codes of available languages
 * @type {Array[String]}
 */
const codes = languages.map(({ code }) => code);

/**
 * Gets current language code from link
 * @param {String} url - current link
 * @returns {String} - current used language code
 */
export function currentLanguage(url) {
  const parsed = url.split("/");

  if (codes.indexOf(parsed[1]) === -1) {
    return "en";
  }

  return parsed[1];
}

/**
 * Removes language param from URL
 * @param {String} url - current link
 * @returns {String} - URL from params without lang
 */
export default function cutLanguage(url) {
  const parsed = url.split("/");

  // if (codes.indexOf(parsed[1]) === -1) {
  if (parsed[1].length !== 2) {
    // Pass as is if no :lang{2} in the route
    return url;
  }

  return `/${parsed.slice(2).join("/")}`;
}
