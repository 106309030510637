import React, { Component } from "react";
import classNames from "../../../functions/classNames";
import "./withChain.css";

/**
 * Chains several inputs to one long
 * @param {React::Component} Input - modified component
 */
export default Input =>
  /**
   * Chained input
   * @param {Object} $
   * @param {String} value - current value in inputs
   * @param {String} chainClassName - CSS class added to the top element (`.Input__chain`)
   * @param {Number} symbols - max amount of symbols in one box
   * @param {Number} chainLength - amount of inputs in the chain
   * @param {Array[React::Component]} $.children - slides in the component
   * @param {Boolean} error - show/hide error frame
   * @param {Boolean} eraseOnPaste - erase input before pasting from Ctrl+V
   * @param {Function} onChanged - calls when value was changed
   */
  class withChain extends Component {
    /**
     * Inner state of the component
     * @prop {String} currentValue - composed values stored in inputs
     * @prop {Number} active - current active input
     */
    state = {
      active: null,
      currentValue: "",
    };

    /**
     * Refs to chained inputs
     * @type {Array[React::Component]}
     */
    segmentRefs = [];

    /**
     * Key props base on unix-time for each Input in chain
     * Must be regenerated when fields amount is changing
     * @type {Array[String]}
     */
    segmentUids = [];

    /**
     * Amount of inputs in the chained input
     * @type {Number}
     */
    chainLength = 4;

    /**
     * Setting amount of the child inputs
     * @param {Object} props
     */
    constructor(props) {
      super(props);

      this.chainLength = 4 || props.chainLength;

      for (let i = 0; i < this.chainLength; i += 1) {
        this.segmentRefs.push(React.createRef());
        this.segmentUids.push(`${Date.now()}-${i}`);
      }
    }

    /**
     * Updating `currentValue` in state
     * Focusing last filled input if current became empty
     * @param {Object} $
     * @param {String} value - previous value
     */
    componentDidUpdate({ value: prevValue }) {
      const { value, symbols } = this.props;
      const { currentValue, active } = this.state;

      if (typeof value === "string" && value !== prevValue && value !== currentValue) {
        const values = value.match(new RegExp(`.{1,${symbols}}`, "g")) || [];
        this.setState({ currentValue: value });
        this.handleFocus(values[active], value);
      }
    }

    /**
     * Makes attempt to set position of the cursor
     * Focuses input
     * @param {Element} input - targeted input from chain
     * @param {Number} position - where to place caret
     */
    setCaret(input, position) {
      if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(position, position);
      }
    }

    /**
     * Paste event was fired
     * Try to access pasted text, if succeeded and `eraseOnPaste` enabled -
     *   erases previous value and fills input with pasted data
     * @param {Number} index - number of input where paste occured
     * @param {Event} event
     */
    handlePaste(index, event) {
      const { eraseOnPaste, symbols, onChanged = () => {} } = this.props;
      const clipboardData = event.clipboardData || window.clipboardData;

      try {
        if (eraseOnPaste && clipboardData) {
          const pastedData = clipboardData.getData("Text");

          if (pastedData.length === this.chainLength * symbols) {
            event.stopPropagation();
            event.preventDefault();

            this.setState({ currentValue: pastedData });
            this.segmentRefs[this.chainLength - 1].current.focus();
            onChanged(pastedData);

            return false;
          }
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error("Failed to erase chained input before paste:", error);
      }

      return true;
    }

    /**
     * Changes common value for all inputs
     * Changes focused input
     * @param {Number} index - input with changed value index
     * @param {String} value - new value in input
     */
    handleChange(index, value) {
      const { symbols, onChanged = () => {} } = this.props;
      const { currentValue } = this.state;
      const newValue = (
        currentValue.slice(0, index * symbols) +
        value +
        currentValue.slice((index + 1) * symbols)
      ).slice(0, this.chainLength * symbols);

      const lastFilledInput = index + Math.floor(value.length / symbols);
      this.setState({ currentValue: newValue });
      this.segmentRefs[lastFilledInput % this.chainLength].current.focus();
      onChanged(newValue);
    }

    /**
     * Handles backspace and nav buttons pressed
     * Also changes common value
     * Moves focus to previous form if cursor at zero position
     * @param {KeyUpEvent} event - from onKeyUp on input field
     * @param {Number} index - current used input field
     */
    handleNav({ key, target }, index) {
      const { symbols } = this.props;
      const prevInput = this.segmentRefs[index - 1];

      switch (key) {
        case "Backspace":
          if (target.selectionStart === 0 && index > 0) {
            this.handleChange(index - 1, prevInput.current.value);
            this.setCaret(prevInput.current, 1);
          }
          break;

        case "ArrowLeft":
          if (target.selectionStart === 0 && index > 0) {
            this.setCaret(prevInput.current, 1);
          }
          break;

        case "ArrowRight":
          if (target.selectionStart === symbols && index < this.chainLength - 1) {
            const nextInput = this.segmentRefs[index + 1].current;
            this.setCaret(nextInput, nextInput.value.length);
          }
          break;

        default:
          break;
      }
    }

    /**
     * Chooses the last filled input
     * Focuses it if current input is not filled
     * @param {String} value - user activated input contents
     * @param {String} fullValue - outer passed value, if null - `state.currentValue` will be used
     */
    handleFocus(value, fullValue) {
      if (!value) {
        const { symbols } = this.props;
        const currentValue = typeof fullValue === "string" ? fullValue : this.state.currentValue;
        const lastFilledInput = Math.floor(currentValue.length / symbols);
        this.segmentRefs[lastFilledInput].current.focus();
      }
    }

    /**
     * Focuses input
     */
    focus() {
      this.handleFocus("");
    }

    render() {
      const { symbols, chainClassName, onChanged, ...props } = this.props;
      const { currentValue } = this.state;
      const values = currentValue.match(new RegExp(`.{1,${symbols}}`, "g")) || [];

      return (
        <div className={classNames("Input__chain", chainClassName)}>
          {this.segmentRefs.map((ref, i) => (
            <Input
              key={this.segmentUids[i]}
              {...props}
              inputRef={ref}
              value={values[i] || ""}
              onMouseDown={({ target }) => this.handleFocus(target.value)}
              onChange={({ target }) => this.handleChange(i, target.value)}
              onPaste={event => this.handlePaste(i, event)}
              onFocus={() => this.setState({ active: i })}
              onKeyDown={event => this.handleNav(event, i)}
            />
          ))}
        </div>
      );
    }
  };
