export const arrow = {
  "arrow/left": {
    viewBox: [0, 0, 24, 24],
    shape:
      '<path fill="#3D3D3D" d="M12 19.225 4.775 12 12 4.775l.725.7L6.7 11.5h12.525v1H6.7l6.025 6.025-.725.7Z"/>',
  },
  "arrow/right": {
    viewBox: [0, 0, 24, 24],
    shape:
      '<path fill="#000" fill-opacity=".98" d="m14.1 17.3-.7-.7 4.075-4.1H4.6v-1h12.875L13.4 7.4l.7-.7 5.3 5.3-5.3 5.3Z"/>',
  },
};
