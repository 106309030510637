import * as types from "../types";

/**
 * Default alert content object
 * @param {String} message - the message the alert will show
 * @param {Array.<label: String, callback: Function>} actions - actions the alert has (close by default)
 */
export const DEFAULT_ALERT_CONTENT = {
  message: "",
  lazyMessage: "",
  actions: [],
};

/**
 * @param init - initial alert object
 * @param init.isVisible - manages alert visibility.
 * @param init.content - manages alert content.
 */
export const init = {
  isVisible: false,
  content: DEFAULT_ALERT_CONTENT,
};

export default function alert(state = {}, action) {
  switch (action.type) {
    case types.SET_ALERT_CONTENT:
      return {
        ...state,
        content: action.data,
      };
    case types.SET_ALERT_VISIBILITY:
      return {
        ...state,
        isVisible: action.data,
      };
    default:
      return state;
  }
}
