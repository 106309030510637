import React from "react";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { t } from "@lingui/macro";
import cutLanguage from "../../../functions/urlLanguage";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";
import { Icon } from "../../Icon";
import Button from "../../Button";
/**
 * Menu showing after click on select language button
 */
export default function LanguageMenu({ isMobile, i18n, locale, availableLangs }) {
  const { pathname } = useLocation();
  const link = cutLanguage(pathname);

  return (
    <PopupOrDropdownAdapter
      isMobile={isMobile}
      title={isMobile && i18n._(t`Select language`)}
      triggerElement={<Icon name={`lang/${locale || "en"}`} width="26" height="26" />}
      bodyClassName="HeaderMenu"
      noButtonWrap={false}
      disableOutside={false}
      align="right"
      ContentComponent={() =>
        availableLangs.map(({ code, name }) => {
          return (
            <Button
              key={code}
              external
              className="HeaderMenu-item HeaderMenu-item--bold"
              iconPosition="before"
              size="S"
              icon={<Icon name={`lang/${code}`} width="26" height="26" />}
              to={`/${code}${link}`}
            >
              {name}
            </Button>
          );
        })
      }
    />
  );
}
