import * as types from "../types";

export const init = {
  loading: false,
  error: null,
};

export default function overview(state = {}, action) {
  switch (action.type) {
    case types.SET_OVERVIEW:
      return {
        ...state,
        ...action.data,
      };

    default:
      return state;
  }
}
