import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import { Icon } from "../../Icon";
import Button from "../../Button";
import SignInPassword from "../SignInPassword";

/**
 * HOC for adding ability to sign in with email
 * @param {React::Element} SignIn - modified SignIn class
 */
export default SignIn => {
  /**
   * SignIn with ability to specify email
   * @param {Object} $
   * @param {Array[React::Element]} $.children - component's children
   * @param {SignInScreen} screen - id of the curent screen
   * @param {Function} screenChanged - user choosed sign in option
   */
  return ({ screen, children = [], screenChanged = () => {}, ...props }) => {
    const [passwordScreen, setPasswordScreen] = useState(null);

    /**
     * Changing current screen
     */
    useEffect(() => {
      if (screen !== passwordScreen && [null, "log-in", "password"].indexOf(screen) !== -1) {
        setPasswordScreen(screen);
      }
    }, [screen, passwordScreen]);

    return passwordScreen === "password" ? (
      <SignInPassword screen={screen} />
    ) : (
      <SignIn {...props} screen={screen} screenChanged={screenChanged}>
        {children[0]}
        <div>
          {screen === "log-in" ? (
            <Button
              className="SignIn__option"
              theme="card"
              iconPosition="before"
              icon={<Icon name="key" width="18" height="18" />}
              onClick={() => {
                setPasswordScreen(true);
                screenChanged("password");
              }}
            >
              <Trans>Log in with password</Trans>
            </Button>
          ) : null}
          {children[1] || children}
        </div>
      </SignIn>
    );
  };
};
