import reverseUrl from "./reverseUrl";

export const getProductLink = (lang, { id, slug, city }) => {
  return id && slug && city
    ? reverseUrl("product", {
        lang,
        citySlug: city.slug,
        cityId: city.id,
        productSlug: slug,
        productId: id,
      })
    : "";
};
