/**
 * Icon imports
 */
import pngDe from "./assets/de.png";
import pngEn from "./assets/en.png";
import pngEs from "./assets/es.png";
import pngFr from "./assets/fr.png";
import pngIt from "./assets/it.png";
import pngNl from "./assets/nl.png";
import pngPl from "./assets/pl.png";
import pngRu from "./assets/ru.png";

export const lang = {
  "lang/de": {
    bitmap: pngDe,
  },
  "lang/en": {
    bitmap: pngEn,
  },
  "lang/es": {
    bitmap: pngEs,
  },
  "lang/fr": {
    bitmap: pngFr,
  },
  "lang/it": {
    bitmap: pngIt,
  },
  "lang/nl": {
    bitmap: pngNl,
  },
  "lang/pl": {
    bitmap: pngPl,
  },
  "lang/ru": {
    bitmap: pngRu,
  },
};
