export const bookmark = {
  bookmark: {
    shape:
      '<path fill="#000" fill-opacity=".51" d="M6 19.5V5.625c0-.467.154-.854.463-1.162C6.771 4.154 7.158 4 7.625 4h8.75c.467 0 .854.154 1.163.463.308.308.462.695.462 1.162V19.5l-6-2.575L6 19.5Zm1-1.55 5-2.15 5 2.15V5.625a.597.597 0 0 0-.188-.437.597.597 0 0 0-.437-.188h-8.75a.597.597 0 0 0-.437.188.597.597 0 0 0-.188.437V17.95Z"/>',
    viewBox: [0, 0, 24, 24],
  },
  "bookmark/multiple": {
    shape:
      '<path fill="#000" fill-opacity=".51" d="M4.5 22.27V8.4a1.57 1.57 0 0 1 1.63-1.63h8.75A1.58 1.58 0 0 1 16.5 8.4v13.87l-6-2.57-6 2.57Zm1-1.55 5-2.15 5 2.15V8.4a.6.6 0 0 0-.19-.44.6.6 0 0 0-.44-.19H6.13a.6.6 0 0 0-.44.2.6.6 0 0 0-.19.43v12.32Zm13-1.45V5.4a.6.6 0 0 0-.19-.44.6.6 0 0 0-.43-.19H7.5v-1h10.38A1.58 1.58 0 0 1 19.5 5.4v13.87h-1Z"/>',
    viewBox: [0, 0, 24, 24],
  },
};
