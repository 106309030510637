import { combineReducers } from "redux";
import { fork, all } from "redux-saga/effects";
import products, { init as productsInit } from "./products/reducers";
import productsSagas from "./products/sagas";
import generateTours, { init as generateToursInit } from "./generateTours/reducers";
import generateToursSagas from "./generateTours/sagas";
import reviews, { init as reviewsInit } from "./reviews/reducers";
import reviewsSagas from "./reviews/sagas";
import user, { init as userInit } from "./user/reducers";
import userSagas from "./user/sagas";
import languages, { init as languagesInit } from "./languages/reducers";
import languagesSagas from "./languages/sagas";
import availability, { init as availabilityInit } from "./availability/reducers";
import availabilitySagas from "./availability/sagas";
import attractions, { init as attractionsInit } from "./attractions/reducers";
import attractionsSagas from "./attractions/sagas";
import cities, { init as citiesInit } from "./cities/reducers";
import citiesSagas from "./cities/sagas";
import errors, { init as errorsInit } from "./errors/reducers";
import filters, { init as filtersInit } from "./filter/reducers";
import alert, { init as alertInit } from "./alert/reducers";
import alertSagas from "./alert/sagas";
import currencies, { init as currenciesInit } from "./currencies/reducers";
import currenciesSagas from "./currencies/sagas";
import overview, { init as overviewInit } from "./overview/reducers";
import feedback, { init as feedbackInit } from "./feedback/reducers";
import feedbackSagas from "./feedback/sagas";

// TODO: reduce boilerplate

export const makeReducers = () =>
  combineReducers({
    products,
    reviews,
    user,
    errors,
    filters,
    languages,
    availability,
    alert,
    currencies,
    overview,
    attractions,
    cities,
    feedback,
    generateTours,
  });

export const rootSaga = function* rootSaga() {
  yield all([
    fork(userSagas),
    fork(productsSagas),
    fork(reviewsSagas),
    fork(languagesSagas),
    fork(availabilitySagas),
    fork(alertSagas),
    fork(currenciesSagas),
    fork(attractionsSagas),
    fork(citiesSagas),
    fork(feedbackSagas),
    fork(generateToursSagas),
  ]);
};

export const makeInit = () => ({
  products: productsInit,
  reviews: reviewsInit,
  user: userInit,
  languages: languagesInit,
  availability: availabilityInit,
  errors: errorsInit,
  filters: filtersInit,
  alert: alertInit,
  currencies: currenciesInit,
  overview: overviewInit,
  attractions: attractionsInit,
  cities: citiesInit,
  feedback: feedbackInit,
  generateTours: generateToursInit,
});
