import { takeEvery, put, call } from "redux-saga/effects";
import * as types from "../types";
import { Api } from "../../functions/fetchFromApi";

// eslint-disable-next-line no-unused-vars
function* fetchLanguages(action) {
  try {
    const { data } = yield call(Api.get, `/api/languages/`);
    yield put({ type: types.FETCH_LANGUAGES_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.FETCH_LANGUAGES_FAILURE, error });
  }
}

export default function* watch() {
  yield takeEvery(types.FETCH_LANGUAGES, fetchLanguages);
}
