// Adapter to pass events to Yandex.Metrica
// docs:
// (RU) https://yandex.ru/support/metrica/ecommerce/data.html#examples__purchase,
// (EN) https://translate.google.com/translate?sl=auto&tl=en&u=https://yandex.ru/support/metrica/ecommerce/data.html%23examples__purchase

import DataLayer from "./DataLayer";

/**
 * Create dataLayerObj for desired action
 *
 * @param {String} action ("detail", "add", "remove")
 * @param {Track} track
 */
function createEcommerceObj(action, obj) {
  return {
    ecommerce: {
      currencyCode: obj.currency,
      [action]: {
        products: [
          {
            id: obj.product_id,
            name: obj.name || "",
            price: obj.price,
            brand: obj.brand,
            category: obj.category || "",
            variant: obj.variant,
          },
        ],
      },
    },
  };
}

export default class Yandex {
  /**
   * Product viewed.
   * (RU) https://yandex.ru/support/metrica/ecommerce/data.html#examples__product-detail
   * (EN) https://translate.google.com/translate?sl=auto&tl=en&u=https://yandex.ru/support/metrica/ecommerce/data.html%23examples__product-detail
   *
   * @api private
   * @param {Object} obj object with event info
   */

  static productViewed(obj) {
    DataLayer.push(createEcommerceObj("detail", obj));
  }

  /**
   * Product added to the card.
   * (RU) https://yandex.ru/support/metrica/ecommerce/data.html#examples__add
   * (EN) https://translate.google.com/translate?sl=auto&tl=en&u=https://yandex.ru/support/metrica/ecommerce/data.html%23examples__add
   *
   * @api private
   * @param {Object} obj object with event info
   */

  static productAdded(obj) {
    DataLayer.push(createEcommerceObj("add", obj));
  }

  /**
   * Product removed from the card.
   * (RU) https://yandex.ru/support/metrica/ecommerce/data.html#examples__remove
   * (EN) https://translate.google.com/translate?sl=auto&tl=en&u=https://yandex.ru/support/metrica/ecommerce/data.html%23examples__remove
   *
   * @api private
   * @param {Object} obj object with event info
   */
  static productRemoved(obj) {
    DataLayer.push(createEcommerceObj("remove", obj));
  }

  /**
   * Order completed.
   *
   * (RU) https://yandex.ru/support/metrica/ecommerce/data.html#examples__purchase
   * (EN) https://translate.google.com/translate?sl=auto&tl=en&u=https://yandex.ru/support/metrica/ecommerce/data.html%23examples__purchase
   *
   * @api private
   * @param {Object} obj object with event info
   */
  static orderCompleted(obj) {
    DataLayer.push({
      ecommerce: {
        currencyCode: obj.currency,
        purchase: {
          actionField: {
            id: obj.order_id,
            revenue: obj.total,
            coupon: obj.coupon,
          },
          products: obj.products.map(product => ({
            category: product.category,
            quantity: product.quantity,
            price: product.price,
            name: product.name,
            sku: product.sku,
            id: product.product_id,
            currency: product.currency,
            brand: product.brand,
            variant: product.variant,
          })),
        },
      },
    });
  }

  static widgetClickOnProduct() {
    DataLayer.push({ event: "click-product-card" });
  }

  static widgetClickShowMore() {
    DataLayer.push({ event: "click-widget-show-more" });
  }

  static scrolledToWidget() {
    DataLayer.push({ event: "scrolledToWidget" });
  }

  static discount5TopbarClicked() {
    DataLayer.push({ event: "Discount5: Topbar clicked" });
  }

  static discount5TopbarClosed() {
    DataLayer.push({ event: "Discount5: Topbar closed" });
  }

  static discount5TopbarStarted() {
    DataLayer.push({ event: "Discount5: Topbar started" });
  }

  static discount5TopbarCompleted() {
    DataLayer.push({ event: "Discount5: Topbar completed" });
  }

  static openRefundPopup() {
    DataLayer.push({ event: "Ref info" });
  }
}
