/* eslint-disable react/no-danger */
import * as React from "react";
import styled, { css } from "styled-components";
import Image from "../../Image";
import { getReplacement } from "./getReplacement";

const SVGStyled = styled.svg`
  max-width: inherit;
  max-height: inherit;
  color: inherit;
  cursor: inherit;
  fill: none;
  willchange: auto;

  ${({ internalWidth }) => {
    return css`
      text {
        font-size: ${internalWidth}px;
      }
    `;
  }}
`;

/**
 * Forms icon SVG tree
 * @param {Object} $
 * @param {String} $.name - icon id
 * @param {String} $.shape - icon svg contents
 * @param {String} $.bitmap - icon bitmap contents (if provided, will be used insted of shape)
 * @param {Array}  $.viewBox - viewBox attribute value inserting into svg tag
 * @param {IconTransform} $.change - changes, applied to basic icon (see `IconVariation` docs)
 */
export const createIcon = ({ shape, name, change, bitmap, viewBox = [] }) => {
  const CreatedIcon = ({ onClick, internalWidth, internalHeight }) => {
    if (bitmap) {
      return (
        <Image
          staticImage
          src={bitmap}
          width={internalWidth}
          height={internalHeight}
          onClick={onClick}
        />
      );
    }

    const customWidth = viewBox[2] || internalWidth;
    const customHeight = viewBox[3] || internalHeight;
    const customX = viewBox[0] || 0;
    const customY = viewBox[1] || 0;
    const replacement = getReplacement(change);
    const rotate = replacement.rotate
      ? `rotate(${replacement.rotate} ${customWidth / 2} ${customHeight / 2})`
      : "";
    return (
      <SVGStyled
        width={internalWidth}
        internalWidth={customWidth}
        height={internalHeight}
        internalHeight={customHeight}
        // className={animation && `animation-${animation}`}
        dangerouslySetInnerHTML={{
          // because safari don't support SVG2 spec
          // we can't use transform on svg itself
          __html: `<g transform="${rotate}">${shape}</g>`,
        }}
        // style={SvgCss}
        viewBox={`${customX} ${customY} ${customWidth} ${customHeight}`}
        onClick={onClick}
      />
    );
  };

  CreatedIcon.displayName = `CreatedIcon.${name}`;

  return CreatedIcon;
};
