import React from "react";
import { t } from "@lingui/macro";
import { DEFAULT_LANG } from "../../../constants";
import getDomainZone from "../../../functions/url/getDomainZone";
import { Icon } from "../../Icon";
import Button from "../../Button";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";

/**
 * Menu, appearing after click on help button
 */
export function HelpMenuPure({ i18n, lang = DEFAULT_LANG }) {
  const DNSZone = getDomainZone(lang);
  const phoneSupport = i18n._(t`+ 44 208 089 2265`);

  return (
    <>
      <Button
        external
        to={`https://wegotrip.${DNSZone}/support/`}
        target="_blank"
        className="HeaderMenu-item"
        size="S"
      >
        {i18n._(t`Common questions`)}
      </Button>
      <Button
        external
        to={`tel:${phoneSupport.replace(/\s/g, "")}`}
        className="HeaderMenu-item"
        size="S"
      >
        {phoneSupport}
      </Button>
      <Button
        external
        to={`https://wegotrip.${DNSZone}/feedback/`}
        target="_blank"
        className="HeaderMenu-item"
        size="S"
      >
        {i18n._(t`Contact us`)}
      </Button>
    </>
  );
}

export default function HelpMenu({ isMobile, isDesktop, i18n, lang }) {
  const icon = <Icon name="help" width="15" height="20" />;
  const title = i18n._(t`Help`);
  return (
    <PopupOrDropdownAdapter
      hideTextOnTablet
      hideTextOnMobile
      mergeHandlers
      disableOutside={false}
      icon={icon}
      iconPosition="before"
      align="right"
      isMobile={isMobile}
      title={isMobile && title}
      triggerElement={isDesktop ? title : icon}
      bodyClassName="HeaderMenu"
      noButtonWrap={!isDesktop}
      ContentComponent={() => <HelpMenuPure i18n={i18n} lang={lang} />}
    />
  );
}
