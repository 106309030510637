import yellow from "./yellow";
import grey from "./grey";
import green from "./green";
import accent from "./accent";
import accentBorder from "./accentBorder";
import white from "./white";
import whiteBorder from "./whiteBorder";

export const star = {
  "star/yellow": yellow,
  "star/grey": grey,
  "star/green": green,
  "star/accent": accent,
  "star/accentBorder": accentBorder,
  "star/white": white,
  "star/whiteBorder": whiteBorder,
};
