export const search = {
  search: {
    shape:
      '<path d="M16.092 14.81a4.587 4.587 0 10-6.487-6.486 4.587 4.587 0 006.487 6.487zm.747 2.363a6.882 6.882 0 01-8.855-10.47 6.88 6.88 0 0110.47 8.854c.024.02.048.042.07.064l2.734 2.734a1.147 1.147 0 11-1.622 1.622l-2.733-2.734a1.163 1.163 0 01-.064-.07z" fill="#5648F8"/>',
    viewBox: [0, 0, 26, 26],
  },
  "search/thin": {
    shape:
      '<path fill="#000" fill-opacity=".86" d="m19.475 20.15-6.25-6.25c-.5.417-1.075.742-1.725.975-.65.234-1.3.35-1.95.35-1.617 0-2.979-.554-4.087-1.663C4.354 12.454 3.8 11.1 3.8 9.5s.554-2.954 1.663-4.063C6.571 4.33 7.925 3.775 9.525 3.775s2.958.554 4.075 1.662c1.117 1.11 1.675 2.463 1.675 4.063 0 .7-.125 1.375-.375 2.025-.25.65-.575 1.2-.975 1.65L20.2 19.45l-.725.7ZM9.55 14.225c1.317 0 2.433-.454 3.35-1.362.917-.908 1.375-2.03 1.375-3.363 0-1.333-.458-2.454-1.375-3.363-.917-.908-2.033-1.362-3.35-1.362-1.333 0-2.458.454-3.375 1.362C5.258 7.047 4.8 8.167 4.8 9.5c0 1.334.458 2.455 1.375 3.363.917.908 2.042 1.362 3.375 1.362Z"/>',
    viewBox: [0, 0, 24, 24],
  },
};
