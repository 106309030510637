import React, { Component } from "react";
import { connect } from "react-redux";
import { Trans, t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { isValidPhoneNumber, formatPhoneNumber } from "react-phone-number-input";
import { LOGIN_SMS, LOGIN_SMS_VERIFY, ERROR_RESOLVE } from "../../../stores/types";
import { RECAPTCHA_SITE_KEY } from "../../../constants";
import { VERIFY_CODE } from "../../../constants/errorTypes";
import classNames from "../../../functions/classNames";
import getSagaErrors from "../../../functions/getSagaErrors";
import sendEvent from "../../../functions/analytics";
import Input from "../../Input";
import withChain from "../../Input/withChain";
import { Icon } from "../../Icon";
import Button from "../../Button";

import "./SignInPhone.css";

/**
 * Icon imports
 */
import svgEmail from "../assets/letter.svg";
import SMSTimer from "../SMSTimer";
import Image from "../../Image";

/**
 * Chained input for entering code from SMS
 * @type {React::Component}
 */
const ChainedInput = withChain(Input);

/**
 * Messages from phone number input field
 * @type {Object}
 */
const errorMessages = {
  empty: <Trans>This field cannot be empty</Trans>,
  format: <Trans>Please, enter correct phone number</Trans>,
};

/**
 * SignIn with ability to specify phone number
 * @param {Object} $
 * @param {React::Component} $.Block - modified SignIn component, if given, SignInPhone will be rendered as it's child
 * @param {Array[React::Element]} $.children - component's children
 * @param {SignInScreen} screen - id of the curent screen
 * @param {Boolean} hideSignInPhone - if true, will appear under the dropdown "More options"
 * @param {Function} screenChanged - user choosed sign in option
 */
class SignInPhone extends Component {
  /**
   * Component's inner state
   * @prop {SignInScreen} screen - id of the current showing content
   * @prop {String} phoneNumber - phone number entered into input field
   * @prop {String} error - error occured with phone number
   * @prop {String} sagaError - error occured while interracting with server
   * @prop {Boolean} hidden - only on root screen, if block hidden under `More options` button
   * @prop {Boolean} smsTimeout - show SMS timer near send again button
   * @param {String} option - promo option
   */
  state = {
    screen: null,
    hidden: true,
    phoneNumber: "",
    code: "",
    error: null,
    sagaError: null,
    smsTimeout: false,
  };

  /**
   * SMS code input field
   * @type {React::Ref}
   */
  codeInputRef = React.createRef();

  /**
   * reCAPTCHA
   * @type {React::Ref}
   */
  captchaRef = React.createRef();

  /**
   * Checking out initial value for `hidden` state prop from `hideSignInPhone` prop
   */
  componentDidMount() {
    const { hideSignInPhone = false } = this.props;
    this.setState({ hidden: hideSignInPhone });
  }

  /**
   * Checking if back button pressed
   */
  componentDidUpdate(prevProps) {
    const { screen, sagaErrors } = this.props;
    const { screen: phoneScreen, sagaError } = this.state;

    if (screen !== phoneScreen && [null, "log-in", "phone"].indexOf(screen) !== -1) {
      this.setState({ screen });
    }

    if (prevProps.screen !== screen && screen === "phone" && this.codeInputRef.current) {
      this.codeInputRef.current.focus();
    }

    /**
     * Checking for errors from sagas
     */
    if (!sagaError) {
      /**
       * ToDo: handle errors of SMS not sent
       * const codeWasNotSent = getSagaErrors(sagaErrors, REQUEST_CODE)[0];
       */
      const wrongCode = getSagaErrors(sagaErrors, VERIFY_CODE)[0];

      if (wrongCode) {
        this.setState({ sagaError: VERIFY_CODE });
      }
    }
  }

  /**
   * Sends request to the server for sending code to specified phone number
   */
  sendCode = async e => {
    e.preventDefault();

    const { screenChanged, dispatch } = this.props;
    const { phoneNumber = "", sagaError } = this.state;

    if (sagaError) {
      dispatch({ type: ERROR_RESOLVE, errorType: sagaError });
      this.setState({ sagaError: null, code: "" });
    }

    if (!phoneNumber) {
      this.setState({ error: errorMessages.empty });
    } else if (!isValidPhoneNumber(phoneNumber)) {
      this.setState({ error: errorMessages.format });
    } else {
      const token = await this.captchaRef.current.executeAsync();
      dispatch({ type: LOGIN_SMS, phone: phoneNumber, recaptcha: token });
      this.captchaRef.current.reset();

      this.setState({ screen: "phone", smsTimeout: true });
      screenChanged("phone");
    }
  };

  /**
   * Verifies code, entered by user
   * @param {String} code - code from SMS
   */
  verifyCode = code => {
    const { phoneNumber } = this.state;
    const { dispatch } = this.props;

    if (!code) {
      this.setState({ error: errorMessages.empty });
    } else {
      dispatch({ type: LOGIN_SMS_VERIFY, phone: phoneNumber, code });
    }
  };

  render() {
    const { Block, children = [], dispatch, i18n, ...props } = this.props;
    const { phoneNumber, code, screen, error, sagaError, hidden, smsTimeout } = this.state;

    if (screen === "phone") {
      const wrongCode = sagaError === VERIFY_CODE;

      return (
        <div
          className={classNames(
            "SignInPhone",
            wrongCode && "SignInPhone--incorrect",
            smsTimeout && "SignInPhone--locked",
          )}
        >
          <div className="SignIn__header">
            <Image staticImage src={svgEmail} loading="lazy" />
            <span>
              {wrongCode ? <Trans>Incorrect code</Trans> : <Trans>Confirm your number</Trans>}
            </span>
          </div>
          {wrongCode ? (
            <Trans>Please try again</Trans>
          ) : (
            <Trans>
              Please enter 4-digit code WeGoTrip just sent to {formatPhoneNumber(phoneNumber)}.
            </Trans>
          )}
          <div className="SignInPhone__code">
            <ChainedInput
              ref={this.codeInputRef}
              eraseOnPaste
              error={wrongCode}
              symbols={1}
              pattern="[+]?[0-9]*"
              theme="card"
              value={code}
              autoComplete="one-time-code"
              type="tel"
              onChanged={value => {
                if (sagaError) {
                  dispatch({ type: ERROR_RESOLVE, errorType: sagaError });
                  this.setState({ sagaError: null });
                }

                this.setState({ code: value });
                if (value.length === 4) this.verifyCode(value);
              }}
            />
          </div>
          {error && <div className="SignInPhone__error">{error}</div>}
          <div className="SignInPhone__retry">
            <Trans>Did’t get a text.</Trans>{" "}
            <span>
              <Trans>
                <a onClick={this.sendCode}>Send again</a>
              </Trans>{" "}
              {smsTimeout ? (
                <>
                  &#40;
                  <SMSTimer timeout={30} release={() => this.setState({ smsTimeout: false })} />
                  &#41;
                </>
              ) : null}
            </span>
          </div>
        </div>
      );
    }

    const phoneEnterScreen = (
      <form className="SignInPhone__phoneCode" onSubmit={this.sendCode}>
        <Input
          phone
          className="SignInPhone__phone"
          theme="card"
          placeholder={i18n._(t`Your phone`)}
          value={phoneNumber}
          onChange={value => this.setState({ phoneNumber: value, error: null })}
        />
        <ReCAPTCHA ref={this.captchaRef} sitekey={RECAPTCHA_SITE_KEY} size="invisible" />
        <Button className="SignInPhone__continue" theme="main" type="submit">
          <Trans>Continue</Trans>
        </Button>
        {error && <div className="SignInPhone__error">{error}</div>}
        <div className="SignInPhone__or">
          <Trans>or</Trans>
        </div>
      </form>
    );

    if (Block) {
      return (
        <Block {...props}>
          <div>
            {children[0]}
            {hidden ? (
              <Button
                className="SignInPhone__dropdown"
                theme="simple"
                icon={<Icon name="chevron/down" width="12" height="12" />}
                onClick={() => {
                  this.setState({ hidden: false });
                  sendEvent("track", "Login More Options Clicked");
                }}
              >
                <Trans>More login options</Trans>
              </Button>
            ) : (
              phoneEnterScreen
            )}
          </div>
          {children[1] || children}
        </Block>
      );
    }

    return phoneEnterScreen;
  }
}

/**
 * Copies state props into component props
 * @param {Object} state - redux state
 */
function mapStateToProps(state) {
  return {
    sagaErrors: state.errors.errors,
  };
}

/**
 * Create actions to change store
 * @param {Function} dispatch
 */
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(SignInPhone));
