import React from "react";
import SignInPhone from "../SignInPhone";

/**
 * HOC for adding ability to sign in with phone number
 * @param {React::Element} SignIn - modified SignIn class
 */
export default SignIn => {
  /**
   * SignIn with ability to specify phone number
   * @param {Object} $
   * @param {Array[React::Element]} $.children - component's children (will be used as SignIn children)
   * @param {SignInScreen} screen - id of the curent screen
   * @param {Function} screenChanged - user choosed sign in option
   * @param {String} option - promo option
   */
  return ({ children = [], screenChanged = () => {}, ...props }) => {
    return (
      <SignInPhone screenChanged={screenChanged} Block={SignIn} {...props}>
        {children}
      </SignInPhone>
    );
  };
};
