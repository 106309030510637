import { useEffect } from "react";
import { useHistory } from "react-router-dom";

/**
 * Custom React hook to save the previous page's URL in localStorage.
 * This hook uses the `useHistory` hook from React Router to get the current page's URL.
 * It saves the URL of the previous page in the browser's localStorage under the key "previousPage".
 * This can be useful for implementing custom navigation logic or returning to the previous page.
 */
const usePreviousPageSave = preserve => {
  const history = useHistory();

  useEffect(() => {
    if (preserve) {
      const currentPathName = history.location.pathname;
      localStorage.setItem("previousPage", currentPathName);
    }
  }, [preserve, history]);
};

export default usePreviousPageSave;
