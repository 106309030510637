import {
  CREATE_TOUR_REQUEST,
  CREATE_TOUR_SUCCESS,
  CREATE_TOUR_FAILURE,
  SET_FORM_DATA,
} from "../types";

export const init = {
  booking: {},
  formData: {},
  tourData: {
    product_id: null,
    slug: null,
  },
  loading: false,
  error: null,
};

export default function generateTours(state = init, action) {
  switch (action.type) {
    case CREATE_TOUR_REQUEST:
      return { ...state, loading: true, error: null };
    case CREATE_TOUR_SUCCESS:
      return {
        ...state,
        loading: false,
        tourData: {
          product_id: action.payload.product_id,
          slug: action.payload.slug,
        },
      };
    case CREATE_TOUR_FAILURE:
      return { ...state, loading: false, error: action.payload };
    case SET_FORM_DATA:
      return { ...state, formData: action.payload };
    default:
      return state;
  }
}
