import React from "react";
import "../../components/Search/SearchItem/SearchItem.css";

function HighlightedText({ text, searchWords }) {
  if (!searchWords) {
    return text;
  }
  const searchChars = searchWords.split("").map(c => c.toLowerCase());

  // Функция для поиска совпадающего массива символов в тексте
  const highlightMatch = () => {
    const chars = text.toLowerCase().split("");
    let matchStart = -1;
    let matchEnd = -1;

    for (let i = 0; i < chars.length; i += 1) {
      if (chars[i] === searchChars[0]) {
        matchStart = i;
        matchEnd = i + 1;

        for (let j = 1; j < searchChars.length; j += 1) {
          if (chars[i + j] !== searchChars[j]) {
            matchStart = -1;
            matchEnd = -1;
            break;
          }
          matchEnd += 1;
        }
        if (matchStart >= 0 && matchEnd >= 0) {
          break;
        }
      }
    }

    if (matchStart >= 0 && matchEnd >= 0) {
      return (
        <>
          {text.slice(0, matchStart)}
          <span className="SearchItem__highlighted">{text.slice(matchStart, matchEnd)}</span>
          {text.slice(matchEnd)}
        </>
      );
    }
    return text;
  };
  return <div>{highlightMatch(text)}</div>;
}

export default HighlightedText;
