/**
 * Returns, that user is author or not
 * @param  {Object} $ - user data
 * @param {Boolean} is_partner - true if user passed 2-rd step of authors onboarding
 * @param {Boolean} is_sales - true if user passed 2-rd step of authors onboarding
 */
export default function ({ is_partner, is_sales }) {
  if (is_partner && is_sales) return true;
  return false;
}
