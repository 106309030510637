import React, { useState } from "react";
import { Trans } from "@lingui/macro";
import "./CookieFooter.css";
import { acceptCookieBanner } from "../../functions/cookieBanner";
import Cookies from "../../functions/cookies";
import { isSSR } from "../NoSSR";
import { Icon } from "../Icon";
import Button from "../Button";

const CookieFooter = () => {
  const [isShow, setIsShow] = useState(true);

  if (isSSR || Cookies.get("wgtCookieAccepted") || !isShow) return null;

  /**
   * Close cookie banner and gives cookie that banner was accepted
   */
  const handleAccept = () => {
    setIsShow(false);
    acceptCookieBanner();
  };

  return (
    !isSSR && (
      <div className="CookieFooter">
        <div className="CookieFooter__text">
          <div className="CookieFooter__text_title">
            <Icon name="cookies" />
            <Trans>Cookies!</Trans>
          </div>
          <div className="CookieFooter__text_subtitle">
            <Trans>This website collects cookies to deliver the best user experience</Trans>
          </div>
        </div>
        <Button theme="black" size="M" className="CookieFooter__button" onClick={handleAccept}>
          <Trans>Ok, got it</Trans>
        </Button>
      </div>
    )
  );
};

export default CookieFooter;
