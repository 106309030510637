import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { DEFAULT_LANG } from "../../constants";
import getCurrentLanguage from "./getCurrentLanguage";

/**
 * Gets language code of the page
 * @returns {String} - code of language to use on page
 */

export default function useCurrentLanguage() {
  const { lang } = useParams(DEFAULT_LANG);
  return useMemo(() => getCurrentLanguage(lang), [lang]);
}
