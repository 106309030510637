import {
  FETCH_ATTRACTIONS_REQUEST,
  FETCH_ATTRACTIONS_SUCCESS,
  FETCH_ATTRACTIONS_FAILURE,
  FETCH_V3_ATTRACTIONS_REQUEST,
  FETCH_V3_ATTRACTIONS_SUCCESS,
  FETCH_V3_ATTRACTIONS_FAILURE,
} from "../types";

export const init = {
  attractions: [],
  attractionsV3: [],
  loading: false,
  error: null,
};

export default function attractions(state = init, action) {
  switch (action.type) {
    case FETCH_ATTRACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ATTRACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        attractions: action.payload,
      };

    case FETCH_ATTRACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case FETCH_V3_ATTRACTIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_V3_ATTRACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        attractionsV3: action.payload,
      };
    case FETCH_V3_ATTRACTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
}
