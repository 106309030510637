/* eslint-disable camelcase */
import { takeEvery, put, call } from "redux-saga/effects";
import { DEFAULT_LANG } from "../../constants";
import * as types from "../types";
import { Api } from "../../functions/fetchFromApi";

function* sendFeedback(action) {
  const { method = "patch", reviewID, lang = DEFAULT_LANG, type, ...params } = action;

  try {
    const data = yield call(Api[method], `/api/feedback/${reviewID ? `${reviewID}/` : ""}`, {
      payload: params,
      lang,
    });
    // TODO: check 'patch' request
    yield put({ type: types.SEND_FEEDBACK_SUCCESS, id: data.id });
  } catch (error) {
    yield put({ type: types.SEND_FEEDBACK_FAILURE, error });
  }
}

export default function* watch() {
  yield takeEvery(types.SEND_FEEDBACK, sendFeedback);
}
