export const done = {
  done: {
    shape:
      '<path fill="#000" fill-opacity=".98" d="m6.367 11.533-3.317-3.3.483-.483 2.834 2.833 6.1-6.1.483.484-6.583 6.566Z"/>',
    viewBox: [0, 0, 16, 16],
  },
  "done/all": {
    shape:
      '<path fill="#22ccd8"  d="m6.7 17.3-4.95-4.95.7-.7L6.7 15.9l.7-.7.7.7-1.4 1.4zm5.65 0L7.4 12.35l.7-.72 4.25 4.24 9.2-9.2.7.73-9.9 9.9zm-.7-4.95-.7-.7L15.9 6.7l.7.7-4.95 4.95z"/>',
    viewBox: [0, 0, 24, 24],
  },
};
