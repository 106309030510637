import { TRANSLATED_LANGS, TRANSLATIONS } from "../../constants/i18n/existingTranslations";
import intersection from "lodash/intersection";
import pick from "lodash/pick";

/**
 * Returns object with lang codes as keys and paths to translations as values
 * @param {Array[String]} langs - languages to convert paths
 * @returns {Object}
 */
export default function getTranslations(langs) {
  const langCodes = langs.map(l => l.code);
  return pick(TRANSLATIONS, intersection(langCodes, TRANSLATED_LANGS));
}
