import { matchPath } from "react-router-dom";
import routes from "../../routes";

/**
 * Finds route in available `AfterJS` routes by URL
 * @param {HTTPRequest} req - description of the request for HTML page (URL parts are being get from this)
 * @returns {Object} - AfterJS route description
 */
export default function findAfterRoute(req) {
  return routes.find(r => matchPath(req.baseUrl + req.path, { ...r, path: r.path || "*" }));
}
