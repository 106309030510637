export const person = {
  person: {
    shape: `<path fill="#000" fill-opacity=".98" d="M12 11.38a2.9 2.9 0 0 1-2.13-.88A2.9 2.9 0 0 1 9 8.37c0-.81.3-1.52.88-2.1.58-.6 1.29-.9 2.12-.9.83 0 1.54.3 2.13.9.58.58.87 1.29.87 2.1 0 .84-.3 1.55-.88 2.13a2.9 2.9 0 0 1-2.12.88Zm-7 7.24v-1.64c0-.42.12-.8.36-1.17.24-.36.56-.63.96-.84.96-.45 1.9-.78 2.84-1a12.18 12.18 0 0 1 5.68 0c.94.22 1.89.55 2.84 1 .4.2.72.48.96.84s.36.75.36 1.17v1.64H5Zm1-1h12v-.64c0-.24-.07-.45-.21-.64-.14-.2-.34-.36-.59-.49-.83-.4-1.69-.7-2.56-.91a11.34 11.34 0 0 0-5.28 0c-.87.2-1.73.51-2.56.91-.25.13-.45.3-.59.49-.14.19-.21.4-.21.64v.64Zm6-7.25c.55 0 1.02-.2 1.41-.58.4-.4.59-.86.59-1.41 0-.56-.2-1.03-.59-1.42-.39-.39-.86-.58-1.41-.58-.55 0-1.02.2-1.41.58-.4.4-.59.87-.59 1.42 0 .54.2 1.02.59 1.4.39.4.86.6 1.41.6Z"/>`,
    viewBox: [0, 0, 24, 24],
  },
  "person/add": {
    shape: `<path fill="#000" fill-opacity=".86" d="M18.25 13.5v-3h-3v-1h3v-3h1v3h3v1h-3v3h-1Zm-9.5-2.13a2.9 2.9 0 0 1-2.13-.87 2.9 2.9 0 0 1-.87-2.13c0-.81.3-1.52.88-2.1.58-.6 1.29-.9 2.12-.9.83 0 1.54.3 2.13.9.58.58.87 1.29.87 2.1 0 .84-.3 1.55-.88 2.13a2.9 2.9 0 0 1-2.12.88Zm-7 7.26v-1.65c0-.42.12-.8.36-1.17.24-.36.56-.63.97-.84.95-.45 1.9-.78 2.83-1a12.2 12.2 0 0 1 5.68 0c.94.22 1.88.55 2.84 1 .4.2.72.48.96.84s.36.75.36 1.17v1.64h-14Zm1-1h12v-.65c0-.24-.07-.45-.21-.64-.14-.2-.34-.36-.59-.49-.83-.4-1.69-.7-2.56-.91a11.34 11.34 0 0 0-5.28 0c-.87.2-1.73.51-2.56.91-.25.13-.45.3-.59.49-.14.19-.21.4-.21.64v.64Zm6-7.25c.55 0 1.02-.2 1.41-.6.4-.38.59-.85.59-1.4 0-.56-.2-1.03-.59-1.42-.39-.39-.86-.58-1.41-.58-.55 0-1.02.2-1.41.58-.4.4-.59.87-.59 1.42 0 .54.2 1.02.59 1.4.39.4.86.6 1.41.6Z"/>`,
    viewBox: [0, 0, 24, 24],
  },
};
