import React from "react";
import { getImgProxyUrl } from "../../constants/imgproxy";
import { isSSR } from "../NoSSR";

const loader = ({ src, width, height = 0, quality, ...imgProxyOptions }) => {
  return getImgProxyUrl(src, { width, height, ...imgProxyOptions });
};

/**
 * Universal image component, that should be used instead of `<img />` tag
 * @param {Boolean} staticImage - should be `true` for static local frontend assets
 * @param {String} src - image src
 * @param {Number} width - image width
 * @param {Number} height - image height
 * @param {Object} imgProxyOptions - passed options for imgProxy url builder
 */
export default function Image({
  staticImage = false,
  src,
  width,
  height,
  imgProxyOptions,
  ...props
}) {
  // To prevent premature triggering of lazy loading
  if (isSSR) return null;
  if (staticImage) return <img src={src} width={width} height={height} {...props} />;
  return <img src={loader({ src, width, height, ...props, ...imgProxyOptions })} {...props} />;
}
