export const copy = {
  copy: {
    shape: `<path fill="#000" fill-opacity=".54" d="M16 1H4a2 2 0 0 0-2 2v14h2V3h12V1zm-1 4 6 6v10a2 2 0 0 1-2 2H7.99A2 2 0 0 1 6 21l.01-14A2 2 0 0 1 8 5h7zm-1 7h5.5L14 6.5V12z"/>`,
    viewBox: [0, 0, 24, 24],
  },
  "copy/light": {
    shape: `<path fill="#000" fill-opacity=".76" d="M18.38 18H8.63A1.58 1.58 0 0 1 7 16.37V3.63A1.58 1.58 0 0 1 8.63 2h6.87L20 6.5v9.88A1.58 1.58 0 0 1 18.37 18zM15 7V3H8.62c-.16 0-.3.06-.43.19S8 3.46 8 3.63v12.75c0 .16.06.3.19.43s.27.19.44.19h9.74c.17 0 .32-.06.44-.19s.19-.27.19-.43V7h-4zM4.62 22A1.58 1.58 0 0 1 3 20.37V8h1v12.38c0 .16.06.3.19.43s.27.19.43.19H14v1H4.62z"/>`,
    viewBox: [0, 0, 24, 24],
  },
};
