export default class DataLayer {
  /**
   * Push eventObj to dataLayer for later use in Google Tag Manager
   *
   * @param {Object} eventObj
   * @param {Track} track
   */
  static push(eventObj) {
    if (window.dataLayer) {
      window.dataLayer.push(eventObj);
    } else {
      window.dataLayer = [eventObj];
    }
  }

  /**
   * Push event to dataLayer for later use in Google Tag Manager
   *
   * @param {String} eventName
   * @param {Object} meta
   */
  static event(eventName, meta = {}) {
    DataLayer.push({ event: eventName, ...meta });
  }
}
