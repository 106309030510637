import * as types from "../types";

export const init = {
  items: [],
  loading: false,
  error: null,
};

export default function languages(state = {}, action) {
  switch (action.type) {
    case types.FETCH_LANGUAGES:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_LANGUAGES_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case types.FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        items: [...action.data],
        loading: false,
      };

    default:
      return state;
  }
}
