/**
 * Existing messages translations
 * @type {Object}
 */
export const TRANSLATIONS = {
  en: require(`../../locales/en/messages.js`),
  ru: require(`../../locales/ru/messages.js`),
};

/**
 * Lang codes to which app was translated
 * @type {Array[String]}
 */
export const TRANSLATED_LANGS = Object.keys(TRANSLATIONS);
