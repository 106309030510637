import { MONTH_IN_SEC, COOKIE_MAX_EXPIRES } from "../constants";
import Cookies from "./cookies";

export function getParameterByName(name, url) {
  const urlString = url || window.location.href;

  // eslint-disable-next-line no-useless-escape
  const cookieName = name.replace(/[\[\]]/g, "\\$&");
  const regex = new RegExp(`[?&]${cookieName}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(urlString);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export function renewUrlParamInCookies(name, expires) {
  const currentValue = Cookies.get(name);
  if (currentValue && currentValue !== "undefined") {
    Cookies.set(name, currentValue, { path: "/", expires });
  }
}

export function saveUrlParamToCookies(name, expires) {
  const value = getParameterByName(name);
  if (value) {
    Cookies.set(name, value, { path: "/", expires });
  }
}

const PARTNER_PARAMS = [
  { name: "affam", affiliate: "affam" },
  { name: "sub_id", affiliate: "travelpayouts" },
  { name: "coupon", affiliate: "wegotrip" },
  // To prevent data loss. On the affiliate page `coupon` in generated links was mistakenly replaced by `affiliateId` for a week.
  { name: "affiliateId", affiliate: "wegotrip" },
];

/**
 * Sends partner parameters `affiliate` and `cid` to Cookies as `partnerParams`
 */
export function savePartnerParamsToCookies() {
  PARTNER_PARAMS.forEach(({ name, affiliate }) => {
    const value = getParameterByName(name);
    if (value) {
      Cookies.set("partnerParams", JSON.stringify({ affiliate, cid: value }), {
        path: "/",
        expires: MONTH_IN_SEC,
      });
    }
  });
}

const UTM_PARAMS = ["utm_campaign", "utm_content", "utm_medium", "utm_source", "utm_term"];
/**
 * Sends `UTM` parameters and `referrer` link to Cookies
 */
export function saveUtmParamsToCookies() {
  const utmData = UTM_PARAMS.reduce((obj, utmParam) => {
    const paramValue = getParameterByName(utmParam);
    // eslint-disable-next-line no-param-reassign
    if (paramValue) obj[utmParam] = paramValue;
    return obj;
  }, {});

  if (Object.keys(utmData).length > 0) {
    const storeOptions = { path: "/", expires: COOKIE_MAX_EXPIRES };
    Cookies.set("utmParams", JSON.stringify(utmData), storeOptions);
    // Cookies.set("referrerLink", window.location.href, storeOptions);
  }
}
/**
 * Sends `referer_link` URL to Cookies.
 * Referrer is the URI of the page that linked to the current page.
 * If the document was not opened with a link, an empty string is returned and no `referer_link` is saved to Cookies.
 */
export function saveReferrerToCookies() {
  if (document.referrer)
    Cookies.set("referer_link", document.referrer, { path: "/", expires: COOKIE_MAX_EXPIRES });
}

export function saveUrlParamsToCookies() {
  saveReferrerToCookies();
  saveUtmParamsToCookies();
  savePartnerParamsToCookies();
}

/**
 * Restore partner parameters from Cookies.
 * @returns {object} can include `affiliate`, `cid`, `referer_link`, `utm_campaign`, `utm_content`, `utm_medium`, `utm_source`, `utm_term`
 */
export function restoreUrlParams() {
  const partnerParams = Cookies.get("partnerParams") || {};
  const utmParams = Cookies.get("utmParams") || {};
  const referrerLink = Cookies.get("referer_link");
  const referer = referrerLink ? { referer_link: referrerLink } : {};

  return { ...partnerParams, ...utmParams, ...referer };
}
