import React, { Component, createRef } from "react";
import { connect, useDispatch } from "react-redux";
import { Trans } from "@lingui/macro";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import { withRouter } from "react-router-dom";
import { LOGOUT, LOGIN_POPUP_OPEN, LOGIN_POPUP_CLOSE } from "../../../stores/types";
import classNames from "../../../functions/classNames";
import reverseUrl from "../../../functions/reverseUrl";
import sendEvent from "../../../functions/analytics";
import useCurrentLanguage from "../../../functions/languages/useCurrentLanguage";
import NoSSR from "../../NoSSR";
import Button from "../../Button";
import getDomainZone from "../../../functions/url/getDomainZone";
import { Icon } from "../../Icon";
import Popup from "../../Popup";
import SignInDefault from "../../SignIn";
import withOffer from "../../SignIn/withOffer";
import withEmail from "../../SignIn/withEmail";
import withPhone from "../../SignIn/withPhone";
import withPassword from "../../SignIn/withPassword";
import "./UserAccount.css";
import isAuthor from "../../../functions/isAuthor";
import PopupOrDropdownAdapter from "../../PopupOrDropdownAdapter";

const SignIn = withOffer(withPassword(withEmail(withPhone(SignInDefault))));

/**
 * DropdownBody from `UserAccount__button`
 * Different buttons and links for logined and unlogined states
 * @param {Object} $
 * @param {String} $.affiliate - affiliate object of user
 * @param {Boolean} $.isLogged - if user is logged in account
 * @param {Function} $.onSignIn - `log in` or `sign up` button clicked
 * @param {Function} $.setDropdown - for removing uncontrolled dropdown after user performed update
 * @param {String} $.lang - current language code
 */
export function UserAccountDropdown({
  affiliate,
  isLogged,
  onSignIn,
  setDropdown,
  isUserAuthor,
  isMobile,
}) {
  const lang = useCurrentLanguage();
  const DNSZone = getDomainZone(lang);
  const dispatch = useDispatch();

  if (!isLogged) {
    return (
      <>
        <Button
          className="HeaderMenu-item"
          size="S"
          onClick={() => {
            onSignIn(null);
            setDropdown(null);
          }}
        >
          <Trans>Sign up</Trans>
        </Button>
        <Button
          className="HeaderMenu-item"
          size="S"
          onClick={() => {
            onSignIn("log-in");
            setDropdown(null);
          }}
        >
          <Trans>Log in</Trans>
        </Button>

        <Button
          external
          to={`https://wegotrip.${DNSZone}/suppliers/`}
          className="HeaderMenu-item"
          size="S"
        >
          <Trans>Become a supplier</Trans>
        </Button>

        <Button
          external
          to={`https://wegotrip.${DNSZone}/partners/`}
          className="HeaderMenu-item"
          size="S"
        >
          <Trans>Become an affiliate partner</Trans>
        </Button>
      </>
    );
  }

  return (
    <>
      <Button
        external
        className="HeaderMenu-item"
        iconPosition="before"
        icon={<Icon name="bag" width="15" height="16" />}
        size="S"
        to={reverseUrl("bookings", {
          lang,
        })}
      >
        <Trans>My tours and tickets</Trans>
      </Button>
      {isMobile && (
        <Button
          external
          className="HeaderMenu-item HeaderMenu-item--favorites"
          iconPosition="before"
          icon={<Icon name="heart" width="15" height="14" />}
          size="S"
          to={reverseUrl("favorites", {
            lang,
          })}
        >
          <Trans>Favorites</Trans>
        </Button>
      )}

      <Button
        external
        to={
          isUserAuthor ? `https://wegotrip.${DNSZone}/my` : `https://wegotrip.${DNSZone}/suppliers/`
        }
        className="HeaderMenu-item"
        size="S"
      >
        {isUserAuthor ? <Trans>WeGoTrip Studio</Trans> : <Trans>Become a supplier</Trans>}
      </Button>

      {!isEmpty(affiliate) ? (
        <Button
          external
          to={reverseUrl("affiliate", { lang })}
          className="HeaderMenu-item"
          size="S"
        >
          <Trans>Affiliate Account</Trans>
        </Button>
      ) : (
        <Button
          external
          to={`https://wegotrip.${DNSZone}/partners/`}
          className="HeaderMenu-item"
          size="S"
        >
          <Trans>Become an affiliate partner</Trans>
        </Button>
      )}
      <Button
        className="HeaderMenu-item"
        iconPosition="before"
        size="S"
        icon={<Icon name="exit" width="15" height="15" />}
        onClick={() => {
          dispatch({ type: LOGOUT });
          sendEvent("track", "Logout");
          setDropdown(null);
        }}
      >
        <Trans>Sign out</Trans>
      </Button>
    </>
  );
}

/**
 * Shows user icon in the header
 */
class UserAccount extends Component {
  /**
   * Component's inner state
   * @prop {SignInScreen} defaultScreen - `null` or `"log-in"`, initial screen of login popup
   * @prop {SignInScreen} loginScreen - id of the current login screen in login popup
   * @prop {String?} email - email from `unseccessfulemail` query
   */

  constructor(props) {
    super(props);

    this.state = {
      defaultScreen: "log-in",
      loginScreen: "log-in",
      email: null,
    };

    this.popupRef = createRef();
  }

  /**
   * Checking if login via email was successful and link not expired
   * Showing popup with error screen if expired and user ot logged
   */
  componentDidMount() {
    const {
      history: { location },
      user: { isLogged },
      dispatch,
    } = this.props;
    const email = location.search.match(/unsuccessfulemail=[^&]*/g);

    if (!isLogged && email) {
      this.setState({ loginScreen: "email-error", email: email[0].split("=")[1] });
      dispatch({ type: LOGIN_POPUP_OPEN });
    }
  }

  /**
   * Close login popup if user was successfully logged in
   * @param {Object} prevProps
   */
  componentDidUpdate(prevProps) {
    const {
      user: { isLogged },
      dispatch,
    } = this.props;
    const {
      user: { isLogged: wasLogged },
    } = prevProps;

    if (isLogged && isLogged !== wasLogged) {
      this.popupRef.current?.handleClose();

      // Check if needed
      dispatch({ type: LOGIN_POPUP_CLOSE });
    }
  }

  /**
   * Shows popup on `log-in` or `null` screen
   * @param {String|Null} screen - type of screen to show (`null` or `log-in`)
   */
  showPopup = screen => {
    const { dispatch } = this.props;

    this.setState({ defaultScreen: screen, loginScreen: screen });
    dispatch({ type: LOGIN_POPUP_OPEN });
  };

  render() {
    const { loginScreen, email, defaultScreen } = this.state;
    const {
      user: { isLogged, loginPopup, loginPopupClosable, user, affiliate },
      dispatch,
      setDropdown = () => {},
      isMobile,
      isDesktop,
    } = this.props;

    if (loginPopup) this.popupRef.current?.handleOpen();

    const isUserAuthor = isAuthor(user);

    /**
     * Choosing content of `UserAccount__request` element
     * (request type - log in or sign up)
     */
    let request = {
      title: <Trans>Already have an account?</Trans>,
      button: <Trans>Log in</Trans>,
    };
    if (loginScreen === "log-in") {
      request = {
        title: <Trans>Don’t have an account?</Trans>,
        button: <Trans>Sign up</Trans>,
      };
    }

    /**
     * UserAccount button text
     * Depends on name from user profile
     */
    const userName = get(user, "first_name") ? (
      <Trans>Hi, {user.first_name}</Trans>
    ) : (
      <Trans>Hello!</Trans>
    );
    const buttonText = isLogged ? userName : <Trans>Account</Trans>;
    const Dropdown = (
      <UserAccountDropdown
        isLogged={isLogged}
        isMobile={isMobile}
        email={user && user.email}
        phone={user && user.phone}
        affiliate={affiliate}
        setDropdown={setDropdown}
        isUserAuthor={isUserAuthor}
        onSignIn={this.showPopup}
      />
    );

    return (
      <div className={classNames("UserAccount", isLogged && "UserAccount--logged")}>
        <PopupOrDropdownAdapter
          hideTextOnTablet
          hideTextOnMobile
          mergeHandlers
          isMobile={isMobile}
          disableOutside={false}
          title={isMobile && <Trans>Account</Trans>}
          triggerElement={isDesktop ? buttonText : <Icon name="person" />}
          icon={<Icon name="person" />}
          iconPosition="before"
          align="right"
          bodyClassName="HeaderMenu"
          noButtonWrap={!isDesktop}
          ContentComponent={() => Dropdown}
        />
        <NoSSR>
          {/* !isLogged && <LoginInvitation onSignIn={this.showPopup} /> */}
          <Popup
            ref={this.popupRef}
            className="UserAccount__login"
            backButton={[null, "log-in"].indexOf(loginScreen) === -1}
            closable={loginPopupClosable}
            onClosed={() => dispatch({ type: LOGIN_POPUP_CLOSE })}
            onBackButtonClick={() => this.setState({ loginScreen: defaultScreen })}
          >
            <SignIn
              hideSignInPhone
              email={email}
              socials={["Google", "Facebook"]}
              screen={loginScreen}
              screenChanged={screen => this.setState({ loginScreen: screen })}
            />
            {[null, "log-in"].indexOf(loginScreen) !== -1 ? (
              <div className="UserAccount__request">
                <Button
                  size="S"
                  onClick={() => {
                    const updatedScreens = { defaultScreen: loginScreen ? null : "log-in" };
                    if ([null, "log-in"].indexOf(loginScreen) !== -1)
                      updatedScreens.loginScreen = updatedScreens.defaultScreen;

                    this.setState(updatedScreens);
                    sendEvent("track", "Login Mode Switched", { title: loginScreen });
                  }}
                >
                  {request.button}
                </Button>
              </div>
            ) : null}
          </Popup>
        </NoSSR>
      </div>
    );
  }
}

/**
 * Copies state props into component props
 * @param {Object} state - redux state
 */
function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

/**
 * Create actions to change store
 * @param {Function} dispatch
 */
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserAccount));
