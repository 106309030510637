export default () => {
  // Prevents Chrome from focusing buttons on click
  // Copied from amplifr.com
  let currentTarget;
  let lastMouseDown;
  function q(t) {
    if (t.tagName === "A" || t.tagName === "BUTTON") {
      return t;
    }
    if (t.parentElement) {
      return q(t.parentElement);
    }
    return null;
  }
  document.addEventListener("mousedown", event => {
    currentTarget = q(event.target);
    lastMouseDown = Date.now();
  });

  document.addEventListener("focusin", event => {
    if (event.target === currentTarget && Date.now() - lastMouseDown < 1000) {
      event.target.blur();
    }
  });
};
