export const shoppingCart = {
  "shopping-cart": {
    shape:
      '<path fill="#000" fill-opacity=".98" d="M7.3 21.125c-.417 0-.77-.146-1.062-.437a1.447 1.447 0 0 1-.438-1.063c0-.433.146-.792.438-1.075a1.467 1.467 0 0 1 1.062-.425c.433 0 .792.142 1.075.425.283.283.425.642.425 1.075 0 .417-.142.771-.425 1.063-.283.291-.642.437-1.075.437Zm9.4 0c-.433 0-.792-.146-1.075-.437a1.47 1.47 0 0 1-.425-1.063c0-.433.142-.792.425-1.075.283-.283.642-.425 1.075-.425.417 0 .77.142 1.062.425.292.283.438.642.438 1.075 0 .417-.146.771-.438 1.063a1.446 1.446 0 0 1-1.062.437ZM5.875 5.5l2.675 5.625h6.625a.57.57 0 0 0 .313-.088.803.803 0 0 0 .237-.237l2.625-4.75c.067-.15.067-.28 0-.388-.067-.108-.175-.162-.325-.162H5.875Zm-.475-1h13c.45 0 .783.179 1 .537.217.359.225.721.025 1.088l-2.85 5.2c-.15.25-.342.446-.575.587a1.418 1.418 0 0 1-.75.213H8.1L6.875 14.35c-.1.15-.1.317 0 .5s.242.275.425.275h10.9v1H7.3c-.583 0-1.017-.246-1.3-.738-.283-.491-.292-.987-.025-1.487l1.5-2.675L3.8 3.5H2v-1h2.45l.95 2Z"/>',
    viewBox: [0, 0, 24, 24],
  },
  "shopping-cart-main": {
    shape:
      '<path fill="#22CCD8" d="M46.5 34V22h-12v-4h12V6h4v12h12v4h-12v12h-4ZM29.7 84.5c-1.667 0-3.083-.583-4.248-1.748-1.168-1.168-1.752-2.585-1.752-4.252 0-1.733.584-3.167 1.752-4.3 1.165-1.133 2.581-1.7 4.248-1.7 1.733 0 3.167.567 4.3 1.7s1.7 2.567 1.7 4.3c0 1.667-.567 3.084-1.7 4.252-1.133 1.165-2.567 1.748-4.3 1.748Zm37.6 0c-1.733 0-3.167-.583-4.3-1.748-1.133-1.168-1.7-2.585-1.7-4.252 0-1.733.567-3.167 1.7-4.3s2.567-1.7 4.3-1.7c1.667 0 3.083.567 4.248 1.7 1.168 1.133 1.752 2.567 1.752 4.3 0 1.667-.584 3.084-1.752 4.252-1.165 1.165-2.581 1.748-4.248 1.748Zm-37.6-20c-2.333 0-4.083-.984-5.248-2.952-1.168-1.965-1.185-3.948-.052-5.948l6-10.7L15.7 14H8.5v-4h9.8l16.4 34.5h28L77.4 18l3.5 1.9-14.1 25.4c-.6 1.067-1.35 1.867-2.248 2.4-.901.533-1.919.8-3.052.8H32.9L28 57.4c-.4.6-.4 1.267 0 2 .4.733.967 1.1 1.7 1.1h43.6v4H29.7Z"/>',
    viewBox: [0, 0, 97, 96],
  },
};
