export const headphones = {
  headphones: {
    shape:
      '<path fill="#000" fill-opacity=".51" d="M8.075 20h-2.45c-.467 0-.854-.154-1.162-.462-.309-.309-.463-.696-.463-1.163V12a7.81 7.81 0 0 1 .625-3.125 8.057 8.057 0 0 1 1.713-2.537 8.057 8.057 0 0 1 2.537-1.713A7.81 7.81 0 0 1 12 4a7.81 7.81 0 0 1 3.125.625 8.068 8.068 0 0 1 2.538 1.713 8.065 8.065 0 0 1 1.712 2.537c.417.967.625 2.008.625 3.125v6.375c0 .467-.154.854-.462 1.163-.309.308-.696.462-1.163.462h-2.45v-6.15H19V12c0-1.95-.68-3.604-2.038-4.963C15.604 5.679 13.95 5 12 5c-1.95 0-3.604.679-4.962 2.037C5.679 8.396 5 10.05 5 12v1.85h3.075V20Zm-1-5.15H5v3.525c0 .167.063.312.188.437s.27.188.437.188h1.45v-4.15Zm9.85 0V19h1.45a.597.597 0 0 0 .437-.188.597.597 0 0 0 .188-.437V14.85h-2.075Z"/>',
    viewBox: [0, 0, 24, 24],
  },
  "headphones/gray": {
    shape:
      '<path stroke="#979797" d="M1.25 16.25V10.5C1.25 8.21251 2.1587 6.01871 3.7762 4.4012C5.3937 2.7837 7.58751 1.875 9.875 1.875C12.1625 1.875 14.3563 2.7837 15.9738 4.4012C17.5913 6.01871 18.5 8.21251 18.5 10.5V16.25" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M18.5 17.2083C18.5 17.7167 18.2981 18.2042 17.9386 18.5636C17.5792 18.9231 17.0917 19.125 16.5833 19.125H15.625C15.1167 19.125 14.6292 18.9231 14.2697 18.5636C13.9103 18.2042 13.7083 17.7167 13.7083 17.2083V14.3333C13.7083 13.825 13.9103 13.3375 14.2697 12.9781C14.6292 12.6186 15.1167 12.4167 15.625 12.4167H18.5V17.2083ZM1.25 17.2083C1.25 17.7167 1.45193 18.2042 1.81138 18.5636C2.17082 18.9231 2.65834 19.125 3.16667 19.125H4.125C4.63333 19.125 5.12084 18.9231 5.48029 18.5636C5.83973 18.2042 6.04167 17.7167 6.04167 17.2083V14.3333C6.04167 13.825 5.83973 13.3375 5.48029 12.9781C5.12084 12.6186 4.63333 12.4167 4.125 12.4167H1.25V17.2083Z" stroke="#979797" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>',
    viewBox: [0, 0, 20, 21],
  },
  "headphones/mic": {
    shape:
      '<path fill="#000" fill-opacity=".51" d="M12 21.375v-1h6.375a.634.634 0 0 0 .45-.163.566.566 0 0 0 .175-.437V19h-3.075v-6.15H19V11c0-1.933-.683-3.583-2.05-4.95C15.583 4.683 13.933 4 12 4c-1.933 0-3.583.683-4.95 2.05C5.683 7.417 5 9.067 5 11v1.85h3.075V19h-2.45c-.467 0-.854-.154-1.162-.462-.309-.309-.463-.696-.463-1.163V11c0-1.1.208-2.137.625-3.112a8.017 8.017 0 0 1 1.713-2.55 8.017 8.017 0 0 1 2.55-1.713A7.833 7.833 0 0 1 12 3c1.1 0 2.138.208 3.113.625a8.031 8.031 0 0 1 2.55 1.713 8.026 8.026 0 0 1 1.712 2.55C19.792 8.863 20 9.9 20 11v8.775c0 .45-.154.83-.462 1.138-.309.308-.696.462-1.163.462H12ZM5.625 18h1.45v-4.15H5v3.525a.61.61 0 0 0 .175.45.609.609 0 0 0 .45.175Zm11.3 0H19v-4.15h-2.075V18Z"/>',
    viewBox: [0, 0, 24, 24],
  },
};
