export const languageContent = {
  "language-content": {
    shape:
      '<g><path fill="#333333" d="M11.0799 0C5.1138 0 0.159806 4.31477 0.159806 9.74818C0.159806 12.3018 1.35835 14.549 3.14286 16.2736C3.07294 17.2624 2.57688 17.8517 1.89104 18.431C1.52149 18.7406 1.11531 19.0303 0.745763 19.3898C0.37621 19.7494 0 20.2355 0 20.908C0 21.2975 0.263015 21.6371 0.639225 21.7337C2.24062 22.1631 3.96186 21.8469 5.53995 21.2809C6.95823 20.7715 8.16011 20.0157 9.10896 19.2833C9.75151 19.3898 10.4007 19.4697 11.0799 19.4697C17.046 19.4697 22 15.1783 22 9.74818C22 4.3181 17.046 0 11.0799 0ZM11.0799 1.7046C16.2337 1.7046 20.2954 5.36017 20.2954 9.74818C20.2954 14.1362 16.2337 17.7651 11.0799 17.7651C10.4107 17.7651 9.76816 17.7252 9.13559 17.6053C8.88257 17.5454 8.61955 17.6053 8.41646 17.7651C7.6474 18.411 6.35563 19.1901 4.98063 19.6828C4.13499 19.9858 3.27603 20.1256 2.477 20.1622C2.65678 20.0191 2.79661 19.9159 3.00969 19.7361C3.86864 19.0136 4.90073 17.8484 4.90073 16.0872C4.90073 15.8408 4.79419 15.6077 4.60775 15.4479C2.90315 13.9764 1.86441 11.9722 1.86441 9.74818C1.86441 5.36017 5.92615 1.7046 11.0799 1.7046ZM11.0799 4.15496C9.83142 4.15496 8.76271 5.01725 8.76271 6.63196C8.76271 7.68735 9.25878 8.74274 9.90799 9.40194C10.161 10.0645 9.71156 10.3275 9.61501 10.3608C8.30993 10.8335 6.76513 11.6925 6.76513 12.5448V12.8644C6.76513 14.023 8.99909 14.276 11.0799 14.276C13.164 14.276 15.3947 14.023 15.3947 12.8644V12.5448C15.3947 11.6692 13.8632 10.8136 12.4915 10.3608C12.4283 10.3408 12.0321 10.1544 12.2785 9.40194C12.921 8.73941 13.3971 7.68402 13.3971 6.63196C13.3971 5.01725 12.3284 4.15496 11.0799 4.15496Z"/></g> ',
  },
  "language-content/hieroglyph": {
    shape:
      '<path d="M14.4058 13.5605L10.6871 6.12305L6.96832 13.5605" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M8.03088 11.4355H13.3434" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M4.84338 1.3418V2.93555" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M0.593384 2.93555H9.09338" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M6.96838 2.93555C6.96838 4.6263 6.29673 6.24781 5.10119 7.44335C3.90564 8.6389 2.28414 9.31055 0.593384 9.31055" stroke="black" stroke-linecap="round" stroke-linejoin="round"/><path d="M3.08154 5.06055C3.52175 6.30299 4.33612 7.37852 5.41262 8.13918C6.48912 8.89985 7.77486 9.30827 9.09299 9.30828" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>',
    viewBox: [0, 0, 15, 15],
  },
};
