export default {
  "step/complete": {
    shape:
      '<path d="M62 29.3221V32.0821C61.9963 38.5514 59.9015 44.8461 56.028 50.0276C52.1545 55.209 46.7098 58.9995 40.5061 60.8338C34.3023 62.6681 27.6718 62.4478 21.6034 60.2059C15.535 57.9639 10.354 53.8204 6.83288 48.3933C3.3118 42.9662 1.63937 36.5463 2.06503 30.091C2.49069 23.6358 4.99162 17.4911 9.19484 12.5733C13.3981 7.65557 19.0784 4.22826 25.3886 2.80255C31.6988 1.37684 38.3008 2.02912 44.21 4.66211" stroke="#FFFFFF" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M62 8.08189L32 38.1119L23 29.1119" stroke="#22CCD8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>',
    viewBox: [0, 0, 64, 64],
  },
  "step/complete-bold": {
    shape:
      '<path d="M42 20.6714V22.5114C41.9975 26.8243 40.601 31.0208 38.0187 34.4751C35.4363 37.9294 31.8066 40.4564 27.6707 41.6792C23.5349 42.9021 19.1145 42.7552 15.0689 41.2606C11.0234 39.766 7.56931 37.0036 5.22192 33.3856C2.87453 29.7675 1.75958 25.4876 2.04335 21.1841C2.32712 16.8806 3.99441 12.7841 6.79656 9.50559C9.5987 6.22708 13.3856 3.94221 17.5924 2.99174C21.7992 2.04127 26.2005 2.47612 30.14 4.23145" stroke="white" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/><path d="M42 6.51123L22 26.5312L16 20.5312" stroke="#22CCD8" stroke-width="4" stroke-linecap="round" stroke-linejoin="round"/>',
    viewBox: [0, 0, 44, 45],
  },
};
