import { takeEvery, takeLatest, put, call, select } from "redux-saga/effects";
import * as types from "../types";
import * as errorTypes from "../../constants/errorTypes";
import { WEEK_IN_SEC } from "../../constants";
import { Api } from "../../functions/fetchFromApi";
import Cookies from "../../functions/cookies";
import sendEvent, { convertProduct, identify } from "../../functions/analytics";
import { isSSR } from "../../components/NoSSR";
import { disableDiscountPromo } from "../../functions/promo/discountPromo";

/**
 * Logins user with login and password
 * @param {Object} action
 * @param {String} action.login
 * @param {String} action.password
 */
function* login({ username, password }) {
  try {
    const data = yield call(Api.post, "/api/login/", {
      payload: {
        username,
        password,
      },
    });

    if (data.token) {
      Cookies.set("token", data.token, { path: "/", expires: WEEK_IN_SEC });
      sendEvent("track", "Login Success", { method: "password" });
    }
    disableDiscountPromo();
  } catch (error) {
    yield put({
      type: types.ERROR_ADD,
      error: { type: errorTypes.WRONG_KEYS, data: error },
    });
    yield put({ type: types.RESET_USER_LOADING });
    sendEvent("track", "Login Start Failed", { method: "password", error });
    return;
  }

  yield put({ type: types.FETCH_USER });
}

/**
 * Sends magic link to the users email
 * @param {Object} action
 * @param {String} action.email - targeting user's email
 * @param {String} action.next - what page to open after successful login
 * @param {Number} action.favouriteId - id of the product user tried to like
 * @param {Boolean?} action.guide - if user is signing in as guide
 * @param {Boolean?} action.referal - if user is signing in as referral partner
 */
function* loginEmail(action) {
  // const userParams = (yield call(Cookies.get, "userParams")) || {};
  try {
    yield call(Api.post, "/api/login-magic/", {
      payload: {
        username: action.email,
        next: action.next,
        ...action,
      },
    });
    sendEvent("track", "Login Started", { method: "email" });
  } catch (error) {
    sendEvent("track", "Login Start Failed", { method: "email", error });
  }
}

function* loginMagicCode({ code, username, onError = () => {} }) {
  try {
    const { token } = yield call(Api.post, `/api/login-magic/${code}`, {
      payload: { username },
    });

    if (token) {
      Cookies.set("token", token, { path: "/", expires: WEEK_IN_SEC });
      yield put({ type: types.FETCH_USER });
    } else throw new Error();
  } catch (error) {
    yield put({ type: types.ERROR_ADD, error: { type: errorTypes.WRONG_KEYS, data: error } });
    onError();
  }
}

/**
 * Requests server to send a login code on given phone number
 * @param {Object} action
 * @param {String} action.phone - user phone
 * @param {String} action.recaptcha - token returned by reCAPTCHA
 * @param {String} phone - phone number
 */
function* loginRequestCode({ phone, recaptcha }) {
  const userParams = (yield call(Cookies.get, "userParams")) || {};
  try {
    yield call(Api.post, "/api/v2/sms/request/", {
      payload: { phone, recaptcha, ...userParams },
    });
    sendEvent("track", "Login Started", { method: "sms" });
  } catch (error) {
    sendEvent("track", "Login Start Failed", { method: "sms", error });
    /**
     * ToDo: unskip when sending code will be processed
     * yield put({ type: types.ERROR_ADD, error: { type: errorTypes.REQUEST_CODE, data: error } });
     */
  }
}

/**
 * Verifies entered login code received via SMS by user
 * @param {Object} action
 * @param {String} phone - phone number
 * @param {String} code - login code received via SMS
 */
function* loginVerifyCode(action) {
  try {
    const data = yield call(Api.post, "/api/v2/sms/verify/", {
      payload: { phone: action.phone, code: action.code },
    });

    if (data.errors || !data.token) {
      yield put({
        type: types.ERROR_ADD,
        error: { type: errorTypes.VERIFY_CODE, data: data.errors[0] },
      });
    } else {
      // TODO: unify success login sagas
      Cookies.set("token", data.token, {
        path: "/",
        expires: WEEK_IN_SEC,
      });
      // TODO: track signup or login
      sendEvent("track", "Login Success", { method: "sms" });
    }
  } catch (error) {
    yield put({ type: types.ERROR_ADD, error: { type: errorTypes.VERIFY_CODE, data: error } });
    return;
  }

  yield put({ type: types.FETCH_USER });
}

/**
 * Logs user out
 * Resets user state in store to initial if succeed
 */
function* logout() {
  const csrftoken = Cookies.get("csrftoken");

  try {
    yield call(Api.post, "/logout/", {
      headers: { "X-CSRFToken": csrftoken },
      cookies: document.cookie,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Logout failed", error);
  }

  try {
    let maxCallIndex = 10;
    while ((Cookies.get("token") || Cookies.get("csrftoken")) && maxCallIndex > 0) {
      maxCallIndex -= 1;
      Cookies.set("csrftoken", null, {
        secure: true,
        path: "/",
        domain: `${window.location.host}`,
        expires: -1,
      });
      Cookies.set("token", "", {
        path: "/",
        expires: -1,
      });
      Cookies.set("csrftoken", null, {
        secure: true,
        path: "/",
        domain: `.${window.location.host}`,
        expires: -1,
      });
      Cookies.set("token", null, {
        secure: true,
        path: "/",
        domain: `.${window.location.host}`,
        expires: -1,
      });
    }
    yield put({ type: types.LOGOUT_SUCCESS });
    sendEvent("track", "Logout");
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error("Logout failed (cookies)", error);
  }
}

/**
 * Fetchs current user
 * @param {Object} action
 * @param {String} action.cookies - cookies (when SSR, accessible from `getInitialProps`)
 * @param {Boolean} action.fetchFavorites - fetch tours from `user_favorites`
 */
function* fetchUser({ cookies, fetchFavorites }) {
  try {
    const { data } = yield call(Api.get, `/api/profile/`, { cookies });
    yield put({ type: types.FETCH_USER_SUCCESS, data });

    identify(data);

    if (fetchFavorites && data.favorite_products.length) {
      yield put({ type: types.FETCH_PRODUCTS, ids: data.favorite_products, append: true });
    }
    if (data.is_sales) {
      yield put({ type: types.FETCH_AFFILIATE_PARTNER, cookies });
    }
  } catch (error) {
    yield put({ type: types.FETCH_USER_FAILURE, error });
  }
}

/**
 * Adds product to favorites
 * @param {Object} action
 * @param {Number} action.product - product description
 * @param {String?} action.link - link to the product
 * @param {String?} action.lang - current locale
 */
function* addFavourite({ link, lang, ...action }) {
  const user = yield select(state => state.user.user);
  const favourites = yield select(state => state.user.user.favorite_products);

  try {
    const { data } = yield call(Api.put, `/api/profile/`, {
      payload: { favorite_products: [action.product.id, ...favourites] },
    });

    if (!isSSR) {
      sendEvent("track", "Product Added to Wishlist", {
        wishlist_id: user.id,
        wishlist_name: "favorites",
        ...convertProduct(action.product, { lang, link }),
      });
    }

    yield put({ type: types.ADD_USER_FAVOURITE_SUCCESS, data, productId: action.product.id });
  } catch (error) {
    yield put({ type: types.ADD_USER_FAVOURITE_FAILURE, error, productId: action.product.id });
  }
}

/**
 * Removes product from favorites
 * @param {Object} action
 * @param {Number} action.product - product description
 * @param {String?} action.link - link to the product
 * @param {String?} action.lang - current locale
 */
function* removeFavourite({ link, lang, ...action }) {
  const user = yield select(state => state.user.user);
  const favourites = yield select(state => state.user.user.favorite_products);

  try {
    const { data } = yield call(Api.put, `/api/profile/`, {
      payload: { favorite_products: favourites.filter(item => item !== action.product.id) },
    });

    if (!isSSR) {
      sendEvent("track", "Product Removed from Wishlist", {
        wishlist_id: user.id,
        wishlist_name: "favorites",
        ...convertProduct(action.product, { lang, link }),
      });
    }

    yield put({ type: types.REMOVE_USER_FAVOURITE_SUCCESS, data, productId: action.product.id });
  } catch (error) {
    yield put({ type: types.REMOVE_USER_FAVOURITE_FAILURE, error, productId: action.product.id });
  }
}

function* fetchAffiliatePartner({ cookies }) {
  try {
    const { data } = yield call(Api.get, `/api/v2/affiliate/`, { cookies });
    yield put({ type: types.FETCH_AFFILIATE_PARTNER_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.FETCH_AFFILIATE_PARTNER_FAILURE, error });
  }
}

function* createAffiliatePartner(action) {
  try {
    const { data } = yield call(Api.post, `/api/v2/affiliate/`, {
      payload: action.payload,
    });

    if (!isSSR) {
      sendEvent("track", "Affiliate Partner Registered", {
        id: action.payload.id,
        sites: action.payload.sites,
        description: action.payload.description,
      });
    }

    yield put({ type: types.CREATE_AFFILIATE_PARTNER_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.CREATE_AFFILIATE_PARTNER_FAILURE, error });
  }
}

function* updateAffiliatePartner(action) {
  try {
    const { data } = yield call(Api.put, `/api/v2/affiliate/`, {
      payload: action.payload,
    });

    if (!isSSR) {
      sendEvent("track", "Affiliate Partner Updated");
    }

    yield put({ type: types.UPDATE_AFFILIATE_PARTNER_SUCCESS, data });
  } catch (error) {
    yield put({ type: types.UPDATE_AFFILIATE_PARTNER_FAILURE, error });
  }
}

/**
 * Sends event about closing popup to analytics
 */
function* loginPopupClose() {
  yield call(sendEvent, "track", "Login Popup Closed");
}

function* createUnsubscribe({ user, subject }) {
  try {
    yield call(Api.post, `/api/v2/unsubscribe/`, {
      payload: { user, subject },
    });

    yield put({ type: types.CREATE_UNSUBSCRIBE_SUCCESS });
  } catch (error) {
    yield put({ type: types.CREATE_UNSUBSCRIBE_FAILURE, error });
  }
}

function* sendUnsubscribeText({ user, text }) {
  try {
    yield call(Api.put, `/api/v2/unsubscribe/`, {
      payload: { user, reason: text },
    });

    yield put({ type: types.SEND_UNSUBSCRIBE_TEXT_SUCCESS });
  } catch (error) {
    yield put({ type: types.SEND_UNSUBSCRIBE_TEXT_FAILURE, error });
  }
}

function* fetchUserBookings({ lang }) {
  try {
    const { data } = yield call(Api.get, `/api/v2/inventory/`, { lang });

    yield put({ type: types.FETCH_USER_BOOKINGS_SUCCESS, bookings: data.results });
  } catch (error) {
    yield put({ type: types.FETCH_USER_BOOKINGS_FAILURE, error });
  }
}

export default function* watch() {
  yield takeEvery(types.FETCH_USER, fetchUser);
  yield takeEvery(types.ADD_USER_FAVOURITE, addFavourite);
  yield takeEvery(types.REMOVE_USER_FAVOURITE, removeFavourite);
  yield takeLatest(types.LOGIN_EMAIL, loginEmail);
  yield takeLatest(types.LOGIN_SMS, loginRequestCode);
  yield takeLatest(types.LOGIN, login);
  yield takeLatest(types.LOGIN_MAGIC_CODE, loginMagicCode);
  yield takeLatest(types.LOGIN_SMS_VERIFY, loginVerifyCode);
  yield takeLatest(types.LOGIN_POPUP_CLOSE, loginPopupClose);
  yield takeLatest(types.LOGOUT, logout);
  yield takeLatest(types.CREATE_AFFILIATE_PARTNER, createAffiliatePartner);
  yield takeEvery(types.UPDATE_AFFILIATE_PARTNER, updateAffiliatePartner);
  yield takeEvery(types.FETCH_AFFILIATE_PARTNER, fetchAffiliatePartner);
  yield takeEvery(types.CREATE_UNSUBSCRIBE, createUnsubscribe);
  yield takeEvery(types.SEND_UNSUBSCRIBE_TEXT, sendUnsubscribeText);
  yield takeEvery(types.FETCH_USER_BOOKINGS, fetchUserBookings);
}
