import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { t, Trans } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { LOGIN, ERROR_RESOLVE } from "../../../stores/types";
import { WRONG_KEYS } from "../../../constants/errorTypes";
import getSagaErrors from "../../../functions/getSagaErrors";
import { Icon } from "../../Icon";
import { SpinnerCircle } from "../../Spinner";
import Input from "../../Input";
import Button from "../../Button";
import "./SignInPassword.css";

/**
 * Form error descriptions
 * @type {Object}
 */
const errors = {
  [WRONG_KEYS]: { code: WRONG_KEYS, msg: <Trans>Incorrect login or password</Trans> },
  KEYS_EMPTY: { code: "KEYS_EMPTY", msg: <Trans>Please fill in all fields</Trans> },
};

/**
 * Displays offer to fill form with email
 */
class SignInPassword extends Component {
  /**
   * Inner state of the class
   * @prop {String} login - login entered into the input
   * @prop {String} password - password entered into the input
   * @prop {React::Component} error - error message from email input field
   */
  state = {
    error: null,
    login: "",
    password: "",
  };

  /**
   * Checking for errors from sagas
   */
  componentDidUpdate() {
    const { sagaErrors } = this.props;
    const { error } = this.state;

    if (!error) {
      const wrongKeys = getSagaErrors(sagaErrors, errors.WRONG_KEYS.code)[0];
      if (wrongKeys) this.setState({ error: errors.WRONG_KEYS.code });
    }
  }

  /**
   * Sends request to the server with login and password
   */
  check = event => {
    event.preventDefault();

    const { dispatch } = this.props;
    const { login, password } = this.state;

    if (!login || !password) {
      this.setState({ error: errors.KEYS_EMPTY.code });
      return;
    }

    dispatch({ type: LOGIN, username: login, password });
  };

  /**
   * Updates state field with suitable input field value
   * @param {String} fieldId - state key to update
   * @param {String} value - new value from input
   */
  updateField = (fieldId, value) => {
    const stateChunk = { [fieldId]: value };
    const { error } = this.state;

    if (error) {
      const { dispatch } = this.props;
      dispatch({ type: ERROR_RESOLVE, errorType: error });
      stateChunk.error = null;
    }

    this.setState(stateChunk);
  };

  render() {
    const { loading, i18n } = this.props;
    const { error } = this.state;

    return (
      <div className="SignInPassword">
        <div className="SignIn__header">
          <div className="SignIn__holo">
            <Icon name="key" width="58" height="58" />
          </div>
          <span>
            <Trans>Sign in with password</Trans>
          </span>
        </div>
        <Trans>Please, enter your login and password</Trans>
        <form onSubmit={this.check}>
          <div className="SignInPassword__form">
            <Input
              name="username"
              className="SignInPassword__field"
              placeholder={i18n._(t`Login`)}
              theme="card"
              onChange={({ target }) => this.updateField("login", target.value)}
            />
            <Input
              name="password"
              className="SignInPassword__field"
              placeholder={i18n._(t`Password`)}
              type="password"
              theme="card"
              onChange={({ target }) => this.updateField("password", target.value)}
            />
            {error && <div className="SignInPassword__error">{errors[error].msg}</div>}
          </div>
          <Button className="SignInPassword__check" theme="card" type="submit">
            {loading ? (
              <>
                <SpinnerCircle className="SignInPassword__spinner" theme="main" />
                <Trans>Checking...</Trans>
              </>
            ) : (
              <Trans>Check</Trans>
            )}
          </Button>
        </form>
      </div>
    );
  }
}

/**
 * Copies state props into component props
 * @param {Object} state - redux state
 */
function mapStateToProps(state) {
  return {
    loading: state.user.loading,
    sagaErrors: state.errors.errors,
  };
}

/**
 * Create actions to change store
 * @param {Function} dispatch
 */
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default withI18n()(withRouter(connect(mapStateToProps, mapDispatchToProps)(SignInPassword)));
