import Cookies from "../cookies";
import { COOKIE_TEN_YEAR_AGE, COOKIE_WEEK_AGE } from "../../constants";

/**
 * Sets cookie that promo was declined to hide promo banner
 */
export const disableDiscountPromo = () => {
  Cookies.set("wgtDiscountPromoDeclined", true, { path: "/", expires: COOKIE_TEN_YEAR_AGE });
};

/**
 * Sets cookie that code popup was closed
 * based on declined userIds array
 * @param {Number} userId - state `user::user::id` to identify
 */
export const disableDiscountCodePopup = userId => {
  const existedDeclines = new Set(Cookies.get("wgtDiscountCodePopupClosed"));
  existedDeclines.add(userId);
  Cookies.set("wgtDiscountCodePopupClosed", [...existedDeclines], {
    path: "/",
    expires: COOKIE_WEEK_AGE,
  });
};
