import REGIONS from "../../constants/region/regions";

/**
 * Gets current domain region
 * @param {String} domainRegion - current domain region
 * @returns {String} - region from `REGIONS` constants
 */
export default function getDomainRegion(domainRegion) {
  if (domainRegion === "ru") {
    return REGIONS.Russia;
  }
  return REGIONS.WorldWide;
}
