import { hasKey } from "../../../functions/hasKey";
import { shapes } from "../shapes";
import { notFound } from "../shapes/notFound";

/**
 * Description of the icon
 * @typedef {Object} IconShape
 * @prop {String} shape - svg contents of the icon
 * @prop {String} animation - CSS class name with animation
 * @prop {String} viewBox - viewBox added to the svg tag (if not mentioned, will be costructed from Icon class props)
 * @prop {String} bitmap - bitmap contents of the icon (if it's not already contents vector), data URI or require
 */

/**
 * Get shape from list of shapes
 * @param {String} shapeName - icon id
 * @returns {IconShape}
 */
export const getShape = shapeName => {
  if (hasKey(shapes, shapeName)) {
    return shapes[shapeName];
  }
  return notFound;
};
