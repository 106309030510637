import { takeEvery, select, put, delay, call } from "redux-saga/effects";
import * as types from "../types";
import { DEFAULT_ALERT_CONTENT } from "./reducers";

function* hideAlert() {
  const isVisible = yield select(state => state.alert.isVisible);

  if (isVisible) {
    yield put({ type: types.SET_ALERT_VISIBILITY, data: false });
    yield delay(200);
    yield put({ type: types.SET_ALERT_CONTENT, data: DEFAULT_ALERT_CONTENT });
  }
}

/**
 * Opens modal with the specified content
 * @param {Object} action - the action passed into the saga.
 * @param {String} action.message - the message the modal will show
 * @param {Array.<label: string, callback: Function>} action.actions - callbacks to actions the modal will show
 */
function* showAlert(action) {
  const isVisible = yield select(state => state.alert.isVisible);

  if (isVisible) {
    yield call(hideAlert);
    yield delay(100);
    yield put({ type: types.SET_ALERT_CONTENT, data: action.data });
    yield put({ type: types.SET_ALERT_VISIBILITY, data: true });
  } else {
    yield put({ type: types.SET_ALERT_CONTENT, data: action.data });
    yield put({ type: types.SET_ALERT_VISIBILITY, data: true });
  }
}

export default function* watch() {
  yield takeEvery(types.SHOW_ALERT, showAlert);
  yield takeEvery(types.HIDE_ALERT, hideAlert);
}
