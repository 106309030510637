import get from "lodash/get";
import * as types from "../types";
import { API_ERROR_MSG } from "../../constants/errorTypes";

/**
 * Error occured in user saga/reducer
 * @typedef {Object} SagaError
 * @prop {String} type - error type from `/src/constants/errorTypes`
 * @prop {Object} data - info about error (usually - from server)
 */

/**
 * Saga/reducer errors for outer code (e.g. for frontend error indication)
 * @type {Object}
 * @prop {Array[SagaError]} errors - list of the unhandled exceptions
 */
export const init = {
  errors: [],
};

export default function errors(state = {}, action) {
  /**
   * Checking if network crashed
   */
  if (String(get(action, "error.data.message")) === API_ERROR_MSG) {
    return state;
  }

  switch (action.type) {
    case types.ERROR_ADD:
      return {
        ...state,
        errors:
          state.errors.indexOf(action.error) === -1
            ? [action.error, ...state.errors]
            : state.errors,
      };
    case types.ERROR_RESOLVE:
      return {
        ...state,
        errors: state.errors.filter(({ type }) => type !== action.errorType),
      };

    default:
      return state;
  }
}
