import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types";
import { Api } from "../../functions/fetchFromApi";
import toQueryString from "../../functions/toQueryString";
import { FETCH_CITY_FAILURE, FETCH_CITY_SUCCESS } from "../types";

function* fetchCity(action) {
  try {
    const { lang, cityId } = action.payload;
    const response = yield call(
      Api.get,
      `/api/v2/cities/${cityId}/?${toQueryString({ lang, preorder: true })}`,
      { lang },
    );
    yield put({ type: FETCH_CITY_SUCCESS, payload: response.data });
  } catch (e) {
    yield put({ type: FETCH_CITY_FAILURE, payload: e.message });
  }
}

function* fetchCities(action) {
  const { countryId, lang } = action.payload;
  try {
    const { data } = yield call(
      Api.get,
      `/api/v2/cities/?country=${countryId}&lang=${lang}&preorder=true`,
      { lang },
    );
    yield put({
      type: types.FETCH_CITIES_SUCCESS,
      payload: data.results,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CITIES_FAILURE,
      error,
    });
  }
}

function* fetchCategories(action) {
  const { cityId, lang } = action.payload;

  try {
    const response = yield call(Api.get, `/api/v2/cities/${cityId}/interests/`, { lang });

    yield put({
      type: types.FETCH_CATEGORIES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_CATEGORIES_FAILURE,
      error,
    });
  }
}

function* fetchTravelersInterests(action) {
  const { cityId, lang } = action.payload;

  try {
    const response = yield call(
      Api.get,
      `/api/v2/cities/${cityId}/interests/?autoGenerated=false`,
      { lang },
    );

    yield put({
      type: types.FETCH_TRAVELERS_INTERESTS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_TRAVELERS_INTERESTS_FAILURE,
      error,
    });
  }
}

function* fetchInterests(action) {
  const { cityId, lang } = action.payload;

  try {
    const response = yield call(Api.get, `/api/v2/cities/${cityId}/interests/?autoGenerated=true`, {
      lang,
    });

    yield put({
      type: types.FETCH_INTERESTS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield put({
      type: types.FETCH_INTERESTS_FAILURE,
      error,
    });
  }
}

export default function* watchFetchCities() {
  yield takeEvery(types.FETCH_CITY, fetchCity);
  yield takeEvery(types.FETCH_CITIES, fetchCities);
  yield takeEvery(types.FETCH_CATEGORIES, fetchCategories);
  yield takeEvery(types.FETCH_INTERESTS, fetchInterests);
  yield takeEvery(types.FETCH_TRAVELERS_INTERESTS, fetchTravelersInterests);
}
