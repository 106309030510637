import { t } from "@lingui/macro";

export const CURRENCIES = [
  { code: "EUR", name: t`Euro`, icon: "euro" },
  { code: "USD", name: t`US Dollar`, icon: "dollar" },
  { code: "RUB", name: t`Russian rouble` },
  { code: "GBP", name: t`British Pound`, icon: "pound" },
  // { code: "HKD", name: "Hong Kong Dollar" },
];

export const EURO_COUNTRIES = [
  "AD",
  "AT",
  "BE",
  "CY",
  "EE",
  "FI",
  "FR",
  "DE",
  "GR",
  "IE",
  "IT",
  "LV",
  "LT",
  "LU",
  "MT",
  "MC",
  "ME",
  "NL",
  "PT",
  "SM",
  "SK",
  "SI",
  "ES",
  "TF",
  "BL",
  "MF",
  "PM",
  "BG",
  "HR",
  "CZ",
  "DK",
  "HU",
  "IS",
  "LI",
  "NO",
  "PL",
  "RO",
  "SE",
  "CH",
];

export const ROUBLE_COUNTRIES = ["RU", "UA", "KZ", "BY", "MN"];

export const POUND_COUNTRIES = ["GB", "JE", "GG"];
