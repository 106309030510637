import { CURRENCIES } from "../../constants/currencies";

/**
 * Gets codes of available currencies on current domain
 * @param {String} domainRegion - region (e.g. `"ru"` or `"com"`)
 * @returns {Array[String]} - currency codes
 */
export default function getAvailableCurrOnDomain(domainRegion) {
  const allCurCodes = CURRENCIES.map(c => c.code);

  if (domainRegion === "ru") {
    return ["RUB"];
  }
  if (domainRegion === "com") {
    return allCurCodes.filter(c => c !== "RUB");
  }

  return allCurCodes;
}
