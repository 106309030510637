export default function (paramsString) {
  if (paramsString.indexOf("?") !== -1) {
    // eslint-disable-next-line no-param-reassign
    paramsString = paramsString.replace("?", "");
  }
  const urlParams = new URLSearchParams(paramsString);
  return Array.from(urlParams.keys()).reduce((res, key) => {
    res[key] = urlParams.get(key);
    return res;
  }, {});
}
