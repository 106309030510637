import { generatePath } from "react-router";
import routes from "../routes";
import { DEFAULT_LANG } from "../constants";

/**
 * Generates an URL for given name and params
 * @param {string} name - name of route from ./routes
 * @param {object} params - params for route, e.g. lang, citySlug, etc
 */

export default function reverseUrl(name, { lang = DEFAULT_LANG, ...params }) {
  const route = routes.find(r => r.name === name);
  if (!route) {
    throw new Error(`There is no such route "${name}"`);
  }

  const pathParams = lang === "en" || lang === "ru" ? params : { lang, ...params };

  const path = generatePath(route.path, pathParams);

  return path;
}

/**
 * Finds an URL for given path and params
 * @param {string} path - name of route from ./routes
 * @param {object} params - params for route, e.g. lang, citySlug, etc
 */
export function getPathByPattern(path) {
  const route = routes.find(r => r.path === path);
  if (!route) {
    throw new Error(`There is no such route "${path}"`);
  }
  return route;
}

/**
 * Finds an URL for given path and params
 * @param {string} path - name of route from ./routes
 * @param {object} params - params for route, e.g. lang, citySlug, etc
 */
export function getPathByName(name) {
  const route = routes.find(r => r.name === name);
  if (!route) {
    throw new Error(`There is no such route "${name}"`);
  }
  return route;
}
