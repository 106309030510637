export default function toQueryString(paramsObject) {
  return Object.keys(paramsObject)
    .filter(key => Boolean(paramsObject[key]) || paramsObject[key] === 0)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(paramsObject[key])}`)
    .join("&");
}

/**
 * Takes query from url and converts it into object
 * @param {String} url - url with query to parse
 * @return {Object} - parsed query
 */
export function parseQueryString(url) {
  if (url.indexOf("?") === -1) {
    return {};
  }

  const query = url.split("?").pop();
  return (
    query.split("&").reduce((acc, pair) => {
      const [key, value] = pair.split("=");
      return { ...acc, [key]: value || true };
    }, {}) || {}
  );
}
