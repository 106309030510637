import reverseUrl from "../reverseUrl";

/**
 * Redirects to same page with ability to change URL params and 301 status
 * Keeps query
 * @param {String} routeName - name of the AfterJS route
 * @param {Object} $0 - some props from `AfterJS` `getInitialProps()` 1st argument
 * @param {HTTPRequest} $0.req - description of the request for current HTML page
 * @param {react-router::Match} match - params from URL
 * @param {Object} newParams - overload for URL params (list only changed)
 * @returms {Object} - instruction for redirecting accepted by `AfterJS` as output from `getInitialProps()` method
 */
export default function redirectToSamePage(routeName, { req, match }, newParams) {
  const q = req.url.split("?").splice(1).join("?");
  return {
    statusCode: 301,
    redirectTo: reverseUrl(routeName, { ...match.params, ...newParams }) + (q ? `?${q}` : ""),
  };
}
