import React from "react";
import { Link as RouterLink } from "react-router-dom";
import classNames from "../../functions/classNames";
import { Icon } from "../Icon";
import "./Link.css";

/**
 * Navigational link with themes
 * @param {Object} $
 * @param {String} $.className - CSS class name
 * @param {String} $.theme - CSS theme
 * @param {String} $.to - location
 * @param {Boolean} $.external - use <a /> instead of <Link />
 * @param {Boolean} $.arrow - add arrow or not
 */
export default function Link({ arrow, children, className, theme, external, to, ...props }) {
  if (!to) {
    return children;
  }

  let LinkTag = RouterLink;
  let linkProps = { to };

  if (external) {
    LinkTag = "a";
    linkProps = { href: to };
  }

  return (
    <LinkTag
      {...props}
      {...linkProps}
      className={classNames(
        "Link",
        arrow && "Link--withArrow",
        theme && `Link--${theme}`,
        className,
        className,
      )}
    >
      {children}
      {arrow ? (
        <div className="Link__arrow">
          <Icon name="chevron/right" width="24" height="24" />
        </div>
      ) : null}
    </LinkTag>
  );
}
