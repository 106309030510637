import React from "react";

/**
 * Pass parameters to component
 * @param {JSX.Element|Function} Component - state
 */
export default function withProps(Component) {
  /**
   * Pass parameters to component
   * @param {object} props - Element props
   */
  return props => {
    let componentWithProps = null;
    if (typeof Component === "function") componentWithProps = <Component {...props} />;
    if (typeof Component === "string") componentWithProps = <div {...props}>{Component}</div>;
    else if (React.isValidElement(Component))
      componentWithProps = React.cloneElement(Component, props);

    return componentWithProps;
  };
}
