/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
import React, { Component } from "react";
import { t } from "@lingui/macro";
import { withI18n } from "@lingui/react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { DOMAIN_REGION } from "../../constants";
import * as CATEGORY from "../../constants/searchCategories";
import toQueryString from "../../functions/toQueryString";
import { Api } from "../../functions/fetchFromApi";
import debounce from "../../functions/debounce";
import classNames from "../../functions/classNames";
import reverseUrl from "../../functions/reverseUrl";
import getDomainRegion from "../../functions/region/getDomainRegion";
import { getProductLink } from "../../functions/getProductLink";
import sendEvent from "../../functions/analytics";
import { renewUrlParamInCookies } from "../../functions/urlParamsToCookiesUtils";
import getCurrentLanguage from "../../functions/languages/getCurrentLanguage";
import { SpinnerCircle } from "../Spinner";
import Image from "../Image";
import SearchItem from "./SearchItem";
import "./Search.css";

/**
 * Icon imports
 */
import svgSearch from "./assets/search.svg";

/**
 * Search bar
 * @param {Object} $
 * @param {String} $.className - additional className to the root component
 * @param {String} $.theme
 * @param {String} $.placeholder - placeholder for input field
 */
class Search extends Component {
  /**
   * Inner state of the component
   * @prop {String} searchValue - current search field value
   * @prop {String} lastrequest - last field value was sent to server
   * @prop {Array} results - search results
   * @prop {Boolean} isLoading - if some requests is in progres
   * @prop {Boolean} resultsVisible - show/hide results
   * @prop {Boolean} notFound - if results are empty
   */
  state = {
    searchValue: "",
    lastRequest: "",
    isLoading: false,
    notFound: false,
    resultsVisible: false,
    results: [],
  };

  /**
   * Sends request to the server for getting search results
   * Updating state of component with these results
   * @param {String} payload - request for search
   */
  // `react/sort-comp` wants this to go after `searchDebounced` which leads to `search` is undefined, ¯\_(ツ)_/¯
  // eslint-disable-next-line
  search = async query => {
    /**
     * Analytics
     */
    sendEvent("track", "products_searched", { query });

    const { match, currency } = this.props;
    const lang = getCurrentLanguage(match.params.lang);

    const { data: { results = [] } = {} } = await Api.get(
      `/api/v2/search/?${toQueryString({
        lang,
        query,
        currency,
        region: getDomainRegion(DOMAIN_REGION),
        preorder: true,
      })}`,
      { lang },
    );

    renewUrlParamInCookies("affam");
    renewUrlParamInCookies("sub_id");
    renewUrlParamInCookies("coupon");

    if (this.state.searchValue.length > 2) {
      const filteredResults = results.filter(
        item => item.type !== CATEGORY.CITIES || item.available,
      );
      this.setState({
        isLoading: false,
        lastRequest: query,
        notFound: !results.length || !filteredResults.length,
        results: filteredResults,
      });
    }
  };

  /**
   * `debounce` with timer id in context
   * Debounces search function with 400ms interval
   * @type {Function}
   */
  searchDebounced = debounce(this.search, 400);

  /**
   * Tracks value stored in the input
   * @param {Event} $
   * @param {Element} $.target - input element
   */
  handleChange = ({ target }) => {
    const searchValue = target.value;
    this.setState({ searchValue });

    if (searchValue.trim().length >= 3) {
      this.setState({ isLoading: true });
      this.searchDebounced(searchValue);
    } else {
      this.setState({ results: [], notFound: false, isLoading: false });
    }
  };

  /**
   * Handles keys
   * Goes to a page of the first city in results/first result if 'enter' pressed
   * @param {Event} $
   * @param {String} $.key - key id
   */
  handleKeyboard = ({ key }) => {
    if (key === "Enter") {
      const { results } = this.state;
      let item = results[0];

      for (let i = 0; i < results.length; i += 1) {
        if (results[i].type === CATEGORY.CITIES) {
          item = results[i];
          break;
        }
      }

      if (item) {
        const url = this.generateUrl(item.type, item);
        if (url) window.location.assign(url);
      }
    }
  };

  /**
   * Generates url for search result
   * @param {String} category - result category
   * @param {Object} item - description of the result
   * @return {String|Null}
   */
  generateUrl(category, item) {
    const {
      match: { params: urlParams },
    } = this.props;
    const lang = item.locale || getCurrentLanguage(urlParams.lang);
    let type = category;
    let params;

    switch (category) {
      case CATEGORY.TOURS:
        return getProductLink(lang, item);
      case CATEGORY.ATTRACTIONS:
        type = "attraction-simple";
        params = {
          ...item,
          attractionId: item.id,
          attractionSlug: item.slug,
          lang,
        };
        break;
      case CATEGORY.CITIES:
        params = {
          ...item,
          cityId: item.id,
          citySlug: item.slug,
          lang,
        };
        break;
      default:
        return "";
    }

    try {
      return reverseUrl(type, params);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
  }

  render() {
    const { className, theme, themes, placeholder, i18n } = this.props;
    const { searchValue, isLoading, results, notFound, lastRequest, resultsVisible } = this.state;

    const content = notFound ? (
      <SearchItem
        withoutLink
        category={CATEGORY.NOT_FOUND}
        title={i18n._(t`No results`)}
        text={i18n._(t`It seems the are noting about “${lastRequest}”`)}
      />
    ) : (
      results.map((item, index) => {
        const url = this.generateUrl(item.type, item);
        return url ? (
          <SearchItem
            key={index.toString()}
            link={url}
            title={item.type === CATEGORY.TOURS ? item.title : item.name}
            text={
              (item.city ? `${item.city.name}, ` : ``) + (item.country ? item.country.name : ``)
            }
            category={item.type}
            searchWords={lastRequest}
            currencyCode={item.currencyCode}
            price={item.price}
          />
        ) : null;
      })
    );

    return (
      <div
        className={classNames(
          "Search",
          resultsVisible && "Search--touched",
          resultsVisible && searchValue.length && "Search--filled",
          resultsVisible && (results.length || notFound) && "Search--results-visible",
          theme && `Search--${theme}`,
          themes && themes.map(themeclassName => `Search--${themeclassName}`),
          className,
        )}
      >
        <OutsideClickHandler onOutsideClick={() => this.setState({ resultsVisible: false })}>
          <div className="Search__row">
            <input
              className="Search__input"
              type="text"
              placeholder={placeholder || i18n._(t`City, attraction, museum, tour name...`)}
              value={searchValue}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyboard}
              onFocus={() => {
                this.setState({ resultsVisible: true });
                sendEvent("track", "click_on_search_input");
              }}
            />

            {isLoading ? (
              <SpinnerCircle className="Search__icon" theme="main" />
            ) : (
              <Image
                staticImage
                className="Search__icon Search__icon--glass"
                src={svgSearch}
                loading="lazy"
              />
            )}
          </div>
          <div className="Search__results">{resultsVisible ? content : null}</div>
        </OutsideClickHandler>
      </div>
    );
  }
}

export default connect(({ currencies: { currentCurrency } }) => ({ currency: currentCurrency }))(
  withI18n()(withRouter(Search)),
);
