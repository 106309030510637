import { hasKey } from "../../../functions/hasKey";
import { variations } from "../variations";
import { shapes } from "../shapes";

/**
 * Icon variation
 * Describes difference between icons winth same name, but different modificator
 *
 * {
 *    shapeName: string,
 *    change: [{
 *      rotate: number,
 *    }]
 * }
 *
 * @typedef {Object} IconVariation
 * @prop {String} shapeName - icon id
 * @prop {Array[IconTransform]} change - object with base icon transformation to form variation
 */

/**
 * Get variation from list of variations
 * @param {String} shapeName - icon id
 * @returns {IconVariation}
 */
export const getVariation = shapeName => {
  if (hasKey(variations, shapeName)) {
    return variations[shapeName];
  }

  if (hasKey(shapes, shapeName)) {
    return {
      shapeName,
      change: [],
    };
  }

  return {
    shapeName: "notFound",
    change: [],
  };
};
