import get from "lodash/get";
import { unsubscribeStatus } from "../../pages/Unsubscribe";
import * as types from "../types";

/**
 * Initial state of the `user` prop in the store
 * @type {Object}
 * @prop {Boolean} isFetched - attempt to fetch was made (fetch result does not matter)
 * @prop {Boolean} isLogged - user was entered successfully
 * @prop {Boolean} loading - user profile fetching
 * @prop {Boolean} smsLoading - SMS sending/verifying is now in progress
 * @prop {Boolean} loginPopup - show/hide popup from Header::UserAccount
 */
export const init = {
  user: {
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    favorite_products: [],
  },
  isFetched: false,
  isLogged: false,
  loading: false,
  smsLoding: false,
  loginPopup: false,
  loginPopupClosable: true,
  affiliate: {},
  unsubscribeStatus: "",
  bookings: [],
};

export default function user(state = {}, action) {
  switch (action.type) {
    case types.FETCH_USER:
      return {
        ...state,
        loading: true,
      };
    case types.FETCH_USER_FAILURE:
      return {
        ...state,
        error: action.error,
        isFetched: true,
        isLogged: false,
        loading: false,
      };
    case types.FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.data,
        isFetched: true,
        isLogged: !!action.data,
        loading: false,
      };
    case types.ADD_USER_FAVOURITE:
    case types.REMOVE_USER_FAVOURITE_FAILURE: {
      const favorites = [...get(state, "user.favorite_products", [])];
      const storeUser = { ...state.user };

      if (storeUser && favorites.indexOf(action.productId) === -1) {
        favorites.push(action.productId);
        storeUser.favorite_products = favorites;
      }

      return {
        ...state,
        user: storeUser,
      };
    }
    case types.ADD_USER_FAVOURITE_FAILURE:
    case types.REMOVE_USER_FAVOURITE: {
      const favorites = [...get(state, "user.favorite_products", [])];
      const storeUser = { ...state.user };
      const i = favorites.indexOf(action.productId);

      if (storeUser && i !== -1) {
        favorites.splice(i, 1);
        storeUser.favorite_products = favorites;
      }

      return {
        ...state,
        error: action.error,
        user: storeUser,
      };
    }
    case types.LOGOUT_SUCCESS:
      return {
        ...state,
        ...init,
      };
    case types.LOGIN:
      return {
        ...state,
        loading: true,
      };
    case types.RESET_USER_LOADING:
      return {
        ...state,
        loading: false,
      };
    case types.LOGIN_POPUP_OPEN:
      return {
        ...state,
        loginPopup: !state.isLogged,
        loginPopupClosable: !action.strict,
      };
    case types.LOGIN_POPUP_CLOSE:
      return {
        ...state,
        loginPopup: false,
        loginPopupClosable: true,
      };

    case types.CREATE_AFFILIATE_PARTNER:
    case types.FETCH_AFFILIATE_PARTNER:
    case types.UPDATE_AFFILIATE_PARTNER:
      return {
        ...state,
        loading: true,
      };

    case types.CREATE_AFFILIATE_PARTNER_SUCCESS:
    case types.FETCH_AFFILIATE_PARTNER_SUCCESS:
    case types.UPDATE_AFFILIATE_PARTNER_SUCCESS:
      return {
        ...state,
        affiliate: action.data,
        loading: false,
      };

    case types.CREATE_AFFILIATE_PARTNER_FAILURE:
    case types.FETCH_AFFILIATE_PARTNER_FAILURE:
    case types.UPDATE_AFFILIATE_PARTNER_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case types.SEND_UNSUBSCRIBE_TEXT:
      return {
        ...state,
        unsubscribeStatus: unsubscribeStatus.sending,
      };

    case types.SEND_UNSUBSCRIBE_TEXT_SUCCESS:
      return {
        ...state,
        unsubscribeStatus: unsubscribeStatus.sended,
      };

    case types.SEND_UNSUBSCRIBE_TEXT_FAILURE:
      return {
        ...state,
        unsubscribeStatus: unsubscribeStatus.error,
        error: action.error,
      };

    case types.FETCH_USER_BOOKINGS:
      return {
        ...state,
        isBookingLoading: true,
      };
    case types.FETCH_USER_BOOKINGS_FAILURE:
      return {
        ...state,
        error: action.error,
        isBookingLoading: false,
      };
    case types.FETCH_USER_BOOKINGS_SUCCESS: {
      return {
        ...state,
        bookings: action.bookings,
        isBookingLoading: false,
      };
    }

    default:
      return state;
  }
}
