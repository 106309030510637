/**
 * Known regions
 * @type {Object}
 */
const REGIONS = {
  Russia: "russia",
  WorldWide: "worldwide",
};

export default REGIONS;
