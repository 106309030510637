import React, { useEffect, useState } from "react";
import { Trans } from "@lingui/macro";
import sendEvent from "../../../functions/analytics";
import useCurrentLanguage from "../../../functions/languages/useCurrentLanguage";
import Button from "../../Button";
import { SignInConditions } from "..";
import SignInEmail from "../SignInEmail";

/**
 * Icons imports
 */
import emailSVG from "../assets/email.svg";
import Image from "../../Image";

/**
 * HOC for adding ability to sign in with email
 * @param {React::Element} SignIn - modified SignIn class
 */
export default SignIn => {
  /**
   * SignIn with ability to specify email
   * @param {Object} $
   * @param {String} $.email - user email address
   * @param {Array[React::Element]} $.children - component's children
   * @param {SignInScreen} $.screen - id of the current screen
   * @param {Function} $.screenChanged - user chosen sign in option
   * @param {Boolean} $.emailConditions - if displaying `SignInConditions` required
   * @param {String} option - promo option
   */
  return ({
    screen,
    children = [],
    screenChanged = () => {},
    email,
    emailConditions,
    option = "",
    ...props
  }) => {
    const lang = useCurrentLanguage();
    const [emailScreen, setEmailScreen] = useState(null);

    /**
     * Changing current screen
     */
    useEffect(() => {
      if (
        screen !== emailScreen &&
        [null, "log-in", "email", "email-error"].indexOf(screen) !== -1
      ) {
        setEmailScreen(["email", "email-error"].indexOf(screen) !== -1);
      }
    }, [screen, emailScreen]);

    return emailScreen ? (
      <>
        <SignInEmail screen={screen} email={email} backURL={props.backURL} option={option} />
        {emailConditions ? <SignInConditions lang={lang} /> : null}
      </>
    ) : (
      <SignIn {...props} screen={screen} screenChanged={screenChanged} option={option}>
        {children[0]}
        <div>
          <Button
            className="SignIn__option"
            theme="card"
            iconPosition="before"
            icon={
              <Image
                staticImage
                className="SignIn__option-icon SignIn__option-icon--email"
                src={emailSVG}
                alt="Email icon"
                loading="lazy"
              />
            }
            onClick={() => {
              setEmailScreen(true);
              screenChanged("email");
              if (option === "discount5") sendEvent("track", "Discount5: Topbar started");
            }}
          >
            <Trans>Continue with Email</Trans>
          </Button>
          {children[1] || children}
        </div>
      </SignIn>
    );
  };
};
