import { isSSR } from "../components/NoSSR";

/**
 * Cookie managing class
 */
export default class Cookies {
  /**
   * Find cookie in the cookies list
   * @param {String} key - cookie name
   * @param {String?} data - string with saved cookies (if presented, cookie will be searched in it)
   * @returns {String} - stringified cookie with key
   */
  static find(key, data) {
    try {
      const cookies = isSSR ? data : document.cookie;
      return cookies.match(new RegExp(`( |^|;)${key}=[^;]*(;|$)`));
    } catch (error) {
      return "";
    }
  }

  /**
   * Finds cookie and converts its content from JSON
   * @param {String} key - cookie name
   * @param {String?} data - string with saved cookies (if presented, cookie will be searched in it)
   * @returns {*} - anything stored in cookie
   */
  static get(key, data) {
    if (isSSR && !data) return null;
    const cookie = Cookies.find(key, data);

    if (cookie) {
      let value = cookie[0].split("=")[1];
      value = value[value.length - 1] === ";" ? value.slice(0, -1) : value;
      try {
        return JSON.parse(decodeURIComponent(value));
      } catch (err) {
        return value;
      }
    }

    return null;
  }

  /**
   * Removes cookie
   * Unsafe for SSR, check if `document` exist firstly
   * @param {String} key - cookie name
   */
  static remove(key) {
    if (isSSR) return;
    const cookie = Cookies.find(key);

    if (cookie) {
      document.cookie = document.cookie.replace(new RegExp(`${cookie}`, "g"), "");
    }
  }

  /**
   * Sets cookie or rewrites its value
   * @param {String} key - cookie name
   * @param {*} value - cookie contents
   */
  static set(key, value, opts) {
    if (isSSR) return;

    const options = opts || {};
    let { expires } = options;
    let props = "";

    if (typeof expires === "number" && expires) {
      const d = new Date();
      d.setTime(d.getTime() + expires * 1000);
      expires = d;
      options.expires = d;
    }
    if (expires && expires.toUTCString) {
      options.expires = expires.toUTCString();
    }

    Object.keys(options).forEach(propName => {
      props += `; ${propName}`;
      const propValue = options[propName];
      if (propValue !== true) {
        props += `=${propValue}`;
      }
    });

    const cookieContent =
      typeof value === "string" ? value : encodeURIComponent(JSON.stringify(value));
    document.cookie = `${key}=${cookieContent}${props}`;
  }
}
