/**
 * Saga `loginRequestCode` was crashed
 * Possibly phone in wrong format was sent
 * @type {String}
 */
export const REQUEST_CODE = "REQUEST_CODE";

/**
 * Saga `loginVerifyCode` was crashed
 * Possibly wrong code was sent
 * @type {String}
 */
export const VERIFY_CODE = "VERIFY_CODE";

/**
 * Saga `login` was crashed
 * Possibly wrong login or password were given
 * @type {String}
 */
export const WRONG_KEYS = "WRONG_KEYS";

/**
 * Saga `checkDiscount` was crashed
 * Not all required fields were filled
 * @type {String}
 */
export const DISCOUNT_NO_DATA = "DISCOUNT_NO_DATA";

/**
 * Saga `checkDiscount` was crashed
 * Wrong code was passed
 * @type {String}
 */
export const DISCOUNT_WRONG = "DISCOUNT_WRONG";

/**
 * Network crashed
 * Dispatching by fetch API
 * This is not type, but message from default `fetch` function
 * @type {String}
 */
export const API_ERROR_MSG = "Failed to fetch";
