import React from "react";
import { availablePaymentGates, PAYMENT_GATE_TYPES } from "../../functions/payments";
import classNames from "../../functions/classNames";
import PaymentGatesItem from "./PaymentGatesItem";
import "./PaymentGates.css";

/**
 * Default payment gate types to display
 * @type {Array}
 */
const DEFAULT_TYPES = Object.keys(PAYMENT_GATE_TYPES).map(t => PAYMENT_GATE_TYPES[t]);

/**
 * Displays available payment gates from given types
 * If `$.gates` specified - gates list to show will not be generated automatically
 * @param {Object} $
 * @param {String} $.className - additional CSS class name
 * @param {String?} $.theme - component theme name (`"white"`)
 * @param {String} $.currencyCode - code of the currency from backend (e.g. `"RUB"`)
 * @param {Array?} $.types - types of payment gates to display (from `PAYMENT_GATE_TYPES`)
 * @param {Array?} $.gates - payment gates to display (from `PAYMENT_GATES`)
 */
export default function PaymentGates({
  className,
  gates: gatesExt,
  currencyCode,
  types = DEFAULT_TYPES,
  theme,
}) {
  const gates = gatesExt || availablePaymentGates(currencyCode, types);
  return (
    <span className={classNames("PaymentGates", className)}>
      {gates.map(g => (
        <PaymentGatesItem key={g} gate={g} theme={theme} />
      ))}
    </span>
  );
}
