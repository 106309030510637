import get from "lodash/get";
import { DOMAIN_REGION } from "../../constants";
import {
  EURO_COUNTRIES,
  CURRENCIES,
  ROUBLE_COUNTRIES,
  POUND_COUNTRIES,
} from "../../constants/currencies";
import Cookies from "../cookies";
import { fetchCountryByIp } from "../fetchData";
import getAvailableCurrOnDomain from "./getAvailableCurrOnDomain";

/**
 * Currencies available for usage
 * Formed from:
 * - regional restriction
 * @type {Array[Object]}
 */
export const availableCurs = getAvailableCurrOnDomain(DOMAIN_REGION).map(c =>
  CURRENCIES.find(curr => curr.code === c),
);

export function getDefaultCurrency(countryCode) {
  let foundCurrency = "USD";

  if (EURO_COUNTRIES.includes(countryCode)) {
    foundCurrency = "EUR";
  }

  if (ROUBLE_COUNTRIES.includes(countryCode)) {
    foundCurrency = "RUB";
  }

  if (POUND_COUNTRIES.includes(countryCode)) {
    foundCurrency = "GBP";
  }

  return availableCurs.some(c => c.code === foundCurrency) ? foundCurrency : availableCurs[0].code;
}

export async function fetchDefaultCurrency(request) {
  const savedCurrency = Cookies.get("currency", get(request, "headers.cookie"));
  if (savedCurrency) return savedCurrency;

  let countryCode;
  try {
    const {
      data: { code },
    } = await fetchCountryByIp(request);
    countryCode = code;
  } catch (e) {
    /* eslint-disable-next-line no-console */
    console.error("Error while detecting country by IP for selecting currency", request);
  }

  return getDefaultCurrency(countryCode);
}
