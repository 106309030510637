import React from "react";
import * as P from "prop-types";
import styled, { css } from "styled-components";
import { getIcon } from "./models/getIcon";
import { icons } from "./Icon.constants";
import "./Icon.css";

const IconContainer = styled.span`
  contain: strict;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  color: inherit;
  vertical-align: middle;
  cursor: inherit;
  /* ${({ internalWidth, internalHeight }) => {
    return css`
      flex: 0 0 ${internalWidth}px;
      min-width: ${internalWidth}px;
      max-width: ${internalWidth}px;
      min-height: ${internalHeight}px;
      max-height: ${internalHeight}px;
    `;
  }} */
`;

/**
 * Icon component
 * Note that `viewBox` for svg's is taking from object describing icon in `./shapes/*`
 * If there are no `viewBox` prop, it will be formed from width and height component props
 * @param {Object} $
 * @param {String} $.name - icon id (e.g. lang/en, chevron/up)
 * @param {String} $.width - icon width
 * @param {String} $.height - icon height
 */
export const Icon = React.memo(function IconCore({
  iconRef,
  name,
  width = 24,
  height = 24,
  className,
  onClick,
  onClickCapture,
}) {
  const ComputedIcon = getIcon(name);

  return (
    <IconContainer
      ref={iconRef}
      internalWidth={width}
      internalHeight={height}
      className={`icon-container ${className}`}
      style={{
        "min-width": `${width}px`,
        "min-height": `${height}px`,
      }}
    >
      <ComputedIcon
        internalWidth={width}
        internalHeight={height}
        width={width}
        height={height}
        onClick={onClick}
        onClickCapture={onClickCapture}
      />
    </IconContainer>
  );
});

const propTypes = {
  name: P.oneOf(icons).isRequired,
  onClickCapture: P.func,
  onClick: P.func,
};

Icon.propTypes = propTypes;
Icon.displayName = "Icon";
