import { call, put, takeEvery } from "redux-saga/effects";
import * as types from "../types";
import { Api } from "../../functions/fetchFromApi";

function* generateToursSaga(action) {
  try {
    const requestData = {
      payload: action.payload,
    };

    const response = yield call(Api.post, `/api/v2/ai/create/`, requestData);

    if (response && response.product_id) {
      yield put({
        type: types.CREATE_TOUR_SUCCESS,
        payload: { slug: response.slug, product_id: response.product_id },
      });
    } else {
      yield put({
        type: types.CREATE_TOUR_FAILURE,
        payload: "Product ID not found in the response",
      });
    }
  } catch (error) {
    yield put({
      type: types.CREATE_TOUR_FAILURE,
      payload: "Error creating tour: " + error.message,
    });
  }
}

export default function* watchCreateTour() {
  yield takeEvery(types.CREATE_TOUR_REQUEST, generateToursSaga);
}
