import * as types from "../types";

export const init = {
  reviewID: null,
};

export default function feedback(state = {}, action) {
  switch (action.type) {
    case types.SEND_FEEDBACK:
      return {
        ...state,
        loading: true,
      };

    case types.SEND_FEEDBACK_FAILURE:
      return {
        ...state,
        error: action.error,
        loading: false,
      };

    case types.SEND_FEEDBACK_SUCCESS:
      return {
        ...state,
        reviewID: action.id,
        loading: false,
      };

    default:
      return state;
  }
}
